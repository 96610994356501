import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateGroupList: false,
  groupsList: null,
};

export const groupsListSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    fillGroupsList: (state, action) => {
      state.updateGroupList = false;
      state.groupsList = action.payload.groupsList;
    },
    fetchGroupsList: (state, action) => {
      state.updateGroupList = true;
    },
  },
});

export const { fillGroupsList, fetchGroupsList } = groupsListSlice.actions;

export default groupsListSlice.reducer;
