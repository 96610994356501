import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarBattery,
  faPlugCircleCheck,
  faPlugCircleXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { useLocalize } from "../../components/localize";
import { useDispatch } from "react-redux";

import styles from "./batteries.module.css";
import { setSelectedBattery } from "../../store/reducers/selectedBatteryReducer";

const BatteriesListRow = ({ battery, setMode, indice }) => {
  const dispatch = useDispatch();
  const t = useLocalize();

  const selectBattery = () => {
    dispatch(setSelectedBattery({ selectedBattery: battery }));
  };

  return (
    <tr>
      <td>{battery.battery_id}</td>
      <td>{battery.battery_type}</td>
      <td>
        {battery.production_date ? battery.production_date.split("T")[0] : "-"}
      </td>
      <td>
        {battery.first_charge_date
          ? battery.first_charge_date.split("T")[0]
          : "-"}
      </td>
      <td>{battery.associated_vehicle}</td>
      <td>{battery.associated_station}</td>
      <td>{battery.associated_warehouse}</td>
      <td>
        <FontAwesomeIcon icon={faCarBattery} /> {battery.charge_level} %
      </td>
      <td>
        {battery.connected ? (
          <>
            <FontAwesomeIcon icon={faPlugCircleCheck} /> {t("Yes")}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faPlugCircleXmark} /> {t("No")}
          </>
        )}
      </td>
      <td>
        {battery.is_charging ? (
          <>
            <FontAwesomeIcon icon={faPlugCircleCheck} /> {t("Yes")}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faPlugCircleXmark} /> {t("No")}
          </>
        )}
      </td>
      <td>{`${battery.battery_capacity} ${t("capacity_unit")}`}</td>
      <td>
        <span
          className={styles.clickable}
          onClick={() => {
            selectBattery();
            setMode("edit");
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> {t("details")}
        </span>
      </td>
    </tr>
  );
};

export default BatteriesListRow;
