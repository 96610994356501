import L from "leaflet";
import bikeIcon from "./scooter.png";

const defaultMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [13, 0],
});

const bikeMarker = new L.icon({
  iconUrl: bikeIcon,
  iconSize: [50, 50],
  iconAnchor: [13, 0],
});

export { defaultMarker, bikeMarker };
