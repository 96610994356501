import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faLockOpen,
  faLink,
  faLinkSlash,
} from "@fortawesome/free-solid-svg-icons";
import { isOnline } from ".";

const ConnectionIcon = ({ bike }) => {
  if (isOnline(bike.last_comm_timestamp)) {
    return (
      <>
        <FontAwesomeIcon icon={faLink} style={{ color: "green" }} />
      </>
    );
  }

  return (
    <>
      <FontAwesomeIcon icon={faLinkSlash} />
    </>
  );
};

const LockIcon = ({ bike }) => {
  if (bike.actual_lock_status) {
    return (
      <>
        <FontAwesomeIcon icon={faLock} style={{ color: "red" }} />
      </>
    );
  }

  return (
    <>
      <FontAwesomeIcon icon={faLockOpen} style={{ color: "green" }} />
    </>
  );
};

const ListRow = ({ bike, focus, setFocus }) => {
  const rowStyle = {
    cursor: bike.latitude && bike.longitude ? "pointer" : "no-drop",
    backgroundColor: bike.bike_id === focus?.bike_id ? "#DDD" : "transparent",
  };

  return (
    <tr onClick={() => setFocus(bike)} style={rowStyle}>
      <td>
        <ConnectionIcon bike={bike} />
        <LockIcon bike={bike} />
      </td>
      <td>{bike.licence_plate}</td>
      <td>{bike.actual_speed} km/h</td>
    </tr>
  );
};

export default ListRow;
