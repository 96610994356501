import styles from "./users.module.css";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";

import editUser from "../../utils/editUser";
import deleteUser from "../../utils/deleteUser";

import { useLocalize } from "../../components/localize";
import { useState } from "react";

import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";

import tableStyles from "../commonStyles/listTable.module.css";
import { fetchuserList } from "../../store/reducers/usersList";
import { userLevels } from "./userPriviliges";
import { dateFormat, dateFormatEU } from "../../utils/dateFormat";

const UserDetails = ({ setMode, edit }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editLock, setEditLock] = useState(false);
  const authorizedUser = useSelector((state) => state.user);
  const selectedUser = useSelector((state) => state.selectedUser);
  const dispatch = useDispatch();

  let defaultUser = {};

  const deleteUserRequest = () => {
    setConfirmDelete(true);
  };

  defaultUser = { ...selectedUser };

  const [user, setUser] = useState(defaultUser);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const verifyForm = () => {
    if (
      user.name === "" ||
      user.surname === "" ||
      user.user_name === "" ||
      user.email === "" ||
      user.tckn === ""
    ) {
      setMessage("Formda Eksik Veri!");
      setShowMsg(true);
      return false;
    }

    if (
      !user.name ||
      !user.surname ||
      !user.user_name ||
      !user.email ||
      !user.tckn
    ) {
      setMessage("Formda Eksik Veri!");
      setShowMsg(true);
      return false;
    }

    return true;
  };

  const saveUser = () => {
    if (!verifyForm()) return;
    editUser(user).then((result) => {
      setMessage("User Saved");
      setShowMsg(true);
      dispatch(fetchuserList());
      setEditLock(false);
    });
  };

  return (
    <div className={styles.userDetails}>
      <h2>{t("users")}</h2>
      <div className={styles.topBar}>
        <h3>{t("userdetails")}</h3>
        <section>
          {edit && (
            <button onClick={() => deleteUserRequest()}>
              <FontAwesomeIcon icon={faTrash} /> {t("delete")}
            </button>
          )}
          <button
            onClick={() => {
              if (edit) {
                if (editLock) {
                  saveUser();
                } else {
                  setEditLock(true);
                }
              }
            }}
          >
            <FontAwesomeIcon icon={faUserPen} />{" "}
            {edit ? (editLock ? t("save") : t("edit")) : t("add")}
          </button>
          <button onClick={() => setMode("list")}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
          </button>
        </section>
      </div>

      <div className={styles.detailsList}>
        <table className={tableStyles.listTable}>
          <tbody>
            <tr>
              <td>{t("name")}</td>
              {!edit || editLock ? (
                <td>
                  <input
                    name="name"
                    type="text"
                    value={user.name}
                    onChange={handleChange}
                  />
                </td>
              ) : (
                <td>{user.name}</td>
              )}
            </tr>
            <tr>
              <td>{t("surname")}</td>
              {!edit || editLock ? (
                <td>
                  <input
                    name="surname"
                    type="text"
                    value={user.surname}
                    onChange={handleChange}
                  />
                </td>
              ) : (
                <td>{user.surname}</td>
              )}
            </tr>
            <tr>
              <td>{t("tckn")}</td>
              {!edit || editLock ? (
                <td>
                  <input
                    name="tckn"
                    type="text"
                    value={user.tckn}
                    onChange={handleChange}
                  />
                </td>
              ) : (
                <td>{user.tckn}</td>
              )}
            </tr>
            <tr>
              <td>{t("username")}</td>
              {!edit || editLock ? (
                <td>
                  <input
                    name="username"
                    type="text"
                    value={user.user_name}
                    onChange={handleChange}
                  />
                </td>
              ) : (
                <td>{user.user_name}</td>
              )}
            </tr>
            <tr>
              <td>{t("level")}</td>
              {!edit || editLock ? (
                <td>
                  <select
                    name="level"
                    onChange={handleChange}
                    value={user.level}
                  >
                    <option></option>
                    {Object.keys(userLevels).map((level) => (
                      <option value={level}>{t(level)}</option>
                    ))}
                  </select>
                </td>
              ) : (
                <td>{t(user.level)}</td>
              )}
            </tr>
            <tr>
              <td>{t("groups")}</td>
              {!edit || editLock ? (
                <td>
                  <select name="groups" onChange={handleChange}>
                    <option value="0">Group 1</option>
                    <option value="1">Group 2</option>
                  </select>
                </td>
              ) : (
                <td>{user.group}</td>
              )}
            </tr>
            <tr>
              <td>{t("email")}</td>
              {!edit || editLock ? (
                <td>
                  <input
                    name="email"
                    onChange={handleChange}
                    value={user.email}
                  />
                </td>
              ) : (
                <td>{user.email}</td>
              )}
            </tr>
            <tr>
              <td>{t("date_added")}</td>
              <td>{dateFormatEU(user.date_added, selectedLang)}</td>
            </tr>
            <tr>
              <td>{t("last_login")}</td>
              <td>{dateFormatEU(user.last_login, selectedLang)}</td>
            </tr>
            <tr>
              <td>{t("account_activation")}</td>
              <td>{user.temporary_password === 0 ? <>Evet</> : <>Hayır</>}</td>
            </tr>
          </tbody>
        </table>
        <Toast
          title="Message"
          message={message}
          show={showMsg}
          onClose={() => setShowMsg(false)}
        />
        {confirmDelete && (
          <ConfirmDialog
            title={t("delete_title")}
            message={t("confirm_delete")}
            onConfirm={() => {
              deleteUser(user).then((result) => {
                dispatch(fetchuserList());
                setMode("list");
              });
            }}
            onClose={() => setConfirmDelete(false)}
          />
        )}
      </div>
    </div>
  );
};

UserDetails.defaultProps = {
  edit: false,
};

export default UserDetails;
