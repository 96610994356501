export const userPriviliges = {
  admin: {
    canSeeDashBoard: true,
    canSeeCompanies: true,
    canEditCompanies: true,
    canSeeVehicles: true,
    canSeeStations: true,
    canEditStations: true,
    canSeeBatteries: true,
    canEditBatteries: true,
    canSeeUsers: true,
    canEditUsers: true,
    canSeeReports: true,
    canSeeDrivers: true,
    canEditDrivers: true,
    canSeeGroups: true,
    canSeePrices: true,
    canSeeDriversLicence: true,
    canSeeRentedBikes: true,
    canSeeRentedCharges: true,
  },
  partner_admin: {
    canSeeDashBoard: true,
    canSeeCompanies: true,
    canEditCompanies: true,
    canSeeVehicles: true,
    canSeeBatteries: true,
    canEditBatteries: true,
    canSeeUsers: true,
    canEditUsers: true,
    canSeeReports: true,
    canSeeGroups: false,
    canSeePrices: false,
    canSeeDriversLicence: false,
    canSeeRentedBikes: false,
    canSeeRentedCharges: false,
  },
  corp_admin: {
    canSeeDashBoard: true,
    canSeeCompanies: false,
    canSeeVehicles: true,
    canSeeBatteries: false,
    canEditBatteries: false,
    canSeeUsers: true,
    canEditUsers: true,
    canSeeReports: true,
    canSeeDrivers: true,
    canEditDrivers: true,
    canSeeGroups: false,
    canSeePrices: false,
    canSeeDriversLicence: false,
    canSeeRentedBikes: false,
    canSeeRentedCharges: false,
  },
  corp_user: {
    canSeeDashBoard: true,
    canSeeCompanies: false,
    canSeeVehicles: true,
    canSeeBatteries: false,
    canSeeUsers: false,
    canSeeReports: true,
    canSeeProfile: true,
    canSeeGroups: false,
    canSeePrices: false,
    canSeeDriversLicence: false,
    canSeeRentedBikes: false,
    canSeeRentedCharges: false,
  },
  indv_user: {
    canSeeDashBoard: true,
    canSeeCompanies: false,
    canSeeVehicles: true,
    canSeeBatteries: false,
    canSeeUsers: false,
    canSeeReports: true,
    canSeeProfile: true,
    canSeePrices: false,
    canSeeDriversLicence: false,
    canSeeRentedBikes: false,
    canSeeRentedCharges: false,
  },
};

export const userLevels = {
  admin: 1,
  partner_admin: 10,
  corp_admin: 20,
  corp_user: 30,
  indv_user: 40,
};
