import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateList: false,
  list: [],
};

export const rentedBikes = createSlice({
  name: "rentedBikes",
  initialState,
  reducers: {
    fillRentedBikes: (state, action) => {
      state.updateList = false;
      state.list = action.payload.list;
    },
    fetchRentedBikesList: (state, action) => {
      state.updateList = true;
    },
  },
});

export const { fillRentedBikes, fetchRentedBikesList } = rentedBikes.actions;
export default rentedBikes.reducer;
