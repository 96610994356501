import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import tableStyles from "../commonStyles/listTable.module.css";
import styles from "./stations.module.css";
import { useLocalize } from "../../components/localize";
import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";

import addStation from "../../utils/addStation";
import { deleteStation, editStation } from "../../utils/stationsApi";
import { fetchstationList } from "../../store/reducers/stationReducer";
import StationsMap from "./StationsMap";

const StationOperations = ({ setMode, edit }) => {
  const selectedStation = useSelector(
    (state) => state.stations.selectedStation
  );

  console.log("SELECTED STAT : ", selectedStation);

  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editLock, setEditLock] = useState(false);
  const [station, setStation] = useState(!edit ? {} : { ...selectedStation });

  const companyId = useSelector((state) => state.user.companyId);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setStation({ ...station, [e.target.name]: e.target.value });
  };

  const handleStationLocation = (coords) => {
    console.log("COORDS : ", coords);
    setStation((station) => {
      return {
        ...station,
        latitude: coords?.lat,
        longitude: coords?.lng,
        companyid: companyId,
      };
    });
  };

  const saveStation = () => {
    if (edit) {
      console.log("SAVE STATION : ", station);
      editStation(station);
      setMessage(t("station_saved"));
    } else {
      addStation(station);
      setMessage(t("station_added"));
      // setStation({
      //   ...station,
      //   station_type: "default",
      //   name: "",
      //   serial_no: "",
      // });
    }
    setShowMsg(true);
    dispatch(fetchstationList());
  };

  const deleteStationRequest = () => {
    setConfirmDelete(true);
  };

  return (
    <div className={styles.stationOperation}>
      <h2>{edit ? t("station_details") : t("add_new_station")} </h2>
      <section className={styles.topBar}>
        <button onClick={() => setMode("list")}>
          <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
        </button>
        <section>
          {edit && (
            <button onClick={() => deleteStationRequest()}>
              <FontAwesomeIcon icon={faTrash} /> {t("delete")}
            </button>
          )}
          <button
            onClick={() => {
              if (edit) {
                if (editLock) {
                  saveStation();
                  setMode("list");
                  setEditLock(false);
                } else {
                  setEditLock(true);
                }
              } else {
                saveStation();
              }
              dispatch(fetchstationList());
            }}
          >
            <FontAwesomeIcon icon={faSave} />{" "}
            {edit ? (editLock ? t("save") : t("edit")) : t("add")}
          </button>
        </section>
      </section>

      <div className={styles.stationOperationBody}>
        <div className={styles.inputContainer}>
          <table className={tableStyles.listTable}>
            <tr>
              <td>{t("station_type")}</td>
              {!edit || editLock ? (
                <td>
                  <select
                    name="station_type"
                    value={station.station_type}
                    onChange={handleChange}
                  >
                    <option value="multiswap">MultiSwap</option>
                    <option value="swap">Swap</option>
                    <option value="plug">Akıllı Priz</option>
                  </select>
                </td>
              ) : (
                <td>{station.station_type}</td>
              )}
            </tr>
            <tr>
              <td>{t("name")}</td>
              {!edit || editLock ? (
                <td>
                  <input
                    type="text"
                    name="name"
                    value={station.name}
                    onChange={handleChange}
                  />
                </td>
              ) : (
                <td>{station.name}</td>
              )}
            </tr>
            <tr>
              <td>{t("imei")}</td>
              {!edit || editLock ? (
                <td>
                  <input
                    type="text"
                    name="imei"
                    value={station.imei}
                    onChange={handleChange}
                  />
                </td>
              ) : (
                <td>{station.imei}</td>
              )}
            </tr>
          </table>
        </div>
        <div className={styles.stationOperationMap}>
          <StationsMap
            handleStationLocation={handleStationLocation}
            isEdit={edit}
            editLock={editLock}
            station={station}
          ></StationsMap>
        </div>
      </div>
      <Toast
        title="Message"
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
      {confirmDelete && (
        <ConfirmDialog
          title={t("delete_title")}
          message={t("confirm_delete")}
          onConfirm={() => {
            deleteStation(station);
            setMode("list");
            dispatch(fetchstationList());
          }}
          onClose={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
};

StationOperations.defaultProps = {
  edit: false,
};

export default StationOperations;
