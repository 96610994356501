/* eslint-disable */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { now, elapsedTime } from "./rentUtils";
("");
import styles from "./rentabike.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill1Wave,
  faFlagCheckered,
  faClock,
  faHourglassStart,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";

import ChargeLevelGauge from "./chargeLevelGauge";
import { finishRent, updateCost } from "../../store/reducers/bikeRentReducer";

const RentProgress = ({ setMode }) => {
  const rent = useSelector((state) => state.rent);
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(0);

  const d = new Date(rent.rentBegin * 1000);
  const beginDateTime =
    d.getDate().toString().padStart(2, "0") +
    "/" +
    (parseInt(d.getMonth()) + 1).toString().padStart(2, "0") +
    "/" +
    d.getFullYear() +
    " " +
    d.getHours().toString().padStart(2, "0") +
    ":" +
    d.getMinutes().toString().padStart(2, "0");

  useEffect(() => {
    if (rent.isRent) {
      const interval = setInterval(() => {
        setDuration((prevValue) => prevValue + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [rent]);

  useEffect(() => {
    if (duration % 60 === 0 && duration > 0) {
      dispatch(
        updateCost({
          cost: rent.cost + rent.costPerMinute,
          durationMinutes: rent.durationMinutes + 1,
        })
      );
    }
  }, [duration]);

  const rentFinish = () => {
    dispatch(
      finishRent({
        durationMinutes: duration,
        rentEnd: now(),
        cost: rent.cost,
        warning: null,
        violations: null,
        overtime: null,
      })
    );

    setMode("bikeLeave");
  };

  return (
    <div className={styles.rentProgress}>
      <h1 className={styles.pageTitle}>Rent in Progress</h1>
      <div className={styles.progressInfo}>
        <p>
          Status : <span style={{ color: "limegreen" }}>Rent Active</span>
        </p>
        <p>
          <FontAwesomeIcon icon={faClock} /> Rent Begin : {beginDateTime}
        </p>
        <p>
          <FontAwesomeIcon icon={faHourglassStart} /> Duration :{" "}
          {elapsedTime(duration)}
        </p>
        <p>
          <FontAwesomeIcon icon={faMoneyBill1Wave} /> Cost : {rent.cost}{" "}
          {rent.unit}
        </p>
        <div className={styles.chargeInfo}>
          <FontAwesomeIcon icon={faBolt} /> Charge : {rent.chargeLevel} %
          <ChargeLevelGauge level={rent.chargeLevel} />
        </div>
      </div>
      <section className={styles.rentProgressButtons}>
        <button type="button" onClick={() => rentFinish()}>
          <FontAwesomeIcon icon={faFlagCheckered} /> FINISH
        </button>
      </section>
    </div>
  );
};

export default RentProgress;
