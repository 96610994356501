import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import tableStyles from "../commonStyles/listTable.module.css";
import styles from "./companies.module.css";
import addCompany from "../../utils/addCompany";
import editCompany from "../../utils/editCompany";
import deleteCompany from "../../utils/deleteCompany";
import { useLocalize } from "../../components/localize";
import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";
import { dateOnly, dateOnlyEU } from "../../utils/dateFormat";
import { fetchCompanyList } from "../../store/reducers/companiesReducer";

const CompanyOperations = ({ setMode, edit }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editLock, setEditLock] = useState(false);

  const selectedCompany = useSelector(
    (state) => state.selectedCompany.selectedCompany
  );
  const dispatch = useDispatch();

  let defaultCompany = {};

  if (!edit) {
    defaultCompany = {
      company_name: "",
      driver_count: "",
      vehicle_count: "",
      company_type: "customer",
    };
  } else {
    defaultCompany = { ...selectedCompany };
  }

  const [company, setCompany] = useState(defaultCompany);

  const handleChange = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const saveCompany = () => {
    if (edit) {
      if (company.company_name === "") {
        setMessage(t("please_include_all_fields"));
      } else {
        editCompany(company);
        setMessage(t("company_saved"));
      }
    } else {
      if (company.company_name === "") {
        setMessage(t("please_include_all_fields"));
      } else {
        addCompany(company);
        setMessage(t("company_added"));
        setCompany({
          ...company,
          company_name: "",
          driver_count: "",
          vehicle_count: "",
          company_type: "customer",
        });
      }
    }
    setShowMsg(true);
    dispatch(fetchCompanyList());
  };

  const deleteCompanyRequest = () => {
    setConfirmDelete(true);
  };

  return (
    <div className={styles.editSectionContainer}>
      <h2>{t("companies")}</h2>
      <section className={styles.topBar}>
        <h3>{edit ? t("company_details") : t("add_new_company")} </h3>
        <section>
          {edit && (
            <button onClick={() => deleteCompanyRequest()}>
              <FontAwesomeIcon icon={faTrash} /> {t("delete")}
            </button>
          )}
          <button
            onClick={() => {
              if (edit) {
                if (editLock) {
                  saveCompany();
                  setEditLock(false);
                } else {
                  setEditLock(true);
                }
              } else {
                saveCompany();
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} />{" "}
            {edit ? (editLock ? t("save") : t("edit")) : t("add")}
          </button>
          <button onClick={() => setMode("list")}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
          </button>
        </section>
      </section>
      <table className={tableStyles.listTable}>
        <tr>
          <td>{t("company_type")}</td>
          {!edit || editLock ? (
            <td>
              <select
                name="company_type"
                value={company.company_type || "customer"}
                onChange={handleChange}
              >
                <option value="customer">{t("customer")}</option>
                <option value="provider">{t("partner")}</option>
              </select>
            </td>
          ) : (
            <td>{company.company_type ? t(company.company_type) : "-"}</td>
          )}
        </tr>
        <tr>
          <td>{t("name")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="company_name"
                value={company.company_name}
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{company.company_name ? company.company_name : "-"}</td>
          )}
        </tr>
        <tr>
          <td>{t("date_added")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="date_added"
                value={dateOnlyEU(company.date_added, selectedLang)}
                onChange={handleChange}
                disabled
              />
            </td>
          ) : (
            <td>
              {company.date_added
                ? dateOnlyEU(company.date_added, selectedLang)
                : "-"}
            </td>
          )}
        </tr>
        <tr>
          <td>{t("latest_activity")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="latest_activity"
                value={dateOnlyEU(company.latest_activity, selectedLang)}
                onChange={handleChange}
                disabled
              />
            </td>
          ) : (
            <td>
              {company.latest_activity
                ? dateOnlyEU(company.latest_activity, selectedLang)
                : "-"}
            </td>
          )}
        </tr>
        <tr>
          <td>{t("driver_count")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="driver_count"
                value={company.driver_count}
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{company.driver_count ? company.driver_count : "-"}</td>
          )}
        </tr>
        <tr>
          <td>{t("vehicle_count")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="vehicle_count"
                value={company.vehicle_count}
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{company.vehicle_count ? company.vehicle_count : "-"}</td>
          )}
        </tr>
      </table>
      <Toast
        title="Warning"
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
      {confirmDelete && (
        <ConfirmDialog
          title={t("delete_title")}
          message={t("confirm_delete")}
          onConfirm={() => {
            deleteCompany(company);
            setMode("list");
            dispatch(fetchCompanyList());
          }}
          onClose={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
};

CompanyOperations.defaultProps = {
  edit: false,
};

export default CompanyOperations;
