import React, { useState } from "react";
import styles from "./batteries.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import BikeListRow from "./BatteriesListRow";
import { useSelector, useDispatch } from "react-redux";
import { Filter, hideByFilter } from "../../components/filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useLocalize } from "../../components/localize";

const BatteriesList = ({ setMode }) => {
  const [filterValue, setFilterValue] = useState("");
  const batteries = useSelector(
    (state) => state.batteries.batteryList.batteries
  );

  const t = useLocalize();

  if (!batteries) return null;
  return (
    <div className={styles.batteriesList}>
      <h2>{t("batteries")}</h2>
      <div className={styles.topBar}>
        <Filter setFilter={setFilterValue} />
        <button onClick={() => setMode("add")}>
          <FontAwesomeIcon icon={faPlus} /> {t("add")}
        </button>
      </div>
      <table className={tableStyles.listTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>{t("type")}</th>
            <th>{t("production_date")}</th>
            <th>{t("first_charge_date")}</th>
            <th>{t("associated_vehicle")}</th>
            <th>{t("associated_station")}</th>
            <th>{t("associated_warehouse")}</th>
            <th>{t("charge_level")}</th>
            <th>{t("connected")}</th>
            <th>{t("charge_level")}</th>
            <th>{t("capacity")}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {batteries?.map((battery, indice) => {
            if (
              hideByFilter(
                [
                  battery.battery_id.toString(),
                  battery.battery_capacity.toString(),
                  battery.replace_date,
                  battery.battery_type,
                ],
                filterValue
              )
            ) {
              return (
                <BikeListRow
                  battery={battery}
                  indice={indice}
                  setMode={setMode}
                  key={battery.battery_id}
                />
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BatteriesList;
