export const properties = {
  bikeId: "Bike ID",
  type: "Type",
  serialNo: "Serial No",
  motorPower: "Motor Power",
  color: "Color",
  brand: "Brand",
  dateAdded: "Date Added",
  dateUpdated: "Date Updated",
  city: "City",
  powerStatus: "Power Status",
  speedLimit: "Speed Limit",
  speedUnit: "Speed Unit",
  actualSpeed: "Actual Speed",
  isActive: "Active",
  isLocked: "Locked",
  licencePlate: "Licence Plate",
  isCorporate: "Corporate",
  account: "Account",
  beginTimeStamp: "Begin Time",
  durationMinst: "Duration Mins",
  isTelemetryEnabled: "Telemetry Enabled",
  isConnected: "Connected",
  ip: "IP",
  modemIMEI: "IMEI",
  serverIP: "Server IP",
  serverPort: "Server Port",
  protocol: "Protocol",
  timeStamp: "Time Stamp",
  batteryCapacity: "Battery Capacity",
  batteryType: "Battery Type",
  chargeLevel: "Charge Level",
  replaceDate: "Replace Date",
  "isCharging	": "Charging",
};

export const brands = ["Sunra", "WOW"];
