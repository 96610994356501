import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  userid: null,
  name: "",
  surname: "",
  email: "",
  type: "user",
  level: "user",
  isLogin: false,
  token: "",
  language: "tr",
  companyId: null,
  priviliges: {},
  update: false,
  lastLogin: null,
  tckn: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      state.isLogin = true;
      state.username = action.payload.username;
      state.userid = action.payload.userid;
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.level = action.payload.level;
      state.type = action.payload.type;
      state.companyId = action.payload.companyid;
      state.token = action.payload.token;
      state.priviliges = action.payload.priviliges;
      state.tckn = action.payload.tckn;
    },
    userLogout: () => initialState,
    setLanguage: (state, action) => {
      state.language = action.payload.language;
    },
  },
});

export const { setLanguage, userLogin, userLogout } = userSlice.actions;

export default userSlice.reducer;
