import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./tabs.css";

const TabControl = ({ tabs, onTabChange }) => {
  //const selectedTab = useSelector((state) => state.menu.activeSettingTab);
  const selectedTab = 1;
  const [active, setActive] = useState(tabs[selectedTab - 1].nr);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const toggleTab = (nr) => {
    // dispatch(setSettingTab(nr));
    setActive(nr);
    onTabChange(nr);
  };

  return (
    <div className="tabControl">
      {tabs.map((tab) => {
        return (
          <div
            className={active === tab.nr ? "tabs active" : "tabs"}
            onClick={() => toggleTab(tab.nr)}
          >
            {tab.name}
          </div>
        );
      })}
    </div>
  );
};

export default TabControl;
