import { useSelector } from "react-redux";
import { useLocalize } from "../../components/localize";
import { dateFormat } from "../../utils/dateFormat";

const ReportsListRow = ({ report, type }) => {
  const vehicles = useSelector((state) => state.bikes.bikesList);
  const stations = useSelector((state) => state.stations.stationList);
  const t = useLocalize();
  const findVehiclePlate = (id) => {
    if (!id) return "----";
    let bike;
    vehicles
      .filter((veh) => veh.bike_id === id)
      .map((veh) => {
        return (bike = veh.licence_plate);
      });
    if (bike) {
      return bike;
    } else {
      return "-----";
    }
  };
  const findStationName = (id) => {
    if (!id) return "-----";
    let station;
    stations
      .filter((station) => station.station_id === id)
      .map((station) => {
        return (station = station.name);
      });
    if (station) {
      return station;
    } else {
      return "-----";
    }
  };
  return type === "rents" ? (
    <tr>
      <td>{report.process_id}</td>
      <td>{report.name}</td>
      <td>{findVehiclePlate(report.vehicle_id)}</td>
      <td>{t(report?.rent_type)}</td>
      <td>{dateFormat(report.start_date)}</td>
      <td>{dateFormat(report.end_date)}</td>
      <td>{report.price ? `${report.price}₺` : "------"}</td>
    </tr>
  ) : type === "chargeRents" ? (
    <tr>
      <td>{report.id}</td>
      <td>{report.name}</td>
      <td>{findStationName(report.station_name)}</td>
      <td>{report.email}</td>
      <td>{report.usage_time_sec ? `${report.usage_time_sec} saniye` : ""}</td>
      <td>{report.usage_price ? `${report.usage_price}₺` : ""}</td>
      <td>
        {report.usage_request_date
          ? dateFormat(report.usage_request_date)
          : "-------"}
      </td>
      <td>
        {dateFormat(report.usage_start_date)
          ? dateFormat(report.usage_start_date)
          : "-------"}
      </td>
      <td>
        {dateFormat(report.usage_finish_date)
          ? dateFormat(report.usage_finish_date)
          : "-------"}
      </td>
    </tr>
  ) : type === "swaps" ? (
    <tr>
      <td>{report.id}</td>
      <td>{report.name}</td>
      <td>{report.station_name}</td>
      <td>{report.licence_plate}</td>
      <td>{dateFormat(report.swap_date)}</td>
      {/* <td>{report.is_finished}</td> */}
    </tr>
  ) : (
    type === "payments" && (
      <tr>
        <td>{report.id}</td>
        <td>{report.name}</td>
        <td>{dateFormat(report.transaction_start)}</td>
        <td>{dateFormat(report.transaction_finish)}</td>
        <td>{report.is_failed === false ? t("succeed") : t("failed")}</td>
        <td>{parseFloat(report.cost).toFixed(2)}₺</td>
      </tr>
    )
  );
};
export default ReportsListRow;
