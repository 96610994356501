import React, { useState } from "react";
import styles from "./bikes.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import BikeListRow from "./BikeListRow";
import { useSelector, useDispatch } from "react-redux";

import { Filter, hideByFilter } from "../../components/filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocalize } from "../../components/localize";
import { setSelectedBike } from "../../store/reducers/selectedBikeReducer";

const BikeList = ({ setMode }) => {
  const [filterValue, setFilterValue] = useState("");
  const ebikes = useSelector((state) => state.bikes.bikesList);
  const dispatch = useDispatch();
  const t = useLocalize();

  const selectBike = (bike) => {
    dispatch(setSelectedBike({ selectedBike: bike }));
  };

  if (!ebikes) return null;

  return (
    <div className={styles.bikesList}>
      <h2>{t("vehicles")}</h2>
      <div className={styles.topBar}>
        <Filter setFilter={setFilterValue} />
        <button onClick={() => setMode("add")}>
          <FontAwesomeIcon icon={faPlus} /> {t("add")}
        </button>
      </div>
      <table className={tableStyles.listTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>{t("type")}</th>
            <th>{t("serial_nr")}</th>
            <th>{t("imei")}</th>
            <th>{t("gsm_nr")}</th>
            <th>HW</th>
            <th>FW</th>
            <th>{t("brand")}</th>
            <th>{t("plate")}</th>
            <th>{t("company")}</th>
            <th>{t("assigned_courier_name")}</th>
            <th>{t("status")}</th>
            <th>{t("connected")}</th>
            {/*<th>{t("battlevel")}</th>
            <th>{t("charging")}</th>*/}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ebikes?.map((bike, indice) => {
            if (
              hideByFilter(
                [
                  bike.licence_plate,
                  bike.bike_id,
                  bike.brand,
                  bike.type,
                  bike.serial_no,
                  bike.company_name,
                ],
                filterValue
              )
            )
              return (
                <BikeListRow
                  bike={bike}
                  indice={indice}
                  setMode={setMode}
                  selectBike={selectBike}
                  key={bike.bike_id}
                />
              );

            return null;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BikeList;
