import styles from "./users.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import UserListRow from "./UserListRow";
import { Filter, hideByFilter } from "../../components/filter";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocalize } from "../../components/localize";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUsersLine,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const Hourglass = () => {
  return (
    <div className={styles.hourglass}>
      <FontAwesomeIcon icon={faSpinner} />
    </div>
  );
};

const UserList = ({ setMode }) => {
  const user = useSelector((state) => state.user);
  const usersList = useSelector((state) => state.usersList.userList);
  const isLoading = useSelector((state) => state.usersList.isLoading);
  const [filterValue, setFilterValue] = useState("");
  const t = useLocalize();

  if (!usersList) return null;

  return (
    <div className={styles.usersList}>
      <h2>{t("users")}</h2>
      <div className={styles.topBar}>
        <Filter setFilter={setFilterValue} />
        <div>
          <button type="button" onClick={() => setMode("adduser")}>
            <FontAwesomeIcon icon={faPlus} /> {t("adduser")}
          </button>
          {user.priviliges.canSeeGroups && (
            <button type="button" onClick={() => setMode("groupslist")}>
              <FontAwesomeIcon icon={faUsersLine} /> {t("groups")}
            </button>
          )}
        </div>
      </div>
      <table className={tableStyles.listTable}>
        <thead>
          <tr>
            <th></th>
            <th>{t("username")}</th>
            <th>{t("name")}</th>
            <th>{t("email")}</th>
            <th>{t("level")}</th>
            <th>{t("last_login")}</th>
            <th>{t("company")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {usersList?.map((usr, indice) => {
            if (
              hideByFilter(
                [usr.name, usr.surname, usr.user_name, usr.email],
                filterValue
              )
            )
              return (
                <UserListRow
                  usr={usr}
                  indice={indice}
                  setMode={setMode}
                  key={usr.user_id}
                />
              );

            return null;
          })}
        </tbody>
      </table>
      {isLoading && <Hourglass />}
    </div>
  );
};

export default UserList;
