import { serverPath } from "./devSettings";

const getDriverLicences = async (user) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getDriverLicences", options);
  const result = await response.json();

  return result;
};

const approveDriverLicence = async (driver) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(driver),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/approveDriverLicence", options);
  const result = await response.json();

  return result;
};

const rejectDriverLicence = async (driver) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(driver),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/rejectDriverLicence", options);
  const result = await response.json();

  return result;
};

export { getDriverLicences, approveDriverLicence, rejectDriverLicence };
