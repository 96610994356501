import styles from "./reports.module.css";
import { useState } from "react";
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import { enUS } from "date-fns/esm/locale";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormatter } from "./dateFormatter";
import { useLocalize } from "../../components/localize";

const ReportSelector = ({ setReport, handleReportType }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const t = useLocalize();
  const [reportType, setReportType] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [swapVehicle, setSwapVehicle] = useState("");
  const [chargeId, setChargeId] = useState("");
  const [chargeType, setChargeType] = useState("");
  const [stationId, setStationId] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const bikes = useSelector((state) => state.bikes.bikesList);
  const stations = useSelector((state) => state.stations.stationList);
  const user = useSelector((state) => state.user);

  function createReport() {
    setReport({
      reportType: reportType,
      startDate: startDate ? dateFormatter(startDate) : null,
      endDate: endDate ? dateFormatter(endDate) : null,
      vehicle_id: vehicleId,
      swap_vehicle: swapVehicle,
      station_id: stationId,
      charge_id: chargeId,
    });

    handleReportType(reportType);
  }
  const swapTypes = ["swap", "plug"];
  const filteredStations = stations?.filter(
    (s) => s.station_type === chargeType
  );
  return (
    <div className={styles.reportSelector}>
      <select onChange={(e) => setReportType(e.target.value)}>
        <option value={""}> {t("report_type")} </option>
        <option value={"swaps"}>{t("battery_change")}</option>
        {user?.level === "admin" && (
          <>
            <option value={"rents"}> {t("rent_car")}</option>
            <option value={"chargeRents"}>{t("rent_energy")}</option>
            <option value={"payments"}>{t("payment")}</option>
          </>
        )}
      </select>
      {reportType === "rents" && (
        <select onChange={(e) => setVehicleId(e.target.value)}>
          <option value={""}> {t("all_vehicles")} </option>
          {bikes &&
            bikes.map((bike) => (
              <option key={bike.bike_id} value={bike.bike_id}>
                {bike.licence_plate}
              </option>
            ))}
        </select>
      )}
      {reportType === "swaps" && (
        <>
          <select onChange={(e) => setStationId(e.target.value)}>
            <option value={""}> {t("all_stations")} </option>
            {stations &&
              stations.map((station) => (
                <option key={station.station_id} value={station.station_id}>
                  {station.name}
                </option>
              ))}
          </select>
          <select onChange={(e) => setSwapVehicle(e.target.value)}>
            <option value={""}> {t("all_vehicles")} </option>
            {bikes &&
              bikes.map((bike) => (
                <option key={bike.bike_id} value={bike.bike_id}>
                  {bike.licence_plate}
                </option>
              ))}
          </select>
        </>
      )}
      {reportType === "chargeRents" && (
        <select onChange={(e) => setChargeType(e.target.value)}>
          <option value={""}> {t("all_types")} </option>
          {swapTypes.map((type, index) => (
            <option key={index} value={type}>
              {t(type)}
            </option>
          ))}
        </select>
      )}
      {reportType === "chargeRents" && chargeType !== "" && (
        <select onChange={(e) => setChargeId(e.target.value)}>
          <option value={""}> {t("all_stations")} </option>
          {filteredStations.map((station) => (
            <option key={station.station_id} value={station.station_id}>
              {station.name}
            </option>
          ))}
        </select>
      )}
      <DatePicker
        selected={startDate}
        locale={selectedLang === "en" ? enUS : tr}
        onChange={(date) => setStartDate(date)}
        dateFormat={selectedLang === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
      />
      <DatePicker
        selected={endDate}
        locale={selectedLang === "en" ? enUS : tr}
        onChange={(date) => setEndDate(date)}
        dateFormat={selectedLang === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
      />
      <button type="button" onClick={() => createReport()}>
        {t("report_creation")}
      </button>
    </div>
  );
};

export default ReportSelector;
