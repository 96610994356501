import styles from "./users.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import DriverListRow from "./DriverListRow";
import { Filter, hideByFilter } from "../../components/filter";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocalize } from "../../components/localize";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUsersLine,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

const Hourglass = () => {
  return (
    <div className={styles.hourglass}>
      <FontAwesomeIcon icon={faSpinner} />
    </div>
  );
};

const UserList = ({ setMode }) => {
  const driversList = useSelector((state) => state.drivers.list);
  const isLoading = useSelector((state) => state.drivers.isLoading);
  const [filterValue, setFilterValue] = useState("");
  const t = useLocalize();

  if (!driversList) return null;

  return (
    <div className={styles.usersList}>
      <h2>{t("drivers")}</h2>
      <div className={styles.topBar}>
        <Filter setFilter={setFilterValue} />
        <div>
          <button type="button" onClick={() => setMode("adduser")}>
            <FontAwesomeIcon icon={faPlus} /> {t("adddriver")}
          </button>
          {/*
          <button type="button" onClick={() => setMode("groupslist")}>
            <FontAwesomeIcon icon={faUsersLine} /> {t("groups")}
          </button>
  */}
        </div>
      </div>
      <table className={tableStyles.listTable}>
        <thead>
          <tr>
            <th></th>
            <th>
              {t("name")} {t("surname")}
            </th>
            <th>{t("tckn")}</th>
            <th>{t("last_login")}</th>
            <th>{t("company")}</th>
            <th>Status</th>
            <th>Vehicle</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {driversList?.map((driver, indice) => {
            if (
              hideByFilter(
                [driver.name, driver.surname, driver.identity_no, driver.phone],
                filterValue
              )
            )
              return (
                <DriverListRow
                  driver={driver}
                  indice={indice}
                  setMode={setMode}
                  key={driver.id}
                />
              );

            return null;
          })}
        </tbody>
      </table>
      {isLoading && <Hourglass />}
    </div>
  );
};

export default UserList;
