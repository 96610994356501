import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./rentabike.module.css";
import { useNavigate } from "react-router-dom";
import QrReader from "./qrReader";
import FindBikeMap from "./findBikeMap";
import {getBikeInfo} from "../../utils/getBikeInfo";
import RentConfirm from "./RentConfirm";
import RentProgress from "./rentProgress";
import BikeLeave from "./bikeLeave";

import { setQR } from "../../store/reducers/bikeRentReducer";

const Rentabike = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [mode, setMode] = useState("map");

  useEffect(() => checkUserInfo(), []);

  const checkUserInfo = () => {};

  const validateBike = (qrResult) => {
    getBikeInfo({
      user: user,
      serialNo: "123123123",
    }).then((result) => {
      const bike = result.bike;
      if (!bike.enabled) {
      }
      dispatch(setQR(qrResult));
      setMode("rentConfirm");
    });
  };

  return (
    <div className={styles.rentabike}>
      {mode === "map" && (
        <FindBikeMap
          modeChange={(mode) => setMode(mode)}
          onCancel={() => navigate(-1)}
        />
      )}

      {mode === "qr" && (
        <QrReader
          setMode={(mode) => setMode(mode)}
          onReadSuccess={(qrResult) => validateBike(qrResult)}
        />
      )}

      {mode === "rentConfirm" && (
        <RentConfirm setMode={(mode) => setMode(mode)} />
      )}

      {mode === "rentProgress" && (
        <RentProgress setMode={(mode) => setMode(mode)} />
      )}

      {mode === "bikeLeave" && <BikeLeave setMode={(mode) => setMode(mode)} />}
    </div>
  );
};

export default Rentabike;
