import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["pages", "user"],
};

export const rootReducers = rootReducer;

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  // reducer : rootReducer,
  reducer: persistedReducer,
  middleware: [],
});
