import { serverPath } from "./devSettings";

export const plugPowerSet = async (user, powerState) => {
  const reqData = {
    user: user,
    powerState: powerState,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/plugPowerSet", options);
  const result = await response.json();

  return result;
};
