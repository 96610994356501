import { serverPath } from "./devSettings";

const resetPassword = async (user) => {
  const reqData = {
    username: user.username,
    password: user.password,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/resetPassword", options);
  const result = await response.json();

  return result;
};

const changePassword = async (user) => {
  const reqData = {
    username: user.username,
    oldPassword: user.oldPassword,
    newPassword: user.newPassword,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/changePassword", options);
  const result = await response.json();

  return result;
};
const recoverPassword = async (username) => {
  const reqData = {
    username: username,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/recoverPassword", options);
  const result = await response.json();

  return result;
};

export { resetPassword, changePassword, recoverPassword };
