import { serverPath } from "./devSettings";

const addBike = async (bike) => {
  const reqData = bike;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addBike", options);
  const result = await response.json();

  return result;
};

export default addBike;
