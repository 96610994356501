import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { plugPowerSet } from "../../utils/smartPlug";
import getConsumption from "../../utils/getConsumption";

const SmartPlug = () => {
  const user = useSelector((state) => state.user);
  const [port1Status, setPort1Status] = useState(0);
  const [port1Energy, setPort1Energy] = useState(0);
  const [port1Cost, setPort1Cost] = useState(0);

  const [port2Status, setPort2Status] = useState(-1);
  const [port3Status, setPort3Status] = useState(-1);
  const [port4Status, setPort4Status] = useState(-1);

  setInterval(async () => {
    let result = await getConsumption();
    setPort1Cost(result.consumption[0].energy_delivered * 10);
    setPort1Energy(
      result.consumption[0].energy_delivered ? result.consumption[0].energy_delivered : 0
    );
  }, 5000);

  const plugPowerCommand = () => {
    if (port1Status === 0) {
      plugPowerSet(user, 1);
      setPort1Status(1);
    } else {
      plugPowerSet(user, 0);
      setPort1Status(0);
    }
  };

  const navigate = useNavigate();
  return (
    <div>
      <h2>SmartPlug</h2>
      <button type="button" onClick={() => navigate(-1)}>
        Geri
      </button>
      <div>
        <h3>Akıllı Priz</h3>
        <h4>
          Port 1 : {port1Status === 0 ? "Boş" : "Kullanımda"}{" "}
          <button type="button" onClick={() => plugPowerCommand()}>
            {port1Status === 0 ? "Başlat" : "Bitir"}
          </button>{" "}
          Enerji : {port1Energy} Watt/h , Ücret : {port1Cost} TL
        </h4>
        <h4 style={{ color: "gray" }}>Port 2 : {port2Status}</h4>
        <h4 style={{ color: "gray" }}>Port 3 : {port3Status}</h4>
        <h4 style={{ color: "gray" }}>Port 4 : {port4Status}</h4>
      </div>
    </div>
  );
};

export default SmartPlug;
