import { useState } from "react";
import styles from "./passwordRecover.module.css";
import { recoverPassword } from "../../utils/resetPassword";
import Toast from "../../components/toast";
import { useLocalize } from "../../components/localize";
const PasswordRecover = () => {
  const [pageState, setPageState] = useState(0);
  const [focused, setFocused] = useState(false);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const t = useLocalize();
  const sendRecoverAccountMail = async () => {
    if (username === "" || username === " ") {
      setShowMsg(true);
      setMessage("Lütfen geçerli bir kullanıcı adı sağlayınız");
    }
    const res = await recoverPassword(username);
    if (res.state === "fail") {
      setShowMsg(true);
      setMessage("Girilen kullanıcı adıyla oluşturulmuş bir hesap bulunamadı.");
    } else if (res.state === "success") {
      setPageState(1);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <img
          src="/images/greengo_logo.png"
          alt="bm logo"
          className={styles.logo}
        />
        <h3 className={styles.header}>Şifremi Unuttum</h3>
        {pageState === 0 ? (
          <>
            <p>Sisteme giriş için kullandığınız kullanıcı adını giriniz. </p>
            <p>
              Şifre yenileme linki, tanımlı e-posta adresinize kısa süre
              içerisinde gönderilecektir.
            </p>
            <input
              label="Kullanıcı Adı"
              placeholder="Kullanıcı adınızı giriniz..."
              required
              value={username}
              onChange={(event) => setUsername(event.currentTarget.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              mt="md"
              autoComplete="nope"
              className={styles.input}
            />

            <button
              className={styles.button}
              onClick={sendRecoverAccountMail}
            >
              Gönder
            </button>
          </>
        ) : (
          <>
            <p>
              Şifre yenileme mesajı gönderildi, kayıtlı E-Posta hesabınızı
              kontrol ediniz.
            </p>

            <a href="/">
              <button className={styles.button}>Giriş Sayfası</button>
            </a>
          </>
        )}
      </div>
      <Toast
        title={t("message")}
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
    </div>
  );
};

export default PasswordRecover;
