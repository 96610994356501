import styles from "./box.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBicycle } from "@fortawesome/free-solid-svg-icons";

const Box = ({ bgColor, value, property, icon, message}) => {
  return (
    <div className={`${styles.box} ${bgColor}`}>
      <div className={styles.infoContainer}>
        <div className={styles.propertyPart}><span>{property}</span></div>
        <div className={styles.valuePart}><span>{value}</span></div>
        <p>{message}</p>
        <div className={styles.iconContainer}><span><FontAwesomeIcon icon={icon}/></span></div>
      </div>
    </div>
  );
};

export default Box;
