import {serverPath} from './devSettings';

const getUsersList = async (user) => {


  const options = {
    method : 'GET',
    headers : {
      'Content-Type' : 'application/json',
    },
    credentials: 'include',
  }

  const response = await fetch(serverPath + '/getUsers', options)
  const result = await response.json()

  return(result);
}

export default getUsersList;