import { serverPath } from "./devSettings";

const addBattery = async (battery) => {
  const reqData = {
    battery_capacity: battery.battery_capacity,
    battery_serial_no: battery.battery_serial_no,
    battery_type : battery.battery_type
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addBattery", options);
  const result = await response.json();
  return result;
};

export default addBattery;
