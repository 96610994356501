import { serverPath } from "./devSettings";

const getPrices = async () => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getPrices", options);
  const result = await response.json();

  return result;
};

const editPricing = async (data) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/editPricing", options);
  const result = await response.json();

  return result;
};

export { getPrices, editPricing };
