import { serverPath } from "./devSettings";

const getBikeInfo = async ({ user, serialNo }) => {
  const reqData = {
    username: user.username,
    serialNo: serialNo,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: 'include',
  };

  const response = await fetch(serverPath + "/getBikeInfo", options);
  const result = await response.json();

  return result;
};

const getBikesLocation = async ({ user }) => {
  const reqData = {
    username: user.username,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: 'include',
  };

  const response = await fetch(serverPath + "/getBikeLocations", options);
  const result = await response.json();

  return result;
};


export  {getBikeInfo, getBikesLocation};
