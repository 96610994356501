import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticalChart = ({ usage }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Rent Usage",
      },
    },
  };

  const [usageData, setData] = useState(null);

  const updateUsage = (usage) => {
    if (usage?.rentUsage) {
      let usageObj = {};
      let graphObj = {};
      let newDate, newDateStr;

      usage?.rentUsage?.forEach((rentUsage) => {
        newDate = new Date(Date.parse(rentUsage.date));
        newDateStr =
          newDate.getDate() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getFullYear();
        if (usageObj[newDateStr]) {
          usageObj[newDateStr].counter =
            usageObj[newDateStr]?.counter + rentUsage?.counter;
        } else {
          usageObj[newDateStr] = { ...rentUsage };
        }
      });

      let today = new Date();
      for (let i = 0; i < 30; i++) {
        // usage?.rentUsage;
        let date = new Date(new Date().setDate(today.getDate() - i));
        let dateStr =
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear();
        if (usageObj[dateStr]) {
          graphObj[dateStr] = usageObj[dateStr]?.counter;
        } else {
          graphObj[dateStr] = 0;
        }
      }
      console.log("GRAPH : ", graphObj);
      setData(graphObj);
    }
  };

  useEffect(() => {
    updateUsage(usage);

    return () => {};
  }, [usage]);

  const data = {
    labels: usageData
      ? Object.keys(usageData)
          .toReversed()
          .map((obj) => {
            return obj.replace("-2023", "");
          })
      : [],
    datasets: [
      {
        label: "Usage",
        data: usageData
          ? Object.keys(usageData)
              .toReversed()
              .map((obj) => {
                return usageData[obj];
              })
          : [],
        // 10, 20, 30, 50, 40, 30, 60, 40, 100, 70, 45, 60, 110, 80, 90, 60, 70,
        // 40, 60, 80, 50, 60, 30, 30,

        backgroundColor: "#17A2B8",
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default VerticalChart;
