import styles from "./users.module.css";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";

import addUser from "../../utils/addUser";

import { useLocalize } from "../../components/localize";
import { useState, useRef } from "react";

import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";

import tableStyles from "../commonStyles/listTable.module.css";
import { fetchuserList } from "../../store/reducers/usersList";

var turkish_map = {
  ş: "s",
  ü: "u",
  ı: "i",
  ğ: "g",
  ç: "c",
  ö: "o",
};

const validateName = (inputText) => {
  if (/[0-9]/.test(inputText)) {
    return false;
  }
  return true;
};

const validateSurname = (inputText) => {
  if (/[0-9]/.test(inputText)) {
    return false;
  }
  return true;
};

const validateId = (inputNum) => {
  if (/^[0-9]+$/.test(inputNum) && inputNum.length === 11) {
    return true;
  }
  return false;
};

const validateEmail = (inputText) => {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) return true;
  return false;
};

const validateGSM = (inputNum) => {
  if (/^[0-9]+$/.test(inputNum) && inputNum.length === 10) {
    return true;
  } else {
    return false;
  }
};

const verifyForm = (user) => {
  if (
    user.name === "" ||
    user.surname === "" ||
    user.username === "" ||
    user.email === "" ||
    user.tckn === ""
  )
    return false;

  if (
    !user.name ||
    !user.surname ||
    !user.username ||
    !user.email ||
    !user.tckn ||
    !user.company_id
  )
    return false;

  return true;
};

const latinize = (str) => {
  return str.replace(/[^A-Za-z0-9]/g, function (x) {
    return turkish_map[x] || x;
  });
};

const createRandomPassword = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let length = 8;
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const AddUser = ({ setMode }) => {
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const authorizedUser = useSelector((state) => state.user);
  const companyList = useSelector((state) => state.companies.list);
  const dispatch = useDispatch();
  const usernameRef = useRef();

  const [user, setUser] = useState({
    username: "",
    name: "",
    surname: "",
    level: null, //to do dynamic
    group: null,
    email: "",
    password: createRandomPassword(),
    gsm_nr: "",
    tckn: "",
    company_id:
      authorizedUser.level === "admin" ? null : authorizedUser.companyId,
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const saveUser = () => {
    user.username = usernameRef.current.value;

    if (validateEmail(user.email) === false) {
      setMessage("E-Mail adresi geçerli değil!");
      setShowMsg(true);
      return;
    }

    if (
      validateName(user.name) === false ||
      validateSurname(user.surname) === false
    ) {
      setMessage("Lütfen isim ve soyad için sadece harf kullanın.");
      setShowMsg(true);
      return;
    }

    if (verifyForm(user) === false) {
      setMessage("Formda eksik veri!");
      setShowMsg(true);
      return;
    }

    if (validateId(user.tckn) == false) {
      setMessage("Hatalı kimlik numarası!");
      setShowMsg(true);
      return;
    }

    if (validateGSM(user.gsm_nr) == false) {
      setMessage("Hatalı telefon numarası!");
      setShowMsg(true);
      return;
    }

    addUser(user).then((result) => {
      if (result.state === "success") {
        setMessage(t("new_user_added"));
        setShowMsg(true);
        dispatch(fetchuserList());
      }
    });

    setUser({
      ...user,
      username: "",
      name: "",
      surname: "",
      level: "",
      group: "0",
      email: "",
      password: createRandomPassword(),
      gsm_nr: "",
      tckn: "",
      company_id:
        authorizedUser.level === "admin" ? null : authorizedUser.companyId,
    });
  };

  return (
    <div className={styles.userDetails}>
      <h2>{t("users")}</h2>
      <div className={styles.topBar}>
        <h3>{t("adduser")}</h3>
        <section>
          <button
            onClick={() => {
              saveUser();
            }}
          >
            <FontAwesomeIcon icon={faSave} />
            {t("save")}
          </button>
          <button onClick={() => setMode("list")}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
          </button>
        </section>
      </div>

      <div className={styles.detailsList}>
        <table className={tableStyles.listTable}>
          <tbody>
            {authorizedUser.level === "admin" && (
              <tr>
                <td>{t("company")}</td>
                <td>
                  <select
                    name="company_id"
                    onChange={handleChange}
                    value={user.company_id}
                  >
                    <option></option>
                    {companyList.map((company) => (
                      <option value={company.id}>{company.company_name}</option>
                    ))}
                  </select>
                </td>
              </tr>
            )}
            <tr>
              <td>{t("name")}</td>
              <td>
                <input
                  name="name"
                  type="text"
                  value={user.name}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>{t("surname")}</td>
              <td>
                <input
                  name="surname"
                  type="text"
                  value={user.surname}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>{t("username")}</td>
              <td>
                <input
                  name="username"
                  type="text"
                  ref={usernameRef}
                  value={latinize((user.name + user.surname).toLowerCase())}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>{t("tckn")}</td>
              <td>
                <input
                  name="tckn"
                  type="text"
                  value={user.tckn}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>{t("level")}</td>
              <td>
                <select name="level" onChange={handleChange} value={user.level}>
                  <option></option>
                  <option value="indv_user">{t("indv_user")}</option>
                  <option value="corp_user">{t("corp_user")}</option>
                  <option value="corp_admin">{t("corp_admin")}</option>
                  <option value="partner_admin">{t("partner_admin")}</option>
                  <option value="admin">{t("admin")}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>{t("email")}</td>
              <td>
                <input
                  name="email"
                  onChange={handleChange}
                  value={user.email}
                />
              </td>
            </tr>
            <tr>
              <td>{t("gsm_nr")}</td>
              <td>
                <input
                  name="gsm_nr"
                  onChange={handleChange}
                  value={user.gsm_nr}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Toast
          title="Message"
          message={message}
          show={showMsg}
          onClose={() => setShowMsg(false)}
        />
      </div>
    </div>
  );
};

AddUser.defaultProps = {
  edit: false,
};

export default AddUser;
