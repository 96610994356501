import { serverPath } from "./devSettings";

const getZones = async () => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getZones", options);
  const result = await response.json();

  return result;
};

const addZone = async (zone) => {
  const reqData = {
    zone: zone,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addZone", options);
  const result = await response.json();

  return result;
};

const editZone = async (zone) => {
  const reqData = {
    zone: zone,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/editZone", options);
  const result = await response.json();

  return result;
};

const deleteZone = async (zone) => {
  const reqData = {
    zone: zone,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/deleteZone", options);
  const result = await response.json();

  return result;
};

export { getZones, addZone, deleteZone, editZone };
