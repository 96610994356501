import React, { useState } from "react";
import styles from "./filter.module.css";
import { useLocalize } from "../localize";

function hideByFilter(itemList, filterValue) {
  let i = 0;
  if (filterValue.length > 0) {
    while (i < itemList?.length) {
      console.log("EXAMP : ", itemList);
      if (itemList[i]?.toString().search(new RegExp(filterValue, "i")) >= 0)
        return true;
      i++;
    }
  } else {
    return true;
  }
}

const Filter = ({ setFilter }) => {
  const t = useLocalize();
  const [filterText, setFilterText] = useState("");

  function handleChange(event) {
    setFilterText(event.target.value);
    setFilter(event.target.value);
  }
  function clearFilter() {
    setFilter("");
    setFilterText("");
  }
  return (
    <div className={styles.filterBox}>
      <input
        className={styles.filterInput}
        type="text"
        placeholder={t("search")}
        onChange={handleChange}
        value={filterText}
      />{" "}
      <span className={styles.clearFilter} onClick={clearFilter}>
        X
      </span>
    </div>
  );
};

export { Filter, hideByFilter };
