/* eslint-disable */

import ChargeLevelGauge from "./chargeLevelGauge";
import { useSelector, useDispatch } from "react-redux";
import styles from "./rentabike.module.css";
import { now, elapsedTime } from "./rentUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill1Wave,
  faArrowLeft,
  faClock,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";

import { startRent } from "../../store/reducers/bikeRentReducer";

const RentConfirm = ({ setMode }) => {
  const rent = useSelector((state) => state.rent);
  const dispatch = useDispatch();

  const rentStart = () => {
    dispatch(
      startRent({
        bikeId: 12345,
        qrCode: 0,
        stationId: 556677,
        rentBegin: now(),
        initialCost: rent.initialCost,
        cost: rent.initialCost,
      })
    );
    setMode("rentProgress");
  };

  return (
    <div className={styles.rentConfirm}>
      <h1 className={styles.pageTitle}>Ready to Ride?</h1>
      <div className={styles.confirmInfo}>
        <p>
          <FontAwesomeIcon icon={faMoneyBill1Wave} /> Initial Cost :{" "}
          {rent.initialCost} TL
        </p>
        <p>
          <FontAwesomeIcon icon={faClock} /> Cost Per Minute :{" "}
          {rent.costPerMinute} TL
        </p>
        <div className={styles.chargeInfo}>
          <FontAwesomeIcon icon={faBolt} /> Charge : {rent.chargeLevel} %
          <ChargeLevelGauge level={rent.chargeLevel} />
        </div>
      </div>

      <section className={styles.rentConfirmButtons}>
        <button type="button" onClick={() => setMode("qr")}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>
        <button type="button" onClick={rentStart}>
          START!
        </button>
      </section>
    </div>
  );
};

export default RentConfirm;
