import { serverPath } from "./devSettings";

const getRentedCharges = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getRentedCharges", options);
  const result = await response.json();

  return result;
};

const terminateChargeRent = async (charge) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(charge),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/terminateChargeRent", options);
  const result = await response.json();

  return result;
};

export { getRentedCharges, terminateChargeRent };
