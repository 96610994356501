import GaugeChart from "react-gauge-chart";
import styles from "./stations.module.css";
import Chart from "../../components/chart/Chart";
import { useLocalize } from "../../components/localize";
import { useSelector } from "react-redux";
import { de } from "date-fns/locale";
const StationsGaugeChart = () => {
  const selectedStation = useSelector(
    (state) => state.stations.selectedStation
  );
  const plugsList = useSelector((state) => state.stations.plugsList);
  const swapsList = useSelector((state) => state.stations.swapsList);
  const multiSwapsList = useSelector((state) => state.stations.multiSwapsList);

  let details;
  if (selectedStation.station_type === "swap") {
    details = swapsList.filter(
      (swap) => swap.station_id === selectedStation.station_id
    );
  } else if (selectedStation.station_type === "plug") {
    details = plugsList.filter(
      (plug) => plug.station_id === selectedStation.station_id
    );
  } else if (selectedStation.station_type === "multiswap") {
    details = multiSwapsList.filter(
      (multiswap) => multiswap.station_id === selectedStation.station_id
    );
  }
  const t = useLocalize();
  const chartStyle = {
    height: 200,
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.gaugeChartName}>
          <p>{t("instant_power")} PORT 1</p>
          <div style={{ width: "300px" }}>
            <GaugeChart
              id="gauge-chart1"
              nrOfLevels={30}
              arcWidth={0.2}
              percent={details[0]?.port_a_power_watt / 100}
              textColor={"#03bafc"}
              formatTextValue={() =>
                details[0] ? details[0]?.port_a_power_watt : "0 " + " Watt"
              }
            />
          </div>
        </div>
        <div className={styles.gaugeChartName}>
          <p>{t("instant_power")} PORT 2</p>
          <div style={{ width: "300px" }}>
            <GaugeChart
              id="gauge-chart1"
              nrOfLevels={30}
              arcWidth={0.2}
              percent={details[0]?.port_b_power_watt / 100}
              textColor={"#03bafc"}
              formatTextValue={() =>
                details[0] ? details[0]?.port_a_power_watt : "0 " + " Watt"
              }
            />
          </div>
        </div>
      </div>
      <Chart type={1}></Chart>
      <Chart type={4}></Chart>
    </div>
  );
};

export default StationsGaugeChart;
