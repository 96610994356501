import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateList: false,
  userList: null,
  isLoading: false,
};

export const userListSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    filluserList: (state, action) => {
      state.updateList = false;
      state.isLoading = false;
      state.userList = action.payload.userList;
    },
    fetchuserList: (state, action) => {
      state.updateList = true;
      state.isLoading = true;
    },
  },
});

export const { filluserList, fetchuserList } = userListSlice.actions;

export default userListSlice.reducer;
