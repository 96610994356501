import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateList: false,
  list: [],
};

export const driversLicence = createSlice({
  name: "driversLicence",
  initialState,
  reducers: {
    fillDriversLicenceList: (state, action) => {
      state.updateList = false;
      state.list = action.payload.list;
    },
    fetchDriversLicenceList: (state, action) => {
      state.updateList = true;
    },
  },
});

export const { fillDriversLicenceList, fetchDriversLicenceList } = driversLicence.actions;
export default driversLicence.reducer;
