import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateList: false,
  list: [],
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    fillReportList: (state, action) => {
      state.updateList = false;
      state.list = action.payload.list;
    },
    fetchReportList: (state, action) => {
      state.updateList = true;
    },
  },
});

export const { fillReportList, fetchReportList } = reportsSlice.actions;
export default reportsSlice.reducer;
