import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faMotorcycle,
  faLink,
  faLinkSlash,
  faCarBattery,
  faPlugCircleCheck,
  faPlugCircleXmark,
  faCheck,
  faXmark,
  faInfo,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch } from "react-redux";

import { useLocalize } from "../../components/localize";

import styles from "./bikes.module.css";
import { setSelectedBike } from "../../store/reducers/selectedBikeReducer";

const BikeListRow = ({ bike, setMode, indice }) => {
  const dispatch = useDispatch();
  const t = useLocalize();

  const isOnline = (last_comm_timestamp) => {
    let a = new Date(last_comm_timestamp).getTime();
    let now = new Date().getTime();
    if (Math.floor((now - a) / 1000) < 120) return true; //120 seconds limit for offline icon
    return false;
  };

  const selectBike = () => {
    dispatch(setSelectedBike({ selectedBike: bike }));
  };
  const formattedName = (name) => {
    if (name) {
      return name.replace(/([a-z])([A-Z])/g, "$1 $2");
    } else return null;
  };
  return (
    <tr>
      <td>{bike.bike_id}</td>
      <td>{bike.type}</td>
      <td>{bike.serial_no}</td>
      <td>{bike.gsm_imei}</td>
      <td>{bike.gsm_nr}</td>
      <td>{bike.hw_vers}</td>
      <td>{bike.fw_vers}</td>
      <td>{bike.brand}</td>
      <td>{bike.licence_plate}</td>
      <td>{bike.company_name}</td>
      <td>{formattedName(bike.assigned_courier_name)}</td>
      {/*
      <td>
        {bike.is_active ? (
          <>
            <FontAwesomeIcon icon={faCheck} /> {t("Yes")}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faXmark} /> {t("No")}
          </>
        )}
      </td>
        */}
      <td>
        {bike.actual_lock_status ? (
          <>
            <FontAwesomeIcon icon={faLock} />
            {t("Locked")}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faMotorcycle} /> {t("in_use")}
          </>
        )}
      </td>
      <td>
        {isOnline(bike.last_comm_timestamp) ? (
          <>
            <FontAwesomeIcon
              icon={faLink}
              style={{ color: "green" }}
            />{" "}
            {t("connected")}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faLinkSlash} /> {t("offline")}
          </>
        )}
      </td>
      {/*
      <td>
        <FontAwesomeIcon icon={faCarBattery} />{" "}
        {(bike.voltage / 1000).toFixed(2)} V
      </td>
      <td>
        {bike.is_charging ? (
          <>
            <FontAwesomeIcon icon={faPlugCircleCheck} /> {t("yes")}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faPlugCircleXmark} /> {t("no")}
          </>
        )}
      </td>
        */}
      {/* <td>
        <span
          className={styles.clickable}
          onClick={() => {
            selectBike(bike);
            setMode("details");
          }}
        >
          <FontAwesomeIcon icon={faInfo} /> {t("details")}
        </span>
      </td> */}
      <td>
        <span
          className={styles.clickable}
          onClick={() => {
            selectBike(bike);
            setMode("edit");
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> {t("details")}
        </span>
      </td>
    </tr>
  );
};

export default BikeListRow;
