import styles from "./rentabike.module.css";

const ChargeLevelGauge = ({ level }) => {
  let levelStyle = { width: `${level}%` };

  if (level > 75) {
    levelStyle.backgroundColor = "yellowgreen";
  } else if (level > 50) {
    levelStyle.backgroundColor = "yellow";
  } else if (level > 25) {
    levelStyle.backgroundColor = "orange";
  } else {
    levelStyle.backgroundColor = "red";
  }

  return (
    <>
      <div className={styles.chargeLevelContainer}>
        <div className={styles.chargelevel} style={levelStyle}></div>
      </div>
      {/*<div className={styles.levelText}>{level} %</div> */}
    </>
  );
};

export default ChargeLevelGauge;
