import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import tableStyles from "../commonStyles/listTable.module.css";
import styles from "../bikes/bikes.module.css";
import { useLocalize } from "../../components/localize";
import { useState } from "react";

import addBattery from "../../utils/addBattery";
import editBattery from "../../utils/editBattery";
import deleteBattery from "../../utils/deleteBattery";

import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";

import { fetchBatteriesList } from "../../store/reducers/batteriesReducer";

import { dateOnly, dateOnlyEU } from "../../utils/dateFormat";

const BatteryOperations = ({ setMode, edit }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editLock, setEditLock] = useState(false);

  const selectedBattery = useSelector(
    (state) => state.selectedBattery.selectedBattery
  );
  const dispatch = useDispatch();

  let defaultBattery = {};

  const deleteBatteryRequest = () => {
    setConfirmDelete(true);
  };

  if (!edit) {
    defaultBattery = {
      battery_capacity: "",
      battery_type: "li-ion",
      charge_level: "",
      battery_serial_no: "",
    };
  } else {
    defaultBattery = { ...selectedBattery };
  }

  const [battery, setBattery] = useState(defaultBattery);

  const handleChange = (e) => {
    setBattery({ ...battery, [e.target.name]: e.target.value });
  };

  const saveBattery = () => {
    if (edit) {
      editBattery(battery);
      setMessage(t("battery_saved"));
    } else {
      addBattery(battery);
      setMessage(t("battery_added"));
      setBattery({
        ...battery,
        battery_capacity: "",
        battery_type: "li-ion",
        charge_level: "",
        battery_serial_no: "",
      });
    }
    setShowMsg(true);
    dispatch(fetchBatteriesList());
  };

  return (
    <div className={styles.editSectionContainer}>
      <section className={styles.topBar}>
        <h3>{edit ? t("battery_details") : t("add_battery")}</h3>

        <section>
          {edit && (
            <button onClick={() => deleteBatteryRequest()}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </button>
          )}
          <button
            onClick={() => {
              if (edit) {
                if (editLock) {
                  saveBattery();
                  setEditLock(false);
                } else {
                  setEditLock(true);
                }
              } else {
                saveBattery();
                setEditLock(false);
              }
              dispatch(fetchBatteriesList());
            }}
          >
            <FontAwesomeIcon icon={faSave} />{" "}
            {edit ? (editLock ? t("save") : t("edit")) : t("add")}
          </button>
          <button onClick={() => setMode("list")}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        </section>
      </section>
      <table className={tableStyles.listTable}>
        <tr>
          <td>{t("battery_type")}</td>
          {!edit || editLock ? (
            <td>
              <select
                name="battery_type"
                onChange={handleChange}
                value={battery.battery_type}
              >
                <option value="scooter">Li-ion</option>
              </select>
            </td>
          ) : (
            <td>{battery.battery_type}</td>
          )}
        </tr>
        <tr>
          <td>{t("production_date")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="production_date"
                value={dateOnlyEU(battery.production_date, selectedLang)}
                onChange={handleChange}
                readOnly
              />
            </td>
          ) : (
            <td>
              {battery.production_date
                ? dateOnlyEU(battery.production_date, selectedLang)
                : "-"}
            </td>
          )}
        </tr>
        <tr>
          <td>{t("first_charge_date")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="first_charge_date"
                value={dateOnlyEU(battery.first_charge_date, selectedLang)}
                onChange={handleChange}
                readOnly
              />
            </td>
          ) : (
            <td>
              {battery.first_charge_date
                ? dateOnlyEU(battery.first_charge_date, selectedLang)
                : "-"}
            </td>
          )}
        </tr>
        <tr>
          <td>{t("associated_vehicle")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="associated_vehicle"
                value={battery.associated_vehicle}
                onChange={handleChange}
                readOnly
              />
            </td>
          ) : (
            <td>
              {battery.associated_vehicle ? battery.associated_vehicle : "-"}
            </td>
          )}
        </tr>
        <tr>
          <td>{t("associated_station")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="associated_station"
                value={battery.associated_station}
                onChange={handleChange}
                readOnly
              />
            </td>
          ) : (
            <td>
              {battery.associated_station ? battery.associated_station : "-"}
            </td>
          )}
        </tr>
        <tr>
          <td>{t("associated_warehouse")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="associated_warehouse"
                value={battery.associated_warehouse}
                onChange={handleChange}
                readOnly
              />
            </td>
          ) : (
            <td>
              {battery.associated_warehouse
                ? battery.associated_warehouse
                : "-"}
            </td>
          )}
        </tr>
        <tr>
          <td>{t("charge_level")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="charge_level"
                value={battery.charge_level}
                onChange={handleChange}
                readOnly
              />
            </td>
          ) : (
            <td>{battery.charge_level ? battery.charge_level + "%" : "0%"}</td>
          )}
        </tr>
        <tr>
          <td>{t("connected")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="connected"
                value={battery.connected}
                onChange={handleChange}
                readOnly
              />
            </td>
          ) : (
            <td>{battery.connected ? t("Yes") : t("No")}</td>
          )}
        </tr>
        <tr>
          <td>{t("battery_capacity")}</td>
          {!edit || editLock ? (
            <td>
              <input
                name="battery_capacity"
                type="text"
                value={battery.battery_capacity}
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{battery.battery_capacity}</td>
          )}
        </tr>
        <tr>
          <td>{t("serial_no")}</td>
          {!edit || editLock ? (
            <td>
              <input
                name="battery_serial_no"
                type="text"
                value={battery.battery_serial_no}
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{battery.battery_serial_no}</td>
          )}
        </tr>
        {/* <tr>
          <td>{t("")}</td>
          <td>
            <input type="text" value={bike.color} />
          </td>
        </tr>
        <tr>
          <td>Brand</td>
          <td>
            <input type="text" value={bike.brand} />
          </td>
        </tr>
        <tr>
          <td>Group</td>
          <td>
            <input type="text" value={bike.group} />
          </td>
        </tr>
        <tr>
          <td>Power</td>
          <td>
            <input type="text" value={bike.motor_power} />
          </td>
        </tr>
        <tr>
          <td>Battery</td>
          <td>
            <input type="text" value={bike.battery_id} />
          </td>
        </tr>
        <tr>
          <td>Speed Limit</td>
          <td>
            <input type="text" value={bike.speed_limit} />
          </td>
        </tr>
        <tr>
          <td>GSM IMEI</td>
          <td>
            <input type="text" value={bike.gsm_imei} />
          </td>
        </tr>
        <tr>
          <td>GSM Nr</td>
          <td>
            <input type="text" value={bike.gsm_nr} />
          </td>
        </tr>
        <tr>
          <td>GSM SIM CCID</td>
          <td>
            <input type="text" value={bike.gsm_ccid} />
          </td>
        </tr>
        <tr>
          <td>GSM Operator</td>
          <td>
            <input type="text" value={bike.gsm_operator} />
          </td>
        </tr>
        <tr>
          <td>Enabled Hours</td>
          <td>
            <input type="text" />
          </td>
        </tr> */}
      </table>
      <Toast
        title={t("message")}
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
      {confirmDelete && (
        <ConfirmDialog
          title={t("delete_title")}
          message={t("confirm_delete")}
          onConfirm={() => {
            deleteBattery(battery);
            setMode("list");
            dispatch(fetchBatteriesList());
          }}
          onClose={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
};

BatteryOperations.defaultProps = {
  edit: false,
};

export default BatteryOperations;
