import { serverPath } from "./devSettings";

const getBatteries = async ({ user, serialNo }) => {
  

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: 'include',
  };

  const response = await fetch(serverPath + "/getBatteries", options);
  const result = await response.json();

  return result;
};

export default getBatteries;
