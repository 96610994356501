import VerticalChart from "../verticalChart/VerticalChart";
import VerticalChart1 from "../verticalChart/VerticalChart1";
import { VerticalChart2 } from "../verticalChart/VerticalChart2";
import { VerticalChart3 } from "../verticalChart/VerticalChart3";
import styles from "./chart.module.css";
const Chart = ({ type, usage }) => {
  if (type === 1) {
    return (
      <div className={styles.chartBox}>
        <VerticalChart usage={usage}></VerticalChart>
      </div>
    );
  } else if (type === 2) {
    return (
      <div className={styles.chartBox}>
        <VerticalChart1 usage={usage}></VerticalChart1>
      </div>
    );
  } else if (type === 3) {
    return (
      <div className={styles.chartBox}>
        <VerticalChart2 usage={usage}></VerticalChart2>
      </div>
    );
  }
};

export default Chart;
