export const now = () => {
    return Math.floor(Date.now() / 1000);
  };

export const elapsedTime = (duration) => {
    let hours = parseInt(duration / 1440).toString();
    let minutes = parseInt((duration % 1440) / 60).toString();
    let seconds = (duration % 60).toString();
    return (
        hours.padStart(2, "0") +
        ":" +
        minutes.padStart(2, "0") +
        ":" +
        seconds.padStart(2, "0")
    );
};

