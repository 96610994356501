import styles from "./users.module.css";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";

import {addDriver} from "../../utils/drivers";

import { useLocalize } from "../../components/localize";
import { useState, useRef } from "react";

import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";

import tableStyles from "../commonStyles/listTable.module.css";
import { fetchDriverList } from "../../store/reducers/driversList";

var turkish_map = {
  ş: "s",
  ü: "u",
  ı: "i",
  ğ: "g",
  ç: "c",
  ö: "o",
};

const validateName = (inputText) => {
  if (/[0-9]/.test(inputText)) {
    return false;
  }
  return true;
};

const validateSurname = (inputText) => {
  if (/[0-9]/.test(inputText)) {
    return false;
  }
  return true;
};

const validateId = (inputNum) => {
  if (/^[0-9]+$/.test(inputNum) && inputNum.length === 11) {
    return true;
  }
  return false;
};

const validateGSM = (inputNum) => {
  if (/^[0-9]+$/.test(inputNum) && inputNum.length === 10) {
    return true;
  } else {
    return false;
  }
};

const verifyForm = (user) => {
  if (
    user.name === "" ||
    user.surname === "" ||
    user.tckn === ""
  )
    return false;

  if (
    !user.name ||
    !user.surname ||
    !user.tckn ||
    !user.company_id
  )
    return false;

  return true;
};


const AddDriver = ({ setMode }) => {
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const authorizedUser = useSelector((state) => state.user);
  const companyList = useSelector((state) => state.companies.list);
  const dispatch = useDispatch();
  const usernameRef = useRef();

  const [user, setUser] = useState({
    name: "",
    surname: "",
    gsm_nr: "",
    tckn: "",
    company_id: authorizedUser.companyId,
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const saveDriver = () => {
    if (
      validateName(user.name) === false ||
      validateSurname(user.surname) === false
    ) {
      setMessage("Lütfen isim ve soyad için sadece harf kullanın.");
      setShowMsg(true);
      return;
    }

    if (verifyForm(user) === false) {
      setMessage("Formda eksik veri!");
      setShowMsg(true);
      return;
    }

    if (validateId(user.tckn) == false) {
      setMessage("Hatalı kimlik numarası!");
      setShowMsg(true);
      return;
    }

    if (validateGSM(user.gsm_nr) == false) {
      setMessage("Hatalı telefon numarası!");
      setShowMsg(true);
      return;
    }

    addDriver(user).then((result) => {
      if (result.state === "success") {
        setMessage(t("new_user_added"));
        setShowMsg(true);
        dispatch(fetchDriverList());
      }
    });

    setUser({
      ...user,
      name: "",
      surname: "",
      gsm_nr: "",
      tckn: "",
      company_id:
        authorizedUser.level === "admin" ? null : authorizedUser.companyId,
    });
  };

  return (
    <div className={styles.userDetails}>
      <h2>{t("drivers")}</h2>
      <div className={styles.topBar}>
        <h3>{t("adddriver")}</h3>
        <section>
          <button
            onClick={() => {
              saveDriver();
            }}
          >
            <FontAwesomeIcon icon={faSave} />
            {t("save")}
          </button>
          <button onClick={() => setMode("list")}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
          </button>
        </section>
      </div>

      <div className={styles.detailsList}>
        <table className={tableStyles.listTable}>
          <tbody>
            {authorizedUser.level === "admin" && (
              <tr>
                <td>{t("company")}</td>
                <td>
                  <select
                    name="company_id"
                    onChange={handleChange}
                    value={user.company_id}
                  >
                    <option></option>
                    {companyList.map((company) => (
                      <option value={company.id}>{company.company_name}</option>
                    ))}
                  </select>
                </td>
              </tr>
            )}
            <tr>
              <td>{t("name")}</td>
              <td>
                <input
                  name="name"
                  type="text"
                  value={user.name}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>{t("surname")}</td>
              <td>
                <input
                  name="surname"
                  type="text"
                  value={user.surname}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>{t("tckn")}</td>
              <td>
                <input
                  name="tckn"
                  type="text"
                  value={user.tckn}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>{t("gsm_nr")}</td>
              <td>
                <input
                  name="gsm_nr"
                  onChange={handleChange}
                  value={user.gsm_nr}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Toast
          title="Message"
          message={message}
          show={showMsg}
          onClose={() => setShowMsg(false)}
        />
      </div>
    </div>
  );
};

AddDriver.defaultProps = {
  edit: false,
};

export default AddDriver;
