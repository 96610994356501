import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  FeatureGroup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import styles from "./stations.module.css";
import { defaultMarker, swapMarker, plugMarker } from "./markers";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocalize } from "../../components/localize";

const FitBounds = ({ parentRef, focusStation, mapReady }) => {
  const map = useMap();

  useEffect(() => {
    if (focusStation && mapReady) {
      if (focusStation.latitude && focusStation.longitude) {
        map.setView([focusStation.latitude, focusStation.longitude], 18);
      }
    } else {
      if (
        parentRef.current.getBounds()._southWest &&
        parentRef.current.getBounds()._northEast
      ) {
        map.fitBounds(parentRef.current.getBounds());
      }
    }
  }, [parentRef, map, focusStation]);

  return null;
};

const PopupContent = ({ station }) => {
  const t = useLocalize();
  return (
    <table className={styles.popupTable}>
      <tbody>
        <tr>
          <td>{t("name")}</td>
          <td>{station.name}</td>
        </tr>
        <tr>
          <td>{t("type")}</td>
          <td>{station.station_type}</td>
        </tr>
        <tr>
          <td>{t("serial_no")}</td>
          <td>{station.serial_no}</td>
        </tr>
      </tbody>
    </table>
  );
};

const StationsMap = ({
  handleStationLocation,
  stations,
  isEdit,
  editLock,
  station,
}) => {
  const featureRef = useRef(null);

  const [mapReady, setMapReady] = useState(false);
  const [position, setPosition] = useState({
    lat: station?.latitude,
    lng: station?.longitude,
  });

  const markerRef = useRef(null);
  const selectedStation = useSelector(
    (state) => state.stations.selectedStation
  );

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          handleStationLocation(marker.getLatLng());
        }
      },
      click() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          handleStationLocation(marker.getLatLng());
        }
      },
    }),
    []
  );

  return (
    <div className={styles.stationsMapContainer}>
      <MapContainer
        center={[41.012702, 28.99884]}
        zoom={13}
        scrollWheelZoom={true}
        whenReady={() => setMapReady(true)}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <FeatureGroup ref={featureRef}>
          {isEdit &&
            station &&
            station.latitude &&
            station.longitude &&
            station.latitude !== 0 &&
            station.longitude !== 0 && (
              <Marker
                ref={markerRef}
                id={station.serial_no}
                icon={station.station_type === "plug" ? plugMarker : swapMarker}
                position={position}
                draggable={editLock}
                eventHandlers={eventHandlers}
              >
                <Popup>
                  <PopupContent station={station}></PopupContent>
                </Popup>
                <FitBounds
                  parentRef={featureRef}
                  focusStation={selectedStation}
                  mapReady={mapReady}
                />
              </Marker>
            )}
          {stations?.map((station) => {
            if (
              station.latitude &&
              station.longitude &&
              station.latitude !== 0 &&
              station.longitude !== 0
            ) {
              return (
                <Marker
                  id={station.serial_no}
                  icon={
                    station.station_type === "plug" ? plugMarker : swapMarker
                  }
                  position={[station.latitude, station.longitude]}
                >
                  <Popup>
                    <PopupContent station={station}></PopupContent>
                  </Popup>

                  <FitBounds
                    parentRef={featureRef}
                    focusStation={selectedStation}
                    mapReady={mapReady}
                  />
                </Marker>
              );
            }
            return null;
          })}
        </FeatureGroup>
      </MapContainer>
    </div>
  );
};

export default StationsMap;
