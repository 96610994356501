import Layout from "../../components/layout";
import styles from "../users/users.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import { Filter, hideByFilter } from "../../components/filter";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocalize } from "../../components/localize";
import RentedChargesListRow from "./RentedChargesListRow";

const RentedCharges = () => {
  const RentedChargesList = useSelector((state) => state.rentedCharges.list);
  const [filterValue, setFilterValue] = useState("");
  const t = useLocalize();

  return (
    <Layout>
      <section className={styles.container}>
        <div className={styles.usersList}>
          <h2>{t("rented_charges")}</h2>
          <div className={styles.topBar}>
            <Filter setFilter={setFilterValue} />
          </div>
          {RentedChargesList?.length < 1 ? (
            <p>{t("no_rented_charges")}</p>
          ) : (
            <table className={tableStyles.listTable}>
              <thead>
                <tr>
                  <th></th>
                  <th>{t("port_id")}</th>
                  <th>{t("renter_name")}</th>
                  <th>{t("station_name")}</th>
                  <th>{t("start_date")}</th>
                  <th>{t("terminate_rent")}</th>
                </tr>
              </thead>
              <tbody>
                {RentedChargesList?.map((driver, indice) => {
                  if (hideByFilter([driver.customer_name], filterValue))
                    return (
                      <RentedChargesListRow
                        driver={driver}
                        indice={indice}
                        key={driver.id}
                      />
                    );

                  return null;
                })}
              </tbody>
            </table>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default RentedCharges;
