import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login";
import Home from "./pages/home";
import Companies from "./pages/companies";
import Users from "./pages/users";
import Drivers from "./pages/drivers";
import Bikes from "./pages/bikes";
import Fleet from "./pages/fleet";
import Stations from "./pages/stations";
import Batteries from "./pages/batteries";
import Rentabike from "./pages/rentabike";
import SmartPlug from "./pages/smartPlug";
import "./index.css";
import Reports from "./pages/reports";
import Profile from "./pages/profile";
import ActivateAccount from "./pages/account";
import ForgotPassword from "./pages/passwordRecover/ForgotPassword";
import PasswordRecover from "./pages/passwordRecover/PasswordRecover";
import Prices from "./pages/pricing/Prices";
import DriversLicence from "./pages/driversLicence";
import RentedBikes from "./pages/rentedBikes";
import RentedCharges from "./pages/rentedCharges";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={<LoginPage />}
        />
        <Route
          exact
          path="/home"
          element={<Home />}
        />
        <Route
          exact
          path="/companies"
          element={<Companies />}
        />
        <Route
          exact
          path="/bikes"
          element={<Bikes />}
        />
        <Route
          exact
          path="/fleet"
          element={<Fleet />}
        />
        <Route
          exact
          path="/stations"
          element={<Stations />}
        />
        <Route
          exact
          path="/batteries"
          element={<Batteries />}
        />
        <Route
          exact
          path="/users"
          element={<Users />}
        />
        <Route
          exact
          path="/drivers"
          element={<Drivers />}
        />
        <Route
          exact
          path="/rentabike"
          element={<Rentabike />}
        />
        <Route
          exact
          path="/smartplug"
          element={<SmartPlug />}
        />
        <Route
          exact
          path="/reports"
          element={<Reports />}
        />
        <Route
          exact
          path="/prices"
          element={<Prices />}
        />
        <Route
          exact
          path="/profile"
          element={<Profile />}
        />
        <Route
          exact
          path="/activateaccount"
          element={<ActivateAccount />}
        />
        <Route
          exact
          path="/forgotpassword"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/passwordrecover"
          element={<PasswordRecover />}
        />
        <Route
          exact
          path="/driversLicence"
          element={<DriversLicence />}
        />
        <Route
          exact
          path="/rentedBikes"
          element={<RentedBikes />}
        />
        <Route
          exact
          path="/rentedCharges"
          element={<RentedCharges />}
        />
        <Route
          path="*"
          element={<LoginPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
