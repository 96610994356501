import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  userEditModalIsOpen: false,
  userAddModalIsOpen: false,
  userDeleteModalIsOpen: false,
  bikeEditModalIsOpen: false,
  bikeAddModalIsOpen: false,
  bikeDeleteModalIsOpen: false,
  stationEditModalIsOpen: false,
  stationAddModalIsOpen: false,
  stationDeleteModalIsOpen: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleUserEditModal: (state, action) => {
      state.userEditModalIsOpen = action.payload.isOpen;
    },
    toggleUserAddModal: (state, action) => {
      state.userAddModalIsOpen = action.payload.isOpen;
    },
    toggleUserDeleteModal: (state, action) => {
      state.userDeleteModalIsOpen = action.payload.isOpen;
    },
    toggleBikeAddModal: (state, action) => {
      state.bikeAddModalIsOpen = action.payload.isOpen;
    },
    toggleBikeEditModal: (state, action) => {
      state.bikeEditModalIsOpen = action.payload.isOpen;
    },
    toggleBikeDeleteModal: (state, action) => {
      state.bikeDeleteModalIsOpen = action.payload.isOpen;
    },
    toggleStationAddModal: (state, action) => {
      state.stationAddModalIsOpen = action.payload.isOpen;
    },
    toggleStationEditModal: (state, action) => {
      state.stationEditModalIsOpen = action.payload.isOpen;
    },
    toggleStationDeleteModal: (state, action) => {
      state.stationDeleteModalIsOpen = action.payload.isOpen;
    },
    closeAllModals: () => initialState
  },
});

export const {toggleBikeAddModal, toggleBikeDeleteModal, toggleBikeEditModal, toggleStationAddModal, toggleStationDeleteModal, toggleStationEditModal, toggleUserAddModal, toggleUserDeleteModal, toggleUserEditModal, closeAllModals} = modalSlice.actions;

export default modalSlice.reducer;

// const modalReducers = (state = initialState, action) => {
//   const payload = action.payload;
//   switch (action.type) {
//     case actionTypes.TOGGLE_USER_EDIT_MODAL:
//       return {
//         ...initialState,
//         userEditModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_USER_ADD_MODAL:
//       return {
//         ...initialState,
//         userAddModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_USER_DELETE_MODAL:
//       return {
//         ...initialState,
//         userDeleteModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_BIKE_ADD_MODAL:
//       return {
//         ...initialState,
//         bikeAddModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_BIKE_EDIT_MODAL:
//       return {
//         ...initialState,
//         bikeEditModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_BIKE_DELETE_MODAL:
//       return {
//         ...initialState,
//         bikeDeleteModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_STATION_ADD_MODAL:
//       return {
//         ...initialState,
//         stationAddModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_STATION_EDIT_MODAL:
//       return {
//         ...initialState,
//         stationEditModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.TOGGLE_STATION_DELETE_MODAL:
//       return {
//         ...initialState,
//         stationDeleteModalIsOpen: payload.isOpen,
//       };
//     case actionTypes.CLOSE_ALL_MODALS:
//       return {
//         ...initialState,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };

// export default modalReducers;
