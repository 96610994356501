import { combineReducers } from "redux";
import userSlice from "./userReducer";
import bikeRentSlice from "./bikeRentReducer";
import bikesSlice from "./bikesReducer";
import pagesSlice from "./pagesReducer";
import stationSlice from "./stationReducer";
import modalSlice from "./modalReducer";
import selectedUserSlice from "./selectedUserReducer";
import selectedBikeSlice from "./selectedBikeReducer";
import selectedCompanySlice from "./selectedCompanyReducer";
import selectedBatterySlice from "./selectedBatteryReducer";
import userListSlice from "./usersList";
import batteriesSlice from "./batteriesReducer";
import companiesListReducer from "./companiesReducer";
import reportsReducer from "./reportsReducer";
import groupsReducer from "./groupsReducer";
import driversReducer from "./driversList";
import driversLicence from "./driversLicence";
import rentedBikes from "./rentsReducer";
import rentedCharges from "./chargeRentsReducer";

const rootReducer = combineReducers({
  user: userSlice,
  rent: bikeRentSlice,
  bikes: bikesSlice,
  pages: pagesSlice,
  stations: stationSlice,
  modal: modalSlice,
  selectedUser: selectedUserSlice,
  selectedBike: selectedBikeSlice,
  selectedCompany: selectedCompanySlice,
  selectedBattery: selectedBatterySlice,
  usersList: userListSlice,
  batteries: batteriesSlice,
  companies: companiesListReducer,
  reports: reportsReducer,
  groups: groupsReducer,
  drivers: driversReducer,
  driversLicence: driversLicence,
  rentedBikes: rentedBikes,
  rentedCharges: rentedCharges,
});

export default rootReducer;
