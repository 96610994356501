import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
};

const selectedBatterySlice = createSlice({
  name : "selectedBattery",
  initialState,
  reducers: {
    setSelectedBattery : (state, action) => {
      state.selectedBattery = action.payload.selectedBattery;
    }
  }
})

export const {setSelectedBattery} = selectedBatterySlice.actions;
export default selectedBatterySlice.reducer;

// const selectedBatteryReducer = (state = initialState, action) => {
//     const payload = action.payload;
//     switch(action.type){
    
//       case actionTypes.SET_SELECTED_BATTERY:
//         return {
//           ...state,
//           ...payload.selectedBattery,
//         }
//       default : 
//         return {
//           ...state
//         }    
//   }
// };

// export default selectedBatteryReducer;