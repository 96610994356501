import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  isRent: false,
  bikeId: null,
  qrCode: null,
  stationId: null,
  rentBegin: null,
  rentEnd: null,
  durationMinutes: 0,
  initialCost: 10,
  costPerMinute: 1,
  costPerMeter: 1,
  cost: 0,
  unit: "TL",
  warning: null,
  overtime: false,
  chargeLevel: 80,
  violations: {},
  dailyRentLog: [],
};

export const bikeRentSlice = createSlice({
  name: "bikeRent",
  initialState,
  reducers: {
    startRent: (state, action) => {
      state.isRent = true;
      state.bikeId = action.payload.bikeId;
      state.qrCode = action.payload.qrCode;
      state.stationId = action.payload.stationId;
      state.rentBegin = action.payload.rentBeginl;
      state.initialCost = action.payload.initialCost;
      state.cost = action.payload.cost;
    },
    finishRent: (state, action) => {
      state.isRent = false;
      state.durationMinutes = action.payload.durationMinutes;
      state.rentEnd = action.payload.rentEnd;
      state.cost = action.payload.cost;
      state.warning = action.payload.warning;
    },
    updateRent : (state, action) => {
      state.isRent = action.payload.isRent;
      state.durationMinutes = action.payload.durationMinutes;
      state.cost = action.payload.cost;
      state.warning = action.payload.warning;
      state.chargeLevel = action.payload.chargeLevel;
      state.violations = action.payload.violations;
      state.overtime = action.payload.overtime;
    },
    updateCost : (state, action) => {
      state.cost = action.payload.cost;
      state.durationMinutes = action.payload.durationMinutes;
    },
    setQR : (state, action) => {
      state.qrCode = action.payload;
    }
  },
});
// Action creators are generated for each case reducer function
export const { startRent, finishRent, updateCost, updateRent, setQR } = bikeRentSlice.actions;

export default bikeRentSlice.reducer;



// const bikeRentReducer = (state = initialState, action) => {
//   let payload = null;

//   switch (action.type) {
//     case actionTypes.START_RENT:
//       payload = action.payload;
//       return {
//         ...initialState,
//         isRent: true,
//         bikeId: payload.bikeId,
//         qrCode: payload.qrCode,
//         stationId: payload.stationId,
//         rentBegin: payload.rentBegin,
//         initialCost: payload.initialCost,
//         cost: payload.cost,
//       };
//     case actionTypes.FINISH_RENT:
//       payload = action.payload;
//       return {
//         ...state,
//         isRent: false,
//         durationMinutes: payload.durationMinutes,
//         rentEnd: payload.rentEnd,
//         cost: payload.cost,
//         warning: payload.warning,
//       };
//     case actionTypes.UPDATE_RENT:
//       payload = action.payload;
//       return {
//         ...state,
//         isRent: payload.isRent,
//         durationMinutes: payload.durationMinutes,
//         cost: payload.cost,
//         warning: payload.warning,
//         chargeLevel: payload.chargeLevel,
//         violations: payload.violations,
//         overtime: payload.overtime,
//       };
//     case actionTypes.UPDATE_COST:
//       payload = action.payload;
//       return {
//         ...state,
//         cost: payload.cost,
//         durationMinutes: payload.durationMinutes,
//       };
//     case actionTypes.SETQR:
//       payload = action.payload;
//       return {
//         ...state,
//         qrCode: payload,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };

// export default bikeRentReducer;
