import { useState } from "react";
import Layout from "../../components/layout";
import StationsList from "./StationsList";
import StationDetails from "./StationDetails";
import StationAdd from "./StationAdd";
import StationOperations from "./StationOperation";
import styles from "./stations.module.css";

const Stations = () => {
  const [mode, setMode] = useState("list");

  return (
    <Layout>
      <section className={styles.container}>
        {mode === "details" && (
          <StationDetails setMode={(mode) => setMode(mode)} />
        )}
        {mode === "list" && <StationsList setMode={(mode) => setMode(mode)} />}
        {mode === "edit" && (
          <StationOperations
            setMode={(mode) => {
              setMode(mode);
            }}
            edit
          />
        )}
        {mode === "add" && (
          <StationAdd
            setMode={(mode) => {
              setMode(mode);
            }}
          />
        )}
      </section>
    </Layout>
  );
};

export default Stations;
