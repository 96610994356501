import { useState } from "react";

import Layout from "../../components/layout";
import BatteriesList from "./BatteriesList";

import styles from "./batteries.module.css";

import EditBattery from "./EditBattery";

const Batteries = () => {
  const [mode, setMode] = useState("list");

  return (
    <Layout>
      <section className={styles.container}>
        {mode === "list" && <BatteriesList setMode={setMode} />}
        {mode === "edit" && <EditBattery setMode={setMode} edit />}
        {mode === "add" && <EditBattery setMode={setMode} />}
      </section>
    </Layout>
  );
};

export default Batteries;
