import { useDispatch } from "react-redux";
import { useLocalize } from "../../components/localize";
import styles from "../users/users.module.css";
import Toast from "../../components/toast";
import { useState } from "react";
import { approveDriverLicence, rejectDriverLicence } from "../../utils/driversLicence";
import { fetchDriversLicenceList } from "../../store/reducers/driversLicence";
import { serverPath } from "../../utils/devSettings";

const DriversLicenceListRow = ({ indice, driver }) => {
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const handleApprove = async () => {
    const response = await approveDriverLicence(driver);
    if (response.state === "success") {
      setMessage(t("driver_licence_approved"));
      dispatch(fetchDriversLicenceList());
    } else {
      setMessage(t("error"));
    }
    setShowMsg(true);
  };

  const handleReject = async () => {
    const response = await rejectDriverLicence(driver);
    if (response.state === "success") {
      setMessage(t("driver_licence_rejected"));
      dispatch(fetchDriversLicenceList());
    } else {
      setMessage(t("error"));
    }
    setShowMsg(true);
  };
  return (
    <tr key={indice}>
      <td>{indice + 1}</td>
      <td>{driver.customer_name}</td>
      <td>
        <img
          className={styles.licenceImage}
          src={`${serverPath}/${driver.driver_licence_image}`}
          alt="Driver's License"
        />
      </td>
      <td>
        <img
          className={styles.licenceImage}
          src={`${serverPath}/${driver.customer_image}`}
          alt="User Images"
        />
      </td>
      <td>
        <button
          onClick={handleApprove}
          className={styles.approveButton}
        >
          {t("approve")}
        </button>
        <button
          onClick={handleReject}
          className={styles.rejectButton}
        >
          {t("reject")}
        </button>
      </td>
      <Toast
        title="Message"
        message={message}
        show={showMsg}
        onClose={() => {
          setShowMsg(false);
        }}
      />
    </tr>
  );
};

export default DriversLicenceListRow;
