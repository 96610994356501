import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  updateList: false,
  bikesList: null,
};

export const bikesSlice = createSlice({
  name : "bikes",
  initialState,
  reducers : {
    fillBikesList : (state,action) => {
      state.updateList = false;
      state.bikesList = action.payload.bikesList;
    },
    fetchBikesList : (state,action) => {
      state.updateList = true;
    }
  }
})

export const {fillBikesList, fetchBikesList} = bikesSlice.actions;

export default bikesSlice.reducer;

// const bikesReducer = (state = initialState, action) => {
//   let payload = action.payload;
//   switch (action.type) {
//     case actionTypes.FILL_BIKES_LIST:
//       return {
//         ...state,
//         updateList: false,
//         bikesList: payload,
//       };
//     case actionTypes.FETCH_BIKES_LIST:
//       return {
//         ...state,
//         updateList: true,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };

// export default bikesReducer;
