import { serverPath } from "./devSettings";

const getDrivers = async (user) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getDrivers", options);
  const result = await response.json();

  return result;
};

const addDriver = async (driver) => {
  
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(driver),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addDriver", options);
  const result = await response.json();

  return result;
};

export { getDrivers, addDriver };
