import { serverPath } from "./devSettings";

const addStation = async (station) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(station),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addStation", options);
  const result = await response.json();

  return result;
};

export default addStation;
