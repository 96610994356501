import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLink,
  faPlugCircleCheck,
  faPlugCircleXmark,
  faLinkSlash,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./stations.module.css";
import { useDispatch } from "react-redux";

import { useLocalize } from "../../components/localize";
import { setSelectedStation } from "../../store/reducers/stationReducer";

const StationsListRow = ({ indice, station, setMode }) => {
  const t = useLocalize();
  const dispatch = useDispatch();

  const selectStation = () => {
    dispatch(setSelectedStation(station));
  };

  const checkIsConnected = () => {
    let now = Math.floor(Date.now() / 1000);
    let a = new Date(station.last_comm_date).getTime();
    a = Math.floor(a / 1000);
    if (now - a < 180) return true;
    return false;
  };

  return (
    <tr
      className={styles.clickable}
      onClick={() => {
        selectStation(station);
      }}
      style={{ color: "black", textAlign: "center" }}
      key={indice}
    >
      <td>{indice + 1}</td>
      <td>{station.name}</td>
      <td>{station.station_type}</td>
      <td>{station.serial_no}</td>
      <td>
        {checkIsConnected() ? (
          <FontAwesomeIcon icon={faLink} style={{ color: "green" }} />
        ) : (
          <FontAwesomeIcon icon={faLinkSlash} style={{ color: "red" }} />
        )}
      </td>
      <td
        onClick={(e) => {
          setMode("details");
          selectStation(station);
        }}
        className={styles.clickable}
      >
        <FontAwesomeIcon icon={faBars} />
      </td>
    </tr>
  );
};

export default StationsListRow;
