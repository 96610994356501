import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./groups.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocalize } from "../../components/localize";
import { getGroupMembers } from "../../utils/getGroups";
import { addGroup } from "../../utils/groups";
import { fetchGroupsList } from "../../store/reducers/groupsReducer";

const GroupElement = ({ group, selectedGroup, setSelectedGroup }) => {
  const setSelected = () => {
    setSelectedGroup(group);
  };

  let style = { backgroundColor: "lightGray" };

  if (selectedGroup.id === group.id) style = { backgroundColor: "orange" };

  return (
    <div
      className={styles.groupElement}
      style={style}
      onClick={() => setSelected()}
    >
      <div>{group.group_name}</div>
      <div className={styles.smallButton}> - Sil</div>
    </div>
  );
};

const UserElement = ({ user }) => {
  return (
    <div className={styles.userElement}>
      <div>
        {user.name} {user.surname}
      </div>{" "}
      <div
        className={styles.smallButton}
        onClick={() => addToGroup(user.user_id)}
      >
        {" "}
        + Ekle
      </div>
    </div>
  );
};

const MemberElement = ({ member }) => {
  return (
    <div className={styles.memberElement}>
      <div>
        {member.name} {member.surname}
      </div>{" "}
      <div
        className={styles.smallButton}
        onClick={() => removeFromGroup(member.user_id)}
      >
        {" "}
        - Çıkar
      </div>
    </div>
  );
};

const addToGroup = (id) => {};

const removeFromGroup = (id) => {};

const deleteGroup = (id) => {};

const AddNewGroup = () => {
  const dispatch = useDispatch();
  const [newGroupName, setNewGroupName] = useState("");

  const saveGroup = () => {
    addGroup({ groupName: newGroupName }).then(() =>
      dispatch(fetchGroupsList())
    );
  };

  return (
    <div className={styles.addNewGroup}>
      <input
        type="text"
        placeholder="Yeni Gurup Ekle"
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
      />
      <button type="button" onClick={() => saveGroup()}>
        Ekle
      </button>
    </div>
  );
};

const Groups = ({ setMode }) => {
  const t = useLocalize();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  //const [groupsList, setGroupsList] = useState(null);
  const groupsList = useSelector((state) => state.groups.groupsList);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [groupMembers, setGroupMembers] = useState(null);
  const allUsers = useSelector((state) => state.usersList.userList);

  const selectGroup = (group) => {
    setSelectedGroup(group);

    getGroupMembers(group.id).then((result) => {
      setGroupMembers(result.members);
    });
  };

  //if (!groupsList) return null;

  return (
    <div className={styles.groupsContainer}>
      <div className={styles.topBar}>
        <h3>{t("groupslist")}</h3>
        <button onClick={() => setMode("list")}>
          <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
        </button>
      </div>
      <div className={styles.groupsBody}>
        <div className={styles.leftPart}>
          <AddNewGroup />
          <p className={styles.columnHeader}>Grup Listesi</p>
          {groupsList.map((group) => (
            <GroupElement
              group={group}
              selectedGroup={selectedGroup}
              setSelectedGroup={(group) => selectGroup(group)}
            />
          ))}
        </div>
        <div className={styles.midPart}>
          <p className={styles.columnHeader}>Tüm Kullanıcılar</p>
          {allUsers && allUsers.map((usr) => <UserElement user={usr} />)}
        </div>
        <div className={styles.rightPart}>
          <p className={styles.columnHeader}>Grup Üyeleri</p>
          {groupMembers ? (
            groupMembers.map((member) => <MemberElement member={member} />)
          ) : (
            <span>Gurup Seçiniz...</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Groups;
