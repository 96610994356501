import React, { useState } from "react";
import styles from "./companies.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import CompaniesListRow from "./CompaniesListRow";
import { useSelector, useDispatch } from "react-redux";

import { Filter, hideByFilter } from "../../components/filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocalize } from "../../components/localize";

const CompaniesList = ({ setMode }) => {
  const [filterValue, setFilterValue] = useState("");
  const companies = useSelector((state) => state.companies.list);
  const t = useLocalize();

  if (!companies) return null;

  return (
    <div className={styles.companiesList}>
      <h2>{t("companies")}</h2>
      <div className={styles.topBar}>
        <Filter setFilter={setFilterValue} />
        <button onClick={() => setMode("add")}>
          <FontAwesomeIcon icon={faPlus} /> {t("add")}
        </button>
      </div>
      <table className={tableStyles.listTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>{t("company_name")}</th>
            <th>{t("date_added")}</th>
            <th>{t("latest_activity")}</th>
            <th>{t("driver_count")}</th>
            <th>{t("vehicle_count")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companies?.map((company, indice) => {
            if (hideByFilter([company.company_name.toString()], filterValue))
              return (
                <CompaniesListRow
                  company={company}
                  indice={indice}
                  setMode={setMode}
                  key={company.id}
                />
              );

            return null;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CompaniesList;
