import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./userinfo.module.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { userLogout } from "../../store/reducers/userReducer";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const Userinfo = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const logOut = () => {
    cookies.set("jwt", "", { path: "/" });
    dispatch(userLogout());
    navigate("/");
    return;
  };

  useEffect(() => {
    if (user.isLogin === false) navigate("/");
  }, [user.isLogin, navigate]);

  return (
    <>
      <div className={styles.userSection}>
        {/*<FontAwesomeIcon icon={faUser} /> */}
        <div className={styles.userBadge}>
          {user.name.substring(0, 1)}
          {user.surname.substring(0, 1)}
        </div>
        <FontAwesomeIcon
          icon={faRightFromBracket}
          className={styles.logoutIcon}
          onClick={logOut}
        />
      </div>
    </>
  );
};

export default Userinfo;
