import { useState, useRef, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import styles from "./fleet.module.css";
import VehicleMarker from "./vehicleMarker";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  useMap,
  useMapEvents,
  Circle,
  Polygon,
  Popup,
} from "react-leaflet";
import { getZones } from "../../utils/zones";
import { useDispatch, useSelector } from "react-redux";

const defaultMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [13, 0],
});

const FitBounds = ({ parentRef, focusVehicle, mapReady }) => {
  const map = useMap();
  const ebikes = useSelector((state) => state.bikes.bikesList);

  useEffect(() => {
    console.log("Focus Vehicle : ", focusVehicle);
    if (focusVehicle && mapReady) {
      ebikes.forEach((bike) => {
        if (bike?.bike_id === focusVehicle?.bike_id) {
          if (bike?.latitude && bike?.longitude) {
            map.setView([bike?.latitude, bike?.longitude], 18);
          }
        }
      });
    } else if (mapReady) {
      map.fitBounds(parentRef.current.getBounds());
    }
  }, [parentRef, map, focusVehicle, mapReady, ebikes]);

  return null;
};

const BikeMap = ({
  isCircleActive,
  getDrawControls,
  getStationCoords,
  vehicleList,
  focusVehicle,
  setZoneShape,
}) => {
  const [mapMode, setMapMode] = useState("tracking");
  const [edit, setEdit] = useState(false);
  const [mapReady, setMapReady] = useState(false);
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const [zone, setZone] = useState(null);
  const [zoneList, setZoneList] = useState([]);
  const featureRef = useRef(null);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fillBlueOptions = { fillColor: "blue" };

  const getZoneAndCenters = async () => {
    return null;
    let allZones = await getZones();
    if (allZones.list) {
      setZoneList(allZones.list);
    }
  };

  useEffect(() => {
    getZoneAndCenters();
  }, [isCircleActive]);

  const LocationFinderDummy = () => {
    const map = useMapEvents({
      click(e) {
        getStationCoords(e.latlng);
        setSelectedLatLng(e.latlng);
        if (isCircleActive) setZoneShape(null, e.latlng);
      },
    });
    return null;
  };

  const clearMap = (e) => {
    if (featureRef) {
      const drawnItems = featureRef.current._layers;
      Object.keys(drawnItems).forEach((layerid, index) => {
        const layer = drawnItems[layerid];
        featureRef.current.removeLayer(layer);
      });
    }
  };

  const _onEdited = () => {};

  const _onDeleted = () => {};

  const _onCreate = (e) => {
    let shape = e.layer.toGeoJSON();

    if (e.layerType === "polygon") {
      setZoneShape(shape, null);
    }
  };

  const _onMounted = (drawControls) => {
    getDrawControls(drawControls);
  };

  return (
    <div className={styles.bikeMapContainer}>
      <MapContainer
        center={[41.006149, 29.001321]}
        zoom={17}
        scrollWheelZoom={true}
        zoomControl={true}
        maxZoom={18}
        minZoom={4}
        whenReady={() => setMapReady(true)}
      >
        <LocationFinderDummy></LocationFinderDummy>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FeatureGroup ref={featureRef}>
          {mapMode === "tracking" && vehicleList && (
            <>
              {vehicleList?.map((vehicle) => {
                return (
                  <VehicleMarker vehicle={vehicle} key={vehicle.bike_id} />
                );
              })}

              <FitBounds
                parentRef={featureRef}
                focusVehicle={focusVehicle}
                mapReady={mapReady}
              />
            </>
          )}
          {selectedLatLng && isCircleActive ? (
            <Circle
              center={selectedLatLng}
              pathOptions={fillBlueOptions}
              radius={20}
            />
          ) : null}

          {zoneList?.map((zone, index) => {
            if (zone.type === "2") {
              if (zone.center_lat && zone.center_lon) {
                return (
                  <Circle
                    center={[zone.center_lat, zone.center_lon]}
                    radius={20}
                    polygonId={zone.id}
                  >
                    <Popup>{zone?.alias}</Popup>
                  </Circle>
                );
              }
            } else {
              let shape = JSON.parse(zone.shape);
              return (
                <Polygon
                  positions={shape.coordinates.map((element) => {
                    return element.map((coord) => {
                      let point = [...coord];
                      return point.reverse();
                    });
                  })}
                  polygonId={zone.id}
                >
                  <Popup>{zone?.alias}</Popup>
                </Polygon>
              );
            }
          })}

          {/*mapMode === "history" && (
            <>
              <HistoryPlot
                history={history}
                onPlotComplete={() => {
                  setPlotReady(true);
                }}
              />
              {history && history?.length !== 0 && (
                <>
                  <Marker
                    position={[history[0].lat, history[0].lon]}
                    size={[32, 32]}
                    icon={greenFlag}
                    rotationAngle={15}
                  />
                  <Marker
                    position={[
                      history[history.length - 1].lat,
                      history[history.length - 1].lon,
                    ]}
                    size={[32, 32]}
                    icon={redFlag}
                    rotationAngle={-15}
                  />
                  <FitBounds
                    parentRef={featureRef}
                    focusVehicle={focusVehicle}
                    mapReady={mapReady}
                  />
                </>
              )}
            </>
                  )*/}
        </FeatureGroup>
        {/*          
        <Marker position={[51.505, -0.09]} icon={defaultMarker}>
          <Popup>
            Bike Name <br /> Bike Plate etc.
          </Popup>
                </Marker> */}
      </MapContainer>
    </div>
  );
};

export default BikeMap;
