import { useState } from "react";
import Layout from "../../components/layout";
import CompaniesList from "./CompaniesList";
import styles from "./companies.module.css";
import CompanyOperations from "./CompanyOperations";

const Companies = () => {
  const [mode, setMode] = useState("list");

  return (
    <Layout>
      <section className={styles.container}>
        {mode === "list" && <CompaniesList setMode={setMode} />}
        {mode === "edit" && <CompanyOperations setMode={setMode} edit />}
        {mode === "add" && <CompanyOperations setMode={setMode} />}
      </section>
    </Layout>
  );
};

export default Companies;
