import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import styles from "./profile.module.css";
import { useSelector } from "react-redux";
import { resetPassword } from "../../utils/resetPassword";
import editUser from "../../utils/editUser";

const Profile = () => {
  const [pageState, setPageState] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [alert, setAlert] = useState("");
  const [isPasswordProper, setIsPasswordProper] = useState(false);
  const [editedUser, setEditedUser] = useState({});

  const user = useSelector((state) => state.user);

  useEffect(() => {
    setEditedUser(user);
  }, []);

  useEffect(() => {}, [editedUser]);

  const saveEditedUser = () => {
    editUser(editedUser);
  };

  const handlePageState = (state) => {
    setPageState(state);
  };

  const handleEditedUser = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleNewPassword = (newPassword) => {
    setNewPassword(newPassword);
  };
  const handleNewPasswordAgain = (newPasswordAgain) => {
    setNewPasswordAgain(newPasswordAgain);
  };

  useEffect(() => {
    if (newPasswordAgain !== newPassword) {
      setAlert("Şifreler uyuşmuyor!");
      setIsPasswordProper(false);
    } else if (newPassword.length < 8) {
      setAlert("Şifre 8 karakter veya daha uzun olmalı!");
      setIsPasswordProper(false);
    } else {
      setAlert(" ");
      setIsPasswordProper(true);
    }
  }, [newPasswordAgain, newPassword]);

  const saveNewPassword = () => {
    if (isPasswordProper) {
      if (user.userid) {
        resetPassword({ user_id: user.userid, password: newPassword });
      }
    } else {
      alert("Şifre geçersiz.");
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <label>Ad</label>
          <input
            onChange={(e) => handleEditedUser(e)}
            type="text"
            name="name"
            value={editedUser?.name}
          ></input>
        </div>
        <div className={styles.inputContainer}>
          <label>Soyad</label>
          <input
            onChange={(e) => handleEditedUser(e)}
            type="text"
            name="surname"
            value={editedUser?.surname}
          ></input>
        </div>
        <div style={{ marginBottom: "20px" }} className={styles.inputContainer}>
          <label>TCKN</label>
          <input
            onChange={(e) => handleEditedUser(e)}
            type="text"
            name="tckn"
            value={editedUser?.tckn}
          ></input>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <button onClick={() => saveEditedUser(editedUser)}>Kaydet</button>
        </div>
        <div>
          <button onClick={() => handlePageState("newpassword")}>
            Şifre Değiştir
          </button>
        </div>

        {pageState === "newpassword" ? (
          <div className={styles.newPasswordContainer}>
            <div className={styles.inputContainer}>
              <label>Yeni Şifre</label>
              <input
                onChange={(e) => handleNewPassword(e.target.value)}
                type="password"
              ></input>
            </div>
            <div className={styles.inputContainer}>
              <label>Yeni Şifre Tekrar</label>
              <input
                onChange={(e) => handleNewPasswordAgain(e.target.value)}
                type="password"
              ></input>
            </div>

            <div className={styles.saveNewPassword}>
              <button
                onClick={() => {
                  saveNewPassword();
                }}
              >
                Kaydet
              </button>
            </div>
            <div>
              <p>{alert}</p>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default Profile;
