import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-test-renderer";

const initialState = {
  selectedPage: "home",
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.selectedPage = action.payload.selectedPage;
    },
  },
});

export const { setPage } = pagesSlice.actions;

export default pagesSlice.reducer;

// const pagesReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actionTypes.SET_PAGE:
//       const payload = action.payload;
//       return {
//         ...state,
//         selectedPage: payload,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };

// export default pagesReducer;
