import styles from "./confirmDialog.module.css";

const ConfirmDialog = ({ title, message, show, onClose, onConfirm }) => {
  if (show === false) return null;

  return (
    <div className={styles.backdrop}>
      <div className={styles.toast}>
        <p className={styles.title}>{title}</p>
        <p>{message}</p>
        <div className={styles.buttonBox}>
          <button type="button" onClick={onConfirm}>
            OK
          </button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
