import { serverPath } from "./devSettings";

const addCompany = async ( company ) => {
  const reqData = {
    company_type: company.company_type,
    company_name: company.company_name,
    driver_count : company.driver_count,
    vehicle_count : company.vehicle_count
  };
  
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: 'include',
  };

  const response = await fetch(serverPath + "/addCompany", options);
  const result = await response.json();

  return result;
};

export default addCompany;
