import { useState } from "react";
import Layout from "../../components/layout";
import styles from "./reports.module.css";
import ReportsList from "./ReportsList";

const Reports = () => {
  const [mode, setMode] = useState("list");

  return (
    <Layout>
      <section className={styles.container}>
        {mode === "list" && <ReportsList setMode={setMode} />}
      </section>
    </Layout>
  );
};

export default Reports;
