import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  updateList: false,
  batteryList : []
};

export const batteriesSlice = createSlice({
  name : "batteries",
  initialState,
  reducers : {
    fillBatteriesList : (state, action) => {
      state.updateList = false;
      state.batteryList = action.payload.batteryList;
    },
    fetchBatteriesList : (state, action) => {
      state.updateList = true;
    }
  }
})

export const {fillBatteriesList, fetchBatteriesList} = batteriesSlice.actions;

export default batteriesSlice.reducer;

// const batteriesReducer = (state = initialState, action) => {
//   let payload = action.payload;
//   switch (action.type) {
//     case actionTypes.FILL_BATTERIES_LIST:
//       return {
//         ...state,
//         updateList: false,
//         batteryList: payload,
//       };
//     case actionTypes.FETCH_BATTERIES_LIST:
//       return {
//         ...state,
//         updateList: true,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };

// export default batteriesReducer;
