const dateFormatter = (actualDate) => {
  if (actualDate === null || actualDate === undefined) {
    return "------";
  } else {
    var inputDate = new Date(actualDate);

    var year = inputDate.getUTCFullYear();
    var month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0");
    var day = inputDate.getUTCDate().toString().padStart(2, "0");
    var hours = inputDate.getUTCHours().toString().padStart(2, "0");
    var minutes = inputDate.getUTCMinutes().toString().padStart(2, "0");
    var seconds = inputDate.getUTCSeconds().toString().padStart(2, "0");
    var milliseconds = inputDate
      .getUTCMilliseconds()
      .toString()
      .padStart(3, "0");
    var formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return formattedDate;
  }
};
export { dateFormatter };
