import { serverPath } from "./devSettings";

const addUser = async (user) => {
  const reqData = {
    username: user.username,
    name: user.name,
    surname: user.surname,
    email: user.email,
    password: user.password,
    level: user.level,
    group: user.group,
    gsm_nr: user.gsm_nr,
    tckn: user.tckn,
    company_id: user.company_id,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addUser", options);
  const result = await response.json();

  return result;
};

export default addUser;
