import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./rentabike.module.css";
import { defaultMarker, bikeMarker } from "./bikeMarker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQrcode,
  faPlug,
  faMagnifyingGlass,
  faLock,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

const BikeLeave = ({ setMode }) => {
  return (
    <>
      <h1 className={styles.pageTitle}>
        <FontAwesomeIcon icon={faPlug} /> Plug & Lock
      </h1>
      <h1 className={styles.pageTitle}>
        <FontAwesomeIcon icon={faArrowRightFromBracket} /> Find the nearest
        station to plug and leave the bike
      </h1>
      <div className={styles.mapSection}>
        <MapContainer
          center={[40.961604, 29.0708]}
          zoom={17}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[40.961604, 29.0708]} icon={defaultMarker}>
            <Popup>
              EScooter
              <br /> Charge 80%
            </Popup>
          </Marker>
        </MapContainer>

        <section className={styles.mapButtons}>
          <button onClick={() => setMode("map")}>
            <FontAwesomeIcon icon={faLock} /> I Locked the Bike!
          </button>
        </section>
      </div>
    </>
  );
};

export default BikeLeave;
