import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateList: false,
  list: null,
  isLoading: false,
};

export const driverListSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    fillDriverList: (state, action) => {
      state.updateList = false;
      state.isLoading = false;
      state.list = action.payload;
    },
    fetchDriverList: (state, action) => {
      state.updateList = true;
      state.isLoading = true;
    },
  },
});

export const { fillDriverList, fetchDriverList } = driverListSlice.actions;

export default driverListSlice.reducer;
