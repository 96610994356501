import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import styles from "./prices.module.css";
import { editPricing, getPrices } from "../../utils/prices";
import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";
import { useLocalize } from "../../components/localize";

const Prices = () => {
  const [tempPrices, setTempPrices] = useState({});

  const [message, setMessage] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);

  const t = useLocalize();

  useEffect(() => {
    getPrices().then((prices) => {
      setTempPrices((curr) => {
        return {
          ...curr,
          swap: prices?.prices[0]?.body?.swap?.price,
          charge_rent: prices?.prices[0]?.body?.charge_rent?.price,
          rent_hourly: prices?.prices[0]?.body?.rent_hourly?.price,
          rent_daily: prices?.prices[0]?.body?.rent_daily?.price,
          rent_weekly: prices?.prices[0]?.body?.rent_weekly?.price,
          rent_monthly: prices?.prices[0]?.body?.rent_monthly?.price,
          rent_penalty: prices?.prices[0]?.body?.rent_penalty?.price,
        };
      });
    });

    return () => {};
  }, []);

  const isValid = (value) => {
    console.log(value, parseFloat(value), value >= 0);
    if (parseFloat(value) && value >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const updatePrices = async () => {
    const res = await editPricing({
      body: {
        swap: { price: parseFloat(tempPrices?.swap) },
        charge_rent: { price: parseFloat(tempPrices?.charge_rent) },
        rent_hourly: { price: parseFloat(tempPrices?.rent_hourly) },
        rent_daily: { price: parseFloat(tempPrices?.rent_daily) },
        rent_weekly: { price: parseFloat(tempPrices?.rent_weekly) },
        rent_monthly: { price: parseFloat(tempPrices?.rent_monthly) },
        rent_penalty: { price: parseFloat(tempPrices?.rent_penalty) },
      },
    });

    if (res?.state === "success") {
      setShowMsg(true);
      setMessage(t("update_price_success"));
    } else {
      setShowMsg(true);
      setMessage(t("update_price_error"));
    }
  };

  const editPrices = async () => {
    if (
      isValid(tempPrices?.swap) &&
      isValid(tempPrices?.charge_rent) &&
      isValid(tempPrices?.rent_hourly) &&
      isValid(tempPrices?.rent_daily) &&
      isValid(tempPrices?.rent_weekly) &&
      isValid(tempPrices?.rent_monthly) &&
      isValid(tempPrices?.rent_penalty)
    ) {
      setConfirmModal(true);
    } else {
      setShowMsg(true);
      setMessage(t("check_prices_warning"));
    }
  };

  //TODO: translate this page.
  return (
    <>
      <Layout>
        <section className={styles.container}>
          <div className={styles.inputContainer}>
            <label>{t("swap")}</label>
            <input
              onChange={(e) => {
                setTempPrices((curr) => {
                  return {
                    ...curr,
                    swap: e.target.value,
                  };
                });
              }}
              type="number"
              value={tempPrices.swap}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>{t("charge_rent")}</label>
            <input
              onChange={(e) =>
                setTempPrices((curr) => {
                  return {
                    ...curr,
                    charge_rent: e.target.value,
                  };
                })
              }
              type="number"
              value={tempPrices.charge_rent}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>{t("rent_hourly")}</label>
            <input
              onChange={(e) =>
                setTempPrices((curr) => {
                  return {
                    ...curr,
                    rent_hourly: e.target.value,
                  };
                })
              }
              type="number"
              value={tempPrices.rent_hourly}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>{t("rent_daily")}</label>
            <input
              onChange={(e) =>
                setTempPrices((curr) => {
                  return {
                    ...curr,
                    rent_daily: e.target.value,
                  };
                })
              }
              type="number"
              value={tempPrices.rent_daily}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>{t("rent_weekly")}</label>
            <input
              onChange={(e) =>
                setTempPrices((curr) => {
                  return {
                    ...curr,
                    rent_weekly: e.target.value,
                  };
                })
              }
              type="number"
              value={tempPrices.rent_weekly}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>{t("rent_monthly")}</label>
            <input
              onChange={(e) =>
                setTempPrices((curr) => {
                  return {
                    ...curr,
                    rent_monthly: e.target.value,
                  };
                })
              }
              value={tempPrices.rent_monthly}
              type="number"
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>{t("rent_penalty")}</label>
            <input
              onChange={(e) =>
                setTempPrices((curr) => {
                  return {
                    ...curr,
                    rent_penalty: e.target.value,
                  };
                })
              }
              value={tempPrices.rent_penalty}
              type="number"
            ></input>
          </div>
          <div className={styles.savePrices}>
            <button
              onClick={() => {
                editPrices();
              }}
            >
              Kaydet
            </button>
          </div>
          <div>
            <p>{alert}</p>
          </div>
        </section>
      </Layout>
      <Toast
        title="Message"
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
      {confirmModal && (
        <ConfirmDialog
          title={t("confirm_prices")}
          message={t("price_update_confirm_message")}
          onConfirm={() => {
            setConfirmModal(false);
            updatePrices();
          }}
          onClose={() => setConfirmModal(false)}
        />
      )}
    </>
  );
};

export default Prices;
