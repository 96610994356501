import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/layout";
import styles from "./fleet.module.css";
import { Filter, hideByFilter } from "../../components/filter";
import { useLocalize } from "../../components/localize";
import ListRow from "./ListRow";
import BikeMap from "./BikeMap";
import tableStyles from "../commonStyles/listTable.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChargingStation,
  faExpand,
  faLocationCrosshairs,
  faSave,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { addZone } from "../../utils/zones";
import bikeControl from "../../utils/bikeControl";
import Toast from "../../components/toast";

export const isOnline = (lastComm) => {
  if (lastComm) {
    if (parseInt((Date.now() - Date.parse(lastComm)) / 1000) < 300) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const Fleet = () => {
  const [filterValue, setFilterValue] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const t = useLocalize();
  const ebikes = useSelector((state) => state.bikes.bikesList);
  const [focusVehicle, setFocusVehicle] = useState(null);
  const [drawControls, setDrawControls] = useState(null);
  const [isCircleActive, setIsCircleActive] = useState(false);
  const [circleLocation, setCircleLocation] = useState(null);
  const [isZoneInputOpen, setIsZoneInputOpen] = useState(false);
  const [isCenterInputOpen, setIsCenterInputOpen] = useState(false);
  const [zone, setZone] = useState({});
  const [commanPending, setCommandPending] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");

  const driverList = useSelector((state) => state.drivers.list);

  const getDrawControls = (drawControls) => {
    setDrawControls(drawControls);
  };

  const getStationCoords = (location) => {
    setCircleLocation(location);
  };

  const addNewCenter = () => {
    setIsCircleActive(!isCircleActive);
    setIsCenterInputOpen(!isCenterInputOpen);
    setIsZoneInputOpen(false);
    drawControls._toolbars.draw._modes.polygon.handler.disable();
  };

  const saveNewCenter = async () => {
    if (!zone.name) {
      alert("Lütfen istasyon ismi girin!");
    } else if (!zone.center) {
      alert("Lütfen istasyonun konumunu harita üzerinde seçiniz!");
    } else {
      await addZone({ ...zone, company_id: user.companyId });
      setZone({});
      setIsCircleActive(false);
      setIsCenterInputOpen(false);
      setIsZoneInputOpen(false);
    }
  };

  const addNewZone = () => {
    setIsCircleActive(false);
    setIsCenterInputOpen(false);
    setIsZoneInputOpen(true);
    drawControls._toolbars.draw._modes.polygon.handler.enable();
  };

  const saveNewZone = async () => {
    if (!zone.name) {
      alert("Lütfen bölge ismi girin!");
    } else if (!zone.shape) {
      alert("Lütfen bölge sınırlarını harita üzerinde çiziniz!");
    } else {
      await addZone({ ...zone, company_id: user.companyId });
      setIsCenterInputOpen(false);
      setIsZoneInputOpen(false);
      setZone({});
    }
  };

  const setZoneShape = (shape, center) => {
    setZone({ ...zone, shape: shape, center: center });
  };

  const handleStationName = (e) => {
    setZone({ ...zone, name: e.target.value });
  };

  const handleZoneName = (e) => {
    setZone({ ...zone, name: e.target.value });
  };

  const detachVehicle = async (e) => {
    bikeControl({
      bikeId: focusVehicle.bike_id,
      command: "detachFromDriver",
    }).then((data) => {
      console.log("DETACH DATA : ", data);
      if (data?.state === "success") {
        setMsg("Araç serbest bırakıldı.");
        setShowMsg(true);
        setFocusVehicle(null);
      } else {
        setMsg("Bir sorun oluştu.");
        setShowMsg(false);
      }
    });
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.listBox}>
          <button
            type="button"
            onClick={() => setFocusVehicle(null)}
            className={styles.showAllButton}
          >
            <FontAwesomeIcon icon={faExpand} /> {t("showall")}
          </button>
          <table className={tableStyles.listTable}>
            <thead>
              <tr>
                <td></td>
                <th>{t("plate")}</th>
                <th>{t("speed")}</th>
              </tr>
            </thead>
            <tbody>
              {ebikes?.map((bike, indice) => {
                if (hideByFilter([bike.licence_plate], filterValue))
                  return (
                    <ListRow
                      bike={bike}
                      focus={focusVehicle}
                      setFocus={(bike) => setFocusVehicle(bike)}
                    />
                  );
                return null;
              })}
            </tbody>
          </table>
        </div>

        <BikeMap
          setZoneShape={setZoneShape}
          isCircleActive={isCircleActive}
          getDrawControls={getDrawControls}
          getStationCoords={getStationCoords}
          vehicleList={ebikes}
          focusVehicle={focusVehicle}
        />
        <div className={styles.listBoxRight}>
          {focusVehicle && (
            <div className={styles.bikeControlButtons}>
              {focusVehicle.actual_lock_status && (
                <button
                  type="button"
                  onClick={() =>
                    bikeControl({
                      bikeId: focusVehicle.bike_id,
                      command: "unlock",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faKey} /> Kontak Aç
                </button>
              )}
              {!focusVehicle.actual_lock_status && (
                <button
                  type="button"
                  onClick={() =>
                    bikeControl({
                      bikeId: focusVehicle.bike_id,
                      command: "lock",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faKey} /> Kontak Kapat
                </button>
              )}
              <button
                type="button"
                onClick={() =>
                  bikeControl({
                    bikeId: focusVehicle.bike_id,
                    command: "boxOpen",
                  })
                }
              >
                Kutu Aç
              </button>

              <button
                type="button"
                disabled={!focusVehicle?.assigned_courier}
                onClick={() => {
                  detachVehicle();
                }}
              >
                Sürücüden Ayır
              </button>
            </div>
          )}

          {user.priviliges.canAddStation && (
            <div className={`${styles.zoneContainer}`}>
              <button
                type="button"
                onClick={() => addNewCenter()}
                className={styles.addNewStationButton}
              >
                <FontAwesomeIcon icon={faChargingStation} />{" "}
                {t("add_new_station")}
              </button>
              {/* {isCenterInputOpen ? ( */}
              <div
                className={`${styles.inputContainer} ${
                  isCenterInputOpen ? styles.display : styles.noDisplay
                }`}
              >
                <input
                  type="text"
                  placeholder="İstasyon Adı"
                  onChange={handleStationName}
                  className={`${styles.inputBox}`}
                ></input>
                <button
                  type="button"
                  onClick={() => saveNewCenter()}
                  className={styles.addNewStationButton}
                >
                  <FontAwesomeIcon icon={faSave} /> {t("save")}
                </button>
              </div>
            </div>
          )}
          <hr></hr>
          <br></br>
          {user.priviliges.canAddZone && (
            <div className={styles.zoneContainer}>
              <button
                type="button"
                onClick={() => addNewZone()}
                className={styles.addNewStationButton}
              >
                <FontAwesomeIcon icon={faLocationCrosshairs} />{" "}
                {t("add_new_area")}
              </button>
              {isZoneInputOpen ? (
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    placeholder="Bölge Adı"
                    onChange={handleZoneName}
                    className={styles.inputBox}
                  ></input>
                  <button
                    type="button"
                    onClick={() => saveNewZone()}
                    className={styles.addNewStationButton}
                  >
                    <FontAwesomeIcon icon={faSave} /> {t("save")}
                  </button>
                </div>
              ) : null}
            </div>
          )}

          {focusVehicle && (
            <section>
              Araç Plaka : {focusVehicle?.licence_plate}
              <br />
              Odometre : {focusVehicle?.odometer} Km
              <br />
              Sürücü :
              {focusVehicle?.assigned_courier
                ? driverList.map((driver) => {
                    if (driver?.id === focusVehicle?.assigned_courier) {
                      return driver.name;
                    }
                  })
                : "Yok"}
              <br />
              Kilitli : {focusVehicle?.actual_lock_status ? "Evet" : "Hayır"}
              <br />
            </section>
          )}
          <Toast
            title="Message"
            message={msg}
            show={showMsg}
            onClose={() => setShowMsg(false)}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Fleet;
