import { Popup, Marker, Tooltip } from "react-leaflet";
import {
  defaultMarker,
  lockedVehicleMarker,
  offlineVehicleMarker,
  onlineVehicleMarker,
} from "./markers";
import styles from "./fleet.module.css";
import { isOnline } from ".";
import { dateFormat } from "../../utils/dateFormat";

function getHeading(heading) {
  if (heading > 22 && heading <= 67) return "K.Doğu";
  else if (heading > 67 && heading <= 112) return "Doğu";
  else if (heading > 112 && heading <= 157) return "G.Doğu";
  else if (heading > 157 && heading <= 202) return "Güney";
  else if (heading > 202 && heading <= 248) return "G.Batı";
  else if (heading > 248 && heading <= 292) return "Batı";
  else if (heading > 292 && heading <= 340) return "K.Batı";
  else if (heading > 337 || heading <= 22) return "Kuzey";
}

const PopupContent = ({ vehicle }) => {
  return (
    <table className={styles.popupTable}>
      <tr>
        <td>Ad</td>
        <td>{vehicle.licence_plate}</td>
      </tr>
      <tr>
        <td>Sürücü</td>
        <td>{vehicle.driver}</td>
      </tr>
      <tr>
        <td>Hız</td>
        <td>{vehicle.actual_speed} Km/h</td>
      </tr>
      <tr>
        <td>Yön</td>
        <td>
          {vehicle.heading} derece, {getHeading(vehicle.heading)}{" "}
        </td>
      </tr>
      <tr>
        <td>Zaman</td>
        <td>{dateFormat(vehicle.last_comm_timestamp)}</td>
      </tr>
      <tr>
        <td>Koordinat</td>
        <td>
          {vehicle.latitude.toFixed(4)}, {vehicle.longitude.toFixed(4)}
        </td>
      </tr>
      <tr>
        <td>Kontak</td>
        <td>{vehicle.actual_lock_status ? "Kapalı" : "Açık"}</td>
      </tr>
      <tr>
        <td>Batarya</td>
        <td>{(vehicle.voltage / 1000).toFixed(2)} Volt</td>
      </tr>
      <tr>
        <td>Durum</td>
        {/*<td>{vehicle.status === "mobile" ? "Hareketi" : "Duruyor"}</td>*/}
        <td>{vehicle.speed > 3 ? "Hareketi" : "Duruyor"}</td>
      </tr>
      <tr>
        <td>Odometre</td>
        <td>
          {vehicle.odometer === null
            ? "---"
            : Math.round(vehicle.odometer / 1000)}{" "}
          Km
        </td>
      </tr>
      <tr>
        <td>Sürücü</td>
        <td>
          {vehicle?.assigned_courier_name === null
            ? "-"
            : vehicle?.assigned_courier_name}
        </td>
      </tr>
    </table>
  );
};

const VehicleMarker = ({ vehicle }) => {
  const checkIsVehicleLocked = (vehicle) => {
    if (vehicle.is_locked) {
      return true;
    } else {
      return false;
    }
  };

  if (!vehicle.latitude || !vehicle.longitude) return null;

  return (
    <Marker
      position={[vehicle.latitude, vehicle.longitude]}
      icon={
        !isOnline(vehicle.last_location_time)
          ? offlineVehicleMarker
          : vehicle.actual_lock_status
          ? lockedVehicleMarker
          : onlineVehicleMarker
      }
      rotationOrigin="center"
      rotationAngle={parseInt(vehicle.heading)}
    >
      <Tooltip direction="right" offset={[10, 5]} opacity={1} permanent>
        {vehicle.licence_plate}
      </Tooltip>
      <Popup>
        <PopupContent vehicle={vehicle} />
      </Popup>
    </Marker>
  );
};

export default VehicleMarker;
