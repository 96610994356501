import styles from "./toast.module.css";

const Toast = ({ title, message, show, onClose }) => {
  if (show === false) return null;

  return (
    <div className={styles.backdrop}>
      <div className={styles.toast}>
        <p className={styles.title}>{title}</p>
        <p>{message}</p>
        <button type="button" onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
};

export default Toast;
