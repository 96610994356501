import { serverPath } from "./devSettings";

const addGroup = async (groupData) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(groupData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addGroup", options);
  const result = await response.json();

  return result;
};

export { addGroup };
