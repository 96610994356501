import { serverPath } from "./devSettings";

const editBattery = async (battery) => {
  const reqData = {
    battery_id: battery.battery_id,
    battery_capacity: battery.battery_capacity,
    battery_serial_no: battery.battery_serial_no,
    battery_type: battery.battery_type,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/editBattery", options);
  const result = await response.json();

  return result;
};

export default editBattery;
