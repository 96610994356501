import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./rentabike.module.css";
import { defaultMarker, bikeMarker } from "./bikeMarker";
import { mapDrawStyle } from "./mapDrawStyle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQrcode,
  faArrowLeft,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

const FindBikeMap = ({ modeChange, onCancel }) => {
  return (
    <>
      <h1 className={styles.pageTitle}>
        <FontAwesomeIcon icon={faMagnifyingGlass} /> Find a Bike
      </h1>
      <div className={styles.mapSection}>
        <MapContainer
          center={[40.961604, 29.0708]}
          zoom={17}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[40.961604, 29.0708]} icon={bikeMarker}>
            <Popup>
              EScooter
              <br /> Charge 80%
            </Popup>
          </Marker>

          <Marker position={[40.960432, 29.080391]} icon={bikeMarker}>
            <Popup>
              EScooter
              <br /> Charge 80%
            </Popup>
          </Marker>
        </MapContainer>

        <section className={styles.mapButtons}>
          <button onClick={onCancel}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
          <button onClick={() => modeChange("qr")}>
            <FontAwesomeIcon icon={faQrcode} /> Scan QR Code
          </button>
        </section>
      </div>
    </>
  );
};

export default FindBikeMap;
