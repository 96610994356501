import { createSlice } from "@reduxjs/toolkit";


const initialState = {};

const selectedCompanySlice = createSlice({
  name : "selectedCompany",
  initialState,
  reducers: {
    setSelectedCompany : (state, action) => {
      state.selectedCompany = action.payload.selectedCompany;
    }
  }
})

export const {setSelectedCompany} = selectedCompanySlice.actions;
export default selectedCompanySlice.reducer;

// const selectedCompanyReducer = (state = initialState, action) => {
//   const payload = action.payload;
//   switch (action.type) {
//     case actionTypes.SET_SELECTED_COMPANY:
//       return {
//         ...state,
//         ...payload.selectedCompany,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };

// export default selectedCompanyReducer;
