import styles from "./ExportBar.module.css";
import { FaFileExcel } from "react-icons/fa";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useLocalize } from "../../components/localize";

export const ExportBar = ({
  tableRef,
  reportFileName,
  reportTitle,
  isDisabled,
}) => {
  const t = useLocalize();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: reportFileName,
    sheet: reportTitle,
  });

  return (
    <div className={styles.exportBar}>
      <button
        disabled={isDisabled}
        className={styles.button}
        onClick={onDownload}
      >
        Excel
        <div>
          <FaFileExcel color="green" />
        </div>
      </button>
    </div>
  );
};

export default ExportBar;
