import { serverPath } from "./devSettings";

const bikeControl = async (bike) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bike),
    credentials: "include",
  };

  console.log(options);

  const response = await fetch(serverPath + "/bikeControl", options);
  const result = await response.json();

  return result;
};

export default bikeControl;
