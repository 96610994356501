/*eslint-disable*/
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import { useDispatch } from "react-redux";
import { serverPath } from "../../utils/devSettings";

import { useLocalize } from "../../components/localize";
import { userLogin } from "../../store/reducers/userReducer";
import { userPriviliges } from "../users/userPriviliges";
import jwt_decode from "jwt-decode";
import { setPage } from "../../store/reducers/pagesReducer";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const LoginPage = () => {
  const t = useLocalize();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginMsg, setLoginMsg] = useState("");

  const [bikeClass, setBikeClass] = useState(styles.bike);
  useEffect(() => {
    dispatch(setPage({ selectedPage: "home" }));
  }, []);
  const sendToPassword = () => {
    navigate("/passwordrecover");
  };
  const loginRequest = async (e) => {
    e.preventDefault();

    setBikeClass(bikeClass + " " + styles.animation);

    if (username === "" || password === "") {
      setLoginMsg(t("username_or_password_not_set"));
      return;
    }

    const loginData = {
      username: username,
      password: password,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(loginData),
    };

    const response = await fetch(serverPath + "/login", options);
    const result = await response.json();

    if (response.status === 200) {
      cookies.set("jwt", result?.token, { path: "/" });
      const decodedToken = jwt_decode(result.token);
      console.log(
        "PRIVILEGES : ",
        decodedToken.level,
        userPriviliges[decodedToken.level]
      );
      console.log("TOKEN : ", result.token);

      dispatch(
        userLogin({
          userid: decodedToken.user_id,
          username: decodedToken.username,
          name: decodedToken.name,
          surname: decodedToken.surname,
          token: result.token,
          level: decodedToken.level,
          role: decodedToken.level,
          email: decodedToken.email,
          companyid: decodedToken.company_id,
          priviliges: userPriviliges[decodedToken.level],
          tckn: decodedToken.tckn,
        })
      );
      navigate("/home");
    } else {
      setLoginMsg(result.message);
      setBikeClass(bikeClass);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.login}>
        <h1 className={styles.title}>
          <img src="/images/greengo_logo.png" alt="greengo_logo" />
        </h1>
        <div style={{ display: "flex" }}>
          <img src="/images/a8.png" alt="scooter image" />

          <div>
            <h2>{t("sign_in")}</h2>

            {loginMsg !== "" ? (
              <div className={styles.loginMsg}>{loginMsg}</div>
            ) : null}

            <section className={styles.formBox}>
              <input
                type="text"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? loginRequest(e) : null)}
              />
              <div className={styles.animationDiv}>
                <img
                  src="/images/kick-scooter.svg"
                  alt="anim"
                  className={bikeClass}
                />
              </div>
              <button type="submit" onClick={loginRequest}>
                {t("login")}
              </button>
            </section>

            <section className={styles.signUpBox}>
              <p>
                {t("no_account_or_login_problem")}{" "}
                <a href="#">{t("sign_up")}</a> {t("or")}{" "}
                <a href="" onClick={sendToPassword}>
                  {t("recover_password")}
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
