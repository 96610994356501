import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./stations.module.css";
import { useState } from "react";

const StationAddMap = ({ location }) => {
  const defMarker = new L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
  });

  const [center, setCenter] = useState(
    location?.latitude && location?.longitude
      ? [location?.latitude, location?.longitude]
      : [41.012702, 28.99884]
  );
  console.log("asdasdasd");

  return (
    <div className={styles.stationsMapContainer}>
      <MapContainer
        center={[41.012702, 28.99884]}
        zoom={13}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {location?.latitude && location?.longitude && (
          <Marker
            position={[location?.latitude, location?.longitude]}
            icon={defMarker}
          ></Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default StationAddMap;
