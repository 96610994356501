import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  updateList: false,
  list: [],
};

export const companiesSlice = createSlice({
  name : "companies",
  initialState,
  reducers : {
    fillCompanyList : (state,action) => {
      state.updateList = false;
      state.list = action.payload.list;
    },
    fetchCompanyList : (state, action) => {
      state.updateList = true;
    }
  }
})

export const {fillCompanyList, fetchCompanyList} = companiesSlice.actions;
export default companiesSlice.reducer;

// const companiesListReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actionTypes.FILL_COMPANY_LIST:
//       const payload = action.payload;
//       return {
//         ...state,
//         updateList: false,
//         list: payload,
//       };
//     case actionTypes.FETCH_COMPANY_LIST:
//       return {
//         ...state,
//         updateList: true,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };

// export default companiesListReducer;
