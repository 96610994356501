import React, { useState } from "react";
import styles from "./reports.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Filter, hideByFilter } from "../../components/filter";
import { useLocalize } from "../../components/localize";
import ReportsListRow from "./ReportsListRow";
import ReportSelector from "./ReportSelector";
import { getReports } from "../../utils/getReports";
import { fillReportList } from "../../store/reducers/reportsReducer";
import ExportBar from "../../components/exportBar/ExportBar";
import ExportBarPDF from "../../components/exportBar/ExportBarPDF";
import { useRef } from "react";
import Toast from "../../components/toast";

const ReportsList = ({ setMode }) => {
  const [filterValue, setFilterValue] = useState("");
  const [type, setType] = useState(null);
  const [message, setMessage] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const tableRef = useRef(null);

  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports.list);
  const t = useLocalize();

  const handleReportType = (e) => {
    setType(e);
  };

  const [isDisabled, setDisabled] = useState(true);

  const setReport = async (params) => {
    const { result, response } = await getReports(params);

    if (response.ok) {
      dispatch(fillReportList({ updateList: true, list: result.reports }));
      setDisabled(false);
      setShowMsg(false);
    } else if (response.status === 404) {
      dispatch(fillReportList({ updateList: true, list: [] }));
      setShowMsg(true);
      setMessage(t("no_results_for_given_date_range"));
    } else if (response.status === 405) {
      setMessage(fillReportList({ updateList: true, list: [] }));
      setShowMsg(true);

      setMessage(t("enter_the_report_type_start_date"));
    } else if (response.status === 500) {
      dispatch(fillReportList({ updateList: true, list: [] }));
      setShowMsg(true);

      setMessage(t("an_error_occured"));
    }
  };
  return (
    <div className={styles.reportsList}>
      <h2>{t("reports")}</h2>
      <ReportSelector
        handleReportType={handleReportType}
        setReport={setReport}
      />
      <div className={styles.topBar}>
        <Filter setFilter={setFilterValue} />
        <div className={styles.ExportBar}>
          <ExportBar
            tableRef={tableRef}
            reportFileName="rapor"
            reportTitle="Rapor"
            isDisabled={isDisabled}
          />
          <ExportBarPDF
            tableRef={tableRef}
            reportFileName="rapor"
            reportTitle="Rapor"
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <table id="reportTable" ref={tableRef} className={tableStyles.listTable}>
        <thead>
          {type === "rents" ? (
            <tr>
              <th>ID</th>
              <th>{t("user")}</th>
              <th>{t("vehicle")}</th>
              <th>{t("rent_type")}</th>
              <th>{t("process_start")}</th>
              <th>{t("process_finish")}</th>
              <th>{t("fee")}</th>
            </tr>
          ) : type === "chargeRents" ? (
            <tr>
              <th>ID</th>
              <th>{t("user")}</th>
              <th>{t("station")}</th>
              <th>Email</th>
              <th>{t("duration")}</th>
              <th>{t("fee")}</th>
              <th>{t("request_date")}</th>
              <th>{t("charging_start")}</th>
              <th>{t("charging_end")}</th>
            </tr>
          ) : type === "swaps" ? (
            <tr>
              <th>ID</th>
              <th>{t("user")}</th>
              {/* <th>Email</th> */}
              <th>{t("station")}</th>
              <th>{t("vehicle")}</th>
              <th>{t("request_date")}</th>
              {/* <th>{t("process_result")}</th> */}
            </tr>
          ) : (
            type === "payments" && (
              <tr>
                <th>ID</th>
                <th>{t("user")}</th>
                <th>{t("process_start")}</th>
                <th>{t("process_finish")}</th>
                <th>{t("is_succeed")}</th>
                <th>{t("fee")}</th>
              </tr>
            )
          )}
        </thead>

        <tbody>
          {reports &&
            reports?.map((report, indice) => {
              if (hideByFilter([Object.values(report)], filterValue))
                return (
                  <ReportsListRow
                    type={type}
                    report={report}
                    indice={indice}
                    setMode={setMode}
                    key={report.id}
                  />
                );

              return null;
            })}
        </tbody>
      </table>
      <Toast
        title={t("message")}
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
    </div>
  );
};

export default ReportsList;
