import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useLocalize } from "../../components/localize";
import { useDispatch } from "react-redux";
import { dateOnly, dateOnlyEU } from "../../utils/dateFormat";
import styles from "./companies.module.css";
import { setSelectedCompany } from "../../store/reducers/selectedCompanyReducer";
import { useSelector } from "react-redux";

const CompaniesListRow = ({ company, setMode, indice }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const dispatch = useDispatch();
  const t = useLocalize();
  const selectCompany = () => {
    dispatch(setSelectedCompany({ selectedCompany: company }));
  };

  return (
    <tr>
      <td>{company.id}</td>
      <td>{company.company_name}</td>
      <td>{dateOnlyEU(company.date_added, selectedLang)}</td>
      <td>{dateOnlyEU(company.latest_activity, selectedLang)}</td>
      <td>{company.driver_count}</td>
      <td>{company.vehicle_count}</td>
      {/* <td>
        <span
          className={styles.clickable}
          onClick={() => {
            selectCompany(company);
            setMode("details");
          }}
        >
          <FontAwesomeIcon icon={faInfo} /> {t("details")}
        </span>
      </td> */}
      <td>
        <span
          className={styles.clickable}
          onClick={() => {
            selectCompany(company);
            setMode("edit");
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> {t("details")}
        </span>
      </td>
    </tr>
  );
};

export default CompaniesListRow;
