import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stationList: null,
  updateList: false,
  plugsList: [],
  swapsList: [],
  multiSwapsList: [],
  selectedStation: null,
};

export const stationSlice = createSlice({
  name: "station",
  initialState,
  reducers: {
    fillstationList: (state, action) => {
      state.updateList = false;
      state.stationList = action.payload.stationList;
    },
    fillPlugsList: (state, action) => {
      state.plugsList = action.payload.stationList;
    },
    fillSwapsList: (state, action) => {
      state.swapsList = action.payload.stationList;
    },
    fillMultiSwapsList: (state, action) => {
      state.multiSwapsList = action.payload.stationList;
    },
    fetchstationList: (state) => {
      state.updateList = true;
    },
    setSelectedStation: (state, action) => {
      state.selectedStation = action.payload;
    },
  },
});

export const {
  fillstationList,
  fetchstationList,
  fillPlugsList,
  fillSwapsList,
  fillMultiSwapsList,
  setSelectedStation,
} = stationSlice.actions;

export default stationSlice.reducer;
