import styles from "./layout.module.css";
import Userinfo from "../userinfo";
import NavMenu from "../navmenu";
import LangSelector from "../langSelector";
import Fetchers from "../fetchers";
export const siteTitle = "EBike GreenGo Tech";

export default function Layout({ children }) {
  return (
    <div>
      <div className={styles.container} style={{ display: "flex" }}>
        <header className={styles.header}>
          <img
            src={"./images/greengo_logo.png"}
            height={40}
            alt="Company Logo"
          />
        </header>
        <NavMenu />
        <section>
          <LangSelector />
          <Userinfo />
        </section>
      </div>
      <Fetchers />
      <main>{children}</main>
    </div>
  );
}
