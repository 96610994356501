import tableStyles from "../commonStyles/listTable.module.css";
import { useSelector, useDispatch } from "react-redux";
import { dateFormat } from "../../utils/dateFormat";
import {
  plugCommand,
  swapCommand,
  multiSwapCommand,
} from "../../utils/stationsApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchstationList } from "../../store/reducers/stationReducer";
import { useLocalize } from "../../components/localize";

const PortInfo = ({ port, toast }) => {
  const dispatch = useDispatch();
  const t = useLocalize();
  return (
    <>
      <tr>
        <td>
          <b>PORT {port.plug_no}</b>
        </td>
        <td>{port.is_active ? "Aktif" : "Devre Dışı"}</td>
      </tr>
      <tr>
        <td>{t("energy_flow")}</td>
        <td>{port.is_delivering ? "Var" : "Yok"}</td>
      </tr>
      <tr>
        <td>{t("reservation")}</td>
        <td>{port.is_reserved ? "Evet" : "Hayır"}</td>
      </tr>
      <tr>
        <td>{t("instant_power")}</td>
        <td>{port.power_latest} Watt</td>
      </tr>
      <tr>
        <td>{t("energy")}</td>
        <td>{port.energy_delivered} Watt/H</td>
      </tr>
      <tr>
        <td>{t("flow_start")}</td>
        <td>
          {port.energy_begin_timestamp
            ? dateFormat(port.energy_begin_timestamp)
            : "---------"}
        </td>
      </tr>
      <tr>
        <td>{t("flow_end")}</td>
        <td>
          {port.energy_stop_timestamp
            ? dateFormat(port.energy_stop_timestamp)
            : "---------"}
        </td>
      </tr>
      <tr>
        <td>{t("last_command")}</td>
        <td>
          {port.power_deliver ? "Role Aç" : "Role Kapat"} ({port.command_state})
        </td>
      </tr>
      <tr>
        <td>
          <b>{t("alarms")}</b>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>{t("timeout")}</td>
        <td>{port.timeout_warning}</td>
      </tr>
      <tr>
        <td>{t("excessive_force")}</td>
        <td>{port.overpower_warning}</td>
      </tr>
      <tr>
        <td>{t("flow_interruption")}</td>
        <td>{port.energy_flow_warning}</td>
      </tr>
      <tr>
        <td>
          <button
            type="button"
            onClick={() =>
              plugCommand({ plugId: port.plug_id, command: "begin" }).then(
                () => {
                  dispatch(fetchstationList());
                  toast.info("Komut Gönderildi");
                }
              )
            }
          >
            {t("start")}
          </button>
          <button
            type="button"
            onClick={() =>
              plugCommand({ plugId: port.plug_id, command: "end" }).then(() => {
                dispatch(fetchstationList());
                toast.info("Komut Gönderildi");
              })
            }
          >
            {t("finish")}
          </button>
        </td>
      </tr>
    </>
  );
};

const MultiSwapInfo = ({ swapInfo }) => {
  console.log(swapInfo);
  const dispatch = useDispatch();
  const t = useLocalize();
  return (
    <>
      <tr>
        <td>
          <b>Charger {swapInfo?.charger_nr}</b>
        </td>
        <td>{swapInfo?.power > 0 ? "Şarj Ediyor" : "Şarj Beklemede"}</td>
      </tr>
      <tr>
        <td>
          <b>{t("instant_power")}</b>
        </td>
        <td>{swapInfo?.power} Watt</td>
      </tr>
      <tr>
        <td>
          <b>Kapak</b>
        </td>
        <td>{swapInfo?.door === 0 ? "Açık" : "Kapalı"}</td>
      </tr>

      <tr>
        <td>
          <b>Kilitli</b>
        </td>
        <td>{swapInfo?.lock === 2 ? "On" : "Off"}</td>
      </tr>
      <tr>
        <td>
          <b>Toplam Enerji</b>
        </td>
        <td>{swapInfo?.power_total} Watt/H</td>
      </tr>
      <tr>
        <td>
          <button
            type="button"
            onClick={() =>
              multiSwapCommand({
                id: swapInfo?.charger_nr,
                stationId: swapInfo?.station_id,
                command: "openDoor",
              }).then(() => {
                dispatch(fetchstationList());
                toast.info("Komut Gönderildi");
              })
            }
          >
            Kapak Aç
          </button>
        </td>
      </tr>
    </>
  );
};

const SwapInfo = ({ swapInfo }) => {
  const dispatch = useDispatch();
  const t = useLocalize();
  return (
    <>
      <tr>
        <td>
          <b>Side A</b>
        </td>
        <td>
          {swapInfo?.charging_side === "A" ? "Şarj Ediyor" : "Şarj Beklemede"}
        </td>
      </tr>
      <tr>
        <td>
          <b>{t("instant_power")}</b>
        </td>
        <td>{swapInfo?.port_a_power_watt} Watt</td>
      </tr>
      <tr>
        <td>
          <b>Kapak</b>
        </td>
        <td>{swapInfo?.door_a_status === 0 ? "Açık" : "Kapalı"}</td>
      </tr>

      <tr>
        <td>
          <b>Güç Komutu</b>
        </td>
        <td>{swapInfo?.port_a_power_enable ? "On" : "Off"}</td>
      </tr>
      <tr>
        <td>
          <b>Toplam Enerji</b>
        </td>
        <td>{swapInfo?.port_a_total_energy} Watt/H</td>
      </tr>
      <tr>
        <td>
          <button
            type="button"
            onClick={() =>
              swapCommand({ id: swapInfo?.id, command: "openDoorA" }).then(
                () => {
                  dispatch(fetchstationList());
                  toast.info("Komut Gönderildi");
                }
              )
            }
          >
            Kapak Aç (A)
          </button>
        </td>
      </tr>

      <tr>
        <td>
          <b>Side B</b>
        </td>
        <td>
          {swapInfo?.charging_side === "B" ? "Şarj Ediyor" : "Şarj Beklemede"}
        </td>
      </tr>
      <tr>
        <td>
          <b>Anlık Güç</b>
        </td>
        <td>{swapInfo?.port_b_power_watt} Watt</td>
      </tr>
      <tr>
        <td>
          <b>Kapak</b>
        </td>
        <td>{swapInfo?.door_b_status === 0 ? "Açık" : "Kapalı"}</td>
      </tr>

      <tr>
        <td>
          <b>Güç Komutu</b>
        </td>
        <td>{swapInfo?.port_b_power_enable ? "On" : "Off"}</td>
      </tr>
      <tr>
        <td>
          <b>Toplam Enerji</b>
        </td>
        <td>{swapInfo?.port_b_total_energy} Watt/H</td>
      </tr>
      <tr>
        <td>
          <button
            type="button"
            onClick={() =>
              swapCommand({ id: swapInfo?.id, command: "openDoorB" }).then(
                () => {
                  dispatch(fetchstationList());
                  toast.info("Komut Gönderildi");
                }
              )
            }
          >
            Kapak Aç (B)
          </button>
        </td>
      </tr>
    </>
  );
};

const StationData = () => {
  const selectedStation = useSelector(
    (state) => state.stations.selectedStation
  );
  const plugsList = useSelector((state) => state.stations.plugsList);
  const swapsList = useSelector((state) => state.stations.swapsList);
  const multiSwapsList = useSelector((state) => state.stations.multiSwapsList);

  let details;
  if (selectedStation.station_type === "swap") {
    details = swapsList.filter(
      (swap) => swap.station_id === selectedStation.station_id
    );
  } else if (selectedStation.station_type === "plug") {
    details = plugsList.filter(
      (plug) => plug.station_id === selectedStation.station_id
    );
  } else if (selectedStation.station_type === "multiswap") {
    details = multiSwapsList.filter(
      (multiSwap) => multiSwap.station_id === selectedStation.station_id
    );
  }

  return (
    <>
      <table className={tableStyles.listTable}>
        <thead></thead>
        <tbody>
          <tr>
            <td>Son İletişim</td>
            <td>{dateFormat(selectedStation.last_comm_date)}</td>
          </tr>
          {selectedStation.station_type === "swap" && (
            <SwapInfo swapInfo={details[0]} />
          )}
          {selectedStation.station_type === "plug" &&
            details.map(
              (port) => port.is_active && <PortInfo port={port} toast={toast} />
            )}
          {selectedStation.station_type === "multiswap" &&
            details.map((multiSwap) => <MultiSwapInfo swapInfo={multiSwap} />)}
        </tbody>
      </table>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="light"
      />
    </>
  );
};

export default StationData;
