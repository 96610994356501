import { serverPath } from "./devSettings";

const editUser = async (user) => {
  const reqData = user;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/editUser", options);
  const result = await response.json();

  return result;
};

export default editUser;
