import Layout from "../../components/layout";
import styles from "../users/users.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import { Filter, hideByFilter } from "../../components/filter";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocalize } from "../../components/localize";
import DriversLicenceListRow from "./DriversLicenceListRow";

const DriversLicence = () => {
  const driversLicenceList = useSelector((state) => state.driversLicence.list);
  const [filterValue, setFilterValue] = useState("");
  const t = useLocalize();

  return (
    <Layout>
      <section className={styles.container}>
        <div className={styles.usersList}>
          <h2>{t("drivers_licences")}</h2>
          <div className={styles.topBar}>
            <Filter setFilter={setFilterValue} />
          </div>
          <table className={tableStyles.listTable}>
            <thead>
              <tr>
                <th></th>
                <th>
                  {t("name")} {t("surname")}
                </th>
                <th>{t("drivers_licence")}</th>
                <th>{t("user_image")}</th>
                <th>
                  {t("approve")}/{t("reject")}
                </th>
              </tr>
            </thead>
            <tbody>
              {driversLicenceList?.map((driver, indice) => {
                if (hideByFilter([driver.customer_name], filterValue))
                  return (
                    <DriversLicenceListRow
                      driver={driver}
                      indice={indice}
                      key={driver.id}
                    />
                  );

                return null;
              })}
            </tbody>
          </table>
        </div>
      </section>
    </Layout>
  );
};

export default DriversLicence;
