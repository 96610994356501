import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getEbikes from "../../utils/getEbikesList";
import { fillBikesList } from "../../store/reducers/bikesReducer";
import getCompanies from "../../utils/getCompanies";
import { fillCompanyList } from "../../store/reducers/companiesReducer";
import {
  getStations,
  getPlugStations,
  getSwapStations,
  getMultiSwapStations,
} from "../../utils/stationsApi";
import {
  fillstationList,
  fillPlugsList,
  fillSwapsList,
  fillMultiSwapsList,
} from "../../store/reducers/stationReducer";
import getUsersList from "../../utils/getUsersList";
import { filluserList } from "../../store/reducers/usersList";
import getBatteries from "../../utils/getBatteryList";
import { fillBatteriesList } from "../../store/reducers/batteriesReducer";
import { getGroupsList } from "../../utils/getGroups";
import { fillGroupsList } from "../../store/reducers/groupsReducer";
import { getDrivers } from "../../utils/drivers";
import { fillDriverList } from "../../store/reducers/driversList";
import { getDriverLicences } from "../../utils/driversLicence";
import { fillDriversLicenceList } from "../../store/reducers/driversLicence";
import { getRentedBikes } from "../../utils/terminateRent";
import { fillRentedBikes } from "../../store/reducers/rentsReducer";
import { fillRentedCharges } from "../../store/reducers/chargeRentsReducer";
import { getRentedCharges } from "../../utils/terminateChargeRent";

const Fetchers = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const updateBikes = useSelector((state) => state.bikes.updateList);
  const updateCompanies = useSelector((state) => state.companies.updateList);
  const updateStations = useSelector((state) => state.stations.updateList);
  const updateUsers = useSelector((state) => state.usersList.updateList);
  const updateBatteries = useSelector((state) => state.batteries.updateList);
  const updateGroups = useSelector((state) => state.groups.updateGroupList);
  const updateDriversLicence = useSelector(
    (state) => state.driversLicence.updateList
  );
  const updateRentedBikes = useSelector(
    (state) => state.rentedBikes.updateList
  );
  const updateRentedCharges = useSelector(
    (state) => state.rentedCharges.updateList
  );
  const [timedUpdate, setTimedUpdate] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimedUpdate((prevTimedUpdate) => !prevTimedUpdate);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getEbikes({ username: user.username, token: user.token }).then(
      (bikesList) => dispatch(fillBikesList({ bikesList: bikesList.bikes }))
    );
  }, [updateBikes, timedUpdate]);

  useEffect(() => {
    getCompanies({ username: user.username, token: user.token }).then(
      (result) => dispatch(fillCompanyList({ list: result.companies }))
    );
  }, [updateCompanies, timedUpdate]);

  useEffect(() => {
    getStations({ username: user.username, token: user.token }).then(
      (result) => {
        dispatch(fillstationList({ stationList: result.stations }));
      }
    );

    getPlugStations({ username: user.username, token: user.token }).then(
      (result) => {
        dispatch(fillPlugsList({ stationList: result.stations }));
      }
    );

    getSwapStations({ username: user.username, token: user.token }).then(
      (result) => {
        dispatch(fillSwapsList({ stationList: result.stations }));
      }
    );
    getMultiSwapStations({ username: user.username, token: user.token }).then(
      (result) => {
        dispatch(fillMultiSwapsList({ stationList: result.stations }));
      }
    );
  }, [updateStations, timedUpdate]);

  useEffect(() => {
    getUsersList({ username: user.username, token: user.token }).then(
      (usersList) => {
        dispatch(filluserList({ userList: usersList.users }));
      }
    );
  }, [updateUsers, timedUpdate]);

  useEffect(() => {
    getBatteries({ username: user.username, token: user.token }).then(
      (batteryList) => {
        dispatch(fillBatteriesList({ batteryList: batteryList }));
      }
    );
  }, [updateBatteries, timedUpdate]);

  useEffect(() => {
    getGroupsList({ username: user.username, token: user.token }).then(
      (groupsList) => {
        dispatch(fillGroupsList({ groupsList: groupsList.groups }));
      }
    );
  }, [updateGroups]);

  useEffect(() => {
    getDrivers({ username: user.username, token: user.token }).then((resp) => {
      dispatch(fillDriverList(resp.drivers));
    });
  }, []);
  useEffect(() => {
    getDriverLicences({ username: user.username, token: user.token }).then(
      (resp) => {
        dispatch(fillDriversLicenceList({ list: resp.licences }));
      }
    );
  }, [updateDriversLicence]);
  useEffect(() => {
    getRentedBikes({ username: user.username, token: user.token }).then(
      (resp) => {
        dispatch(fillRentedBikes({ list: resp.list }));
      }
    );
  }, [updateRentedBikes]);
  useEffect(() => {
    getRentedCharges({ username: user.username, token: user.token }).then(
      (resp) => {
        dispatch(fillRentedCharges({ list: resp.list }));
      }
    );
  }, [updateRentedCharges]);
  return null;
};

export default Fetchers;
