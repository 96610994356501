import { useState } from "react";
import Layout from "../../components/layout";
import getUsersList from "../../utils/getUsersList";
import UserList from "./UserList";
import UserDetails from "./UserDetails";
import AddUser from "./AddUser";
import Groups from "../../components/groups";
import styles from "./users.module.css";

const Users = () => {
  const [mode, setMode] = useState("list");

  return (
    <Layout>
      <section className={styles.container}>
        {mode === "list" && <UserList setMode={setMode} />}
        {mode === "detail" && <UserDetails setMode={setMode} edit />}
        {mode === "adduser" && <AddUser setMode={setMode} />}
        {mode === "groupslist" && <Groups setMode={setMode} />}
      </section>
    </Layout>
  );
};

export default Users;
