export const en = {
  home: "Home",
  companies: "Companies",
  vehicles: "Vehicles",
  fleet: "Fleet",
  speed: "Speed",
  stations: "Stations",
  batteries: "Batteries",
  users: "Users",
  drivers: "Drivers",
  reports: "Reports",
  bikedetails: "Vehicle Details",
  back: "Back",
  edit: "Edit",
  details: "Details",
  add: "Add",
  delete: "Delete",
  assigned_courier_name: "Assigned Courier Name",
  save: "Save",
  type: "Type",
  plate: "Licence Plate",
  city: "City",
  enabled: "Active",
  status: "Status",
  connected: "Connected",
  battlevel: "Battery",
  charging: "Charging",
  search: "Search",
  company_name: "Copany Name",
  date_added: "Date Added",
  latest_activity: "Latest Actv.",
  driver_count: "Driver Count",
  vehicle_count: "Vehicle Count",
  userdetails: "User Details",
  username: "Username",
  name: "Name",
  surname: "Surname",
  groups: "Groups",
  email: "E-Mail",
  level: "Level",
  last_login: "Last Login",
  gsm_nr: "GSM Nr",
  tckn: "National ID",
  adduser: "Add User",
  serial_nr: "Serial Nr",
  bike_type: "Bike Type",
  color: "Color",
  brand: "Brand",
  company: "Company",
  group: "Group",
  power: "Power",
  battery: "Battery",
  speed_limit: "Speed Limit",
  gsm_imei: "GSM IMEI",
  gsm_sim_ccid: "GSM SIM CCID",
  gsm_operator: "GSM Operator",
  enabled_hours: "Enabled Hours",
  delete_title: "Confirm Delete",
  confirm_delete: "Item will be deleted, continue?",

  battery_saved: "Battery Saved",
  battery_added: "Battery Added",
  battery_details: "Battery Details",
  add_battery: "Add Battery",
  battery_type: "Battery Type",
  battery_capacity: "Battery Capacity",
  serial_no: "Serial No",
  message: "Message",
  all_vehicles: "All Vehicles",
  all_stations: "All Stations",
  all_types: "All Types",
  energy_flow: "Energy Flow",
  reservation: "Reservation",
  instant_power: "Instant Power",
  energy: "Energy",
  flow_start: "Flow Start",
  flow_end: "Flow End",
  last_command: "Last Command",
  alarms: "Alarms",
  timeout: "Time Out",
  excessive_force: "Excessive Force",
  flow_interruption: "Flow Interruption",
  start: "Start",
  swap: "Swap",
  plug: "Plug",
  finish: "Finish",
  rent_type: "Rent Type",
  hourly: "Hourly",
  three_hourly: "Three Hours",
  six_hourly: "Six Hours",
  daily: "Daily",
  vehicle: "Vehicle",
  report_type: "Report Type",
  rent_car: "Rent Car",
  rent_energy: "Energy Rental",
  battery_change: "Battery Change",
  report_creation: "Report Creation",
  user: "User",
  station: "Station",
  duration: "Duration",
  payment: "Payment",
  fee: "Fee",
  process_start: "Process Start",
  process_finish: "Process Finish",
  request_date: "Request Date",
  charging_start: "Charging Start",
  charging_end: "Charging End",
  process_result: "Process Result",
  number_port: "Number of Port",
  company_id: "Company ID",
  station_name: "Station Name",
  power_unit: "Power Unit",
  select_station: "Select Station",
  is_succeed: "Is Succeed",
  yes: "Yes",
  true: "True",
  false: "False",
  no: "No",
  succeed: "Succeed",
  failed: "Failed",
  showall: "Show All",
  no_results_for_given_date_range: "No report found for the given date range.",
  enter_the_report_type_start_date: "Please enter the report type, start date, and end date.",

  capacity_unit: "kW/h",
  an_error_occured: "An error occurred, please try again.",

  production_date: "Production Date",
  first_charge_date: "First Charge Date",
  associated_vehicle: "Associated Vehicle",
  associated_station: "Associated Station",
  associated_warehouse: "Associated Warehouse",
  charge_level: "Charge Level",
  capacity: "Capacity",

  bike_saved: "Vehicle Saved",
  bike_added: "Vehicle Added",
  imei_not_found_error: "Error! IMEI Nr must be 15 digits long.",
  vehicle_details: "Vehicle Details",
  add_new_vehicle: "Add New Vehicle",
  locked: "Locked",
  in_use: "In Use",
  offline: "Offline",
  edit_vehicle_details: "Edit Vehilce Details",
  click_the_button_to_be_able_to_select_a_location: "Click the button to be able to select a location",
  related_station: "Related Station",

  company_saved: "Company Saved",
  company_added: "Company Added",
  company_details: "Company Details",
  add_new_company: "Add New Company",
  company_type: "Company Type",
  customer: "Customer",
  partner: "Partner",

  total_vehicles: "Total Vehicles",
  daily_usage: "Daily Usage",
  total_users: "Total Users",
  total_stations: "Total Stations",
  active: "Active",
  total: "Total",
  rides: "Rides",
  day: "Day",

  username_or_password_not_set: "Username or password not set!",
  sign_in: "Sign In",
  sign_up: "Sign Up",
  login: "login",
  recover_password: "Recover Password",
  no_account_or_login_problem: "Don't have an account or problem with login?",
  or: "or",

  ports: "Ports",
  charger: "Charger",
  line: "Line",

  on: "ON",
  off: "OFF",

  station_saved: "Station Saved",
  station_added: "Station Added",
  station_details: "Station Details",
  add_new_station: "Add New Station",
  station_type: "Station Type",

  add_new_area: "Add Area",

  password: "Password",
  confirm_password: "Confirm Password",

  groupslist: "Groups List",
  account_activation: "Account Activated",
  profile: "Profile",
  new_user_added: "New User Added.",
  admin: "Admin",
  partner_admin: "Partner Admin",
  corp_admin: "Company Admin",
  corp_user: "Company User",
  indv_user: "Individual User",

  information: "Information",
  location: "Location",
  graphics: "Charts",
  adddriver: "Add Driver",
  onduty: "On Duty",
  offduty: "Off Duty",

  prices: "Prices",
  update_price_success: "Prices updated successfully.",
  update_price_error: "Prices can not updated. Please check and try again.",
  check_prices_warning: "Make sure all fees are entered correctly. Prices cannot be left blank, negative, or empty.",
  charge_rent: "Charge Rent",
  rent_hourly: "Rent Hourly",
  rent_daily: "Rent Daily",
  rent_weekly: "Rent Weekly",
  rent_monthly: "Rent Monthly",
  rent_penalty: "Rent Penalty",
  confirm_prices: "Are you sure you want to update the prices?",
  price_update_confirm_message: "Before updating, please double-check the prices.",
  drivers_licences: "Driver's Licences",
  licence_image: "Licence Image",
  is_it_approved: "Is it Approved?",
  approve: "Approve",
  reject: "Reject",
  driver_licence_approved: "Driver Licence Approved",
  driver_licence_rejected: "Driver Licence Rejected",
  rented_bikes: "Rented Bikes",
  process_id: "Process ID",
  start_date: "Start Date",
  terminate: "Terminate",
  terminate_rent: "Terminate Rent",
  terminate_rent_success: "Rent terminated successfully.",
  terminate_rent_error: "Rent could not be terminated. Please check and try again.",
  rented_charges: "Rented Charges",
  port_id: "Port ID",
  no_rented_charges: "No rented charges found.",
  no_rented_bikes: "No rented bikes found.",
  drivers_licence: "Driver's Licence",
  user_image: "User Image",
  licence_plate: "Licence Plate",
  driver_name: "Driver Name",
  renter_name: "Renter Name",
};
