import { useDispatch, useSelector } from "react-redux";
import { useLocalize } from "../../components/localize";

import styles from "./users.module.css";
import { setSelectedUser } from "../../store/reducers/selectedUserReducer";
import { dateFormat, dateFormatEU } from "../../utils/dateFormat";
//import { userLevels } from "./userPriviliges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const UserListRow = ({ indice, driver, setMode }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const t = useLocalize();
  const dispatch = useDispatch();

  const setUser = () => {
    //dispatch(setSelectedUser({ ...usr }));
    //setMode("detail");
  };

  return (
    <tr key={indice}>
      <td>{indice + 1}</td>
      <td>
        {driver.name} {driver.surname}
      </td>
      <td>{driver.identity_no}</td>
      <td>{dateFormatEU(driver.login_code_sent_date, selectedLang)}</td>
      <td>{driver.company_name}</td>
      <td>{driver.licence_plate ? t("onduty") : t("offduty")}</td>
      <td>{driver.licence_plate ? driver.licence_plate : "--------"}</td>
      <td>
        <span className={styles.clickable} onClick={() => setUser()}>
          <FontAwesomeIcon icon={faEdit} /> {t("details")}
        </span>
      </td>
    </tr>
  );
};

export default UserListRow;
