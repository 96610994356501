import { serverPath } from "./devSettings";

const deleteBattery = async ( battery ) => {

  const reqData = {
    battery_id : battery.battery_id
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: 'include',
  };

  const response = await fetch(serverPath + "/deleteBattery", options);
  const result = await response.json();

  return result;
};

export default deleteBattery;
