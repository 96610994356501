import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import tableStyles from "../commonStyles/listTable.module.css";
import styles from "./bikes.module.css";
import addBike from "../../utils/addBike";
import editBike from "../../utils/editBike";
import deleteBike from "../../utils/deleteBike";
import { useLocalize } from "../../components/localize";
import Toast from "../../components/toast";
import ConfirmDialog from "../../components/confirmDialog";
import { brands } from "./BikeProperties";
import { fetchBikesList } from "../../store/reducers/bikesReducer";
import { dateFormat, dateFormatEU } from "../../utils/dateFormat";
import { getVehicleAvailableStations } from "../../utils/stationsApi";

const AddBike = ({ setMode, edit }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editLock, setEditLock] = useState(false);
  const [availableStations, setAvailableStations] = useState([]);
  const companyList = useSelector((state) => state.companies.list);
  const selectedBike = useSelector((state) => state.selectedBike.selectedBike);
  const [getActiveStationsFlag, setActiveStationsFlag] = useState(false);
  const dispatch = useDispatch();

  let defaultBike = {};

  useEffect(() => {
    getVehicleAvailableStations().then((availableStations) => {
      console.log("Available Stations : ", availableStations);
      if (availableStations.state === "success") {
        setAvailableStations(availableStations.stations);
      }
    });
    return () => {};
  }, [getActiveStationsFlag]);

  if (!edit) {
    defaultBike = {
      type: "scooter",
      licence_plate: null,
      serial_no: null,
      color: null,
      brand: null,
      company_id: 1,
      motor_power: null,
      group: 0,
      battery_id: null,
      speed_limit: 90,
      gsm_imei: null,
      gsm_nr: null,
      gsm_ccid: null,
      gsm_operator: null,
      enabled_hours: null,
      last_comm_timestamp: null,
      last_location_time: null,
      latitude: null,
      longitude: null,
    };
  } else {
    defaultBike = { ...selectedBike };
  }

  const [bike, setBike] = useState(defaultBike);

  const handleChange = (e) => {
    setBike({ ...bike, [e.target.name]: e.target.value });
  };

  const saveBike = () => {
    if (!bike.licence_plate || !bike.serial_no || !bike.gsm_nr || !bike.brand) {
      setMessage(t("please_include_all_fields"));
      setShowMsg(true);
    } else if (bike.gsm_nr.length !== 11 && bike.gsm_nr.length !== 10) {
      setMessage(t("please_include_a_valid_gsm_number"));
      setShowMsg(true);
    } else if (bike.gsm_imei.length !== 15) {
      setMessage(t("imei_not_found_error"));
      setShowMsg(true);
    } else {
      if (edit) {
        editBike(bike);
        setMessage(t("bike_saved"));
      } else {
        if (bike.battery_id === "") bike.battery_id = null;
        bike.licence_plate = bike.licence_plate.toUpperCase();
        setActiveStationsFlag((curr) => !curr);
        addBike(bike);
        setMessage(t("bike_added"));
        setBike({
          ...bike,
          licence_plate: "",
          serial_no: "",
          battery_id: "",
          gsm_ccid: "",
          gsm_imei: "",
          gsm_nr: "",
        });
      }
      setShowMsg(true);
      dispatch(fetchBikesList());
    }
  };

  const deleteVehicle = () => {
    setConfirmDelete(true);
  };

  return (
    <div className={styles.editSectionContainer}>
      <h2>{t("vehicles")}</h2>
      <section className={styles.topBar}>
        <h3>{edit ? t("vehicle_details") : t("add_new_vehicle")} </h3>

        <section>
          {edit && (
            <button onClick={() => deleteVehicle()}>
              <FontAwesomeIcon icon={faTrash} /> {t("delete")}
            </button>
          )}
          <button
            onClick={() => {
              if (edit) {
                if (editLock) {
                  saveBike();
                  setEditLock(false);
                } else {
                  setEditLock(true);
                }
              } else {
                setEditLock(true);
                saveBike();
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} />{" "}
            {edit ? (editLock ? t("save") : t("edit")) : t("add")}
          </button>
          <button onClick={() => setMode("list")}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
          </button>
        </section>
      </section>
      <table className={tableStyles.listTable}>
        <tr>
          <td>{t("bike_type")}</td>
          {!edit || editLock ? (
            <td>
              <select name="type" value={bike.type} onChange={handleChange}>
                <option value="scooter">Scooter</option>
                <option value="ebike">E-Bike</option>
                <option value="tricycle">TriCycle</option>
              </select>
            </td>
          ) : (
            <td>{bike.type}</td>
          )}
        </tr>
        <tr>
          <td>{t("plate")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="licence_plate"
                value={bike.licence_plate}
                onChange={handleChange}
                className={styles.uppercase}
              />
            </td>
          ) : (
            <td>{bike.licence_plate}</td>
          )}
        </tr>
        <tr>
          <td>{t("serial_nr")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="serial_no"
                value={bike.serial_no}
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.serial_no}</td>
          )}
        </tr>
        <tr>
          <td>{t("color")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                name="color"
                value={bike.color}
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.color}</td>
          )}
        </tr>
        <tr>
          <td>{t("brand")}</td>
          {!edit || editLock ? (
            <td>
              <select
                type="text"
                name="brand"
                value={bike.brand}
                onChange={handleChange}
              >
                <option></option>
                {brands.map((brand) => (
                  <option value={brand} key={brand}>
                    {brand}
                  </option>
                ))}
              </select>
            </td>
          ) : (
            <td>{bike.brand}</td>
          )}
        </tr>
        <tr>
          <td>{t("company")}</td>
          {!edit || editLock ? (
            <td>
              <select
                value={bike.company_id}
                name="company_id"
                onChange={handleChange}
              >
                <option></option>
                {companyList.map((company) => (
                  <option value={company.id} key={company.id}>
                    {company.company_name}
                  </option>
                ))}
              </select>
            </td>
          ) : (
            <td>{bike.company_name}</td>
          )}
        </tr>
        <tr>
          <td>{t("group")}</td>
          {!edit || editLock ? (
            <td>
              <button type="button">Gurup İşlemleri</button>
            </td>
          ) : (
            <td>{bike.group}</td>
          )}
        </tr>
        <tr>
          <td>{t("power")} (KW)</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                value={bike.motor_power}
                name="motor_power"
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.motor_power}</td>
          )}
        </tr>
        {/*
        <tr>
          <td>{t("battery")}</td>
          <td>{bike.battery_id}</td>
        </tr>
        */}
        <tr>
          <td>
            {t("speed_limit")} {bike.speed_unit}
          </td>
          {!edit || editLock ? (
            <td>
              <input
                type="number"
                value={bike.speed_limit}
                name="speed_limit"
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.speed_limit}</td>
          )}
        </tr>
        <tr>
          <td>{t("gsm_imei")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="number"
                value={bike.gsm_imei}
                name="gsm_imei"
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.gsm_imei}</td>
          )}
        </tr>
        <tr>
          <td>{t("gsm_nr")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="number"
                value={bike.gsm_nr}
                name="gsm_nr"
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.gsm_nr}</td>
          )}
        </tr>
        <tr>
          <td>{t("gsm_sim_ccid")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="number"
                value={bike.gsm_ccid}
                name="gsm_ccid"
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.gsm_ccid}</td>
          )}
        </tr>
        <tr>
          <td>{t("gsm_operator")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                value={bike.gsm_operator}
                name="gsm_operator"
                onChange={handleChange}
                className={styles.uppercase}
              />
            </td>
          ) : (
            <td>{bike.gsm_operator}</td>
          )}
        </tr>
        <tr>
          <td>{t("related_station")}</td>
          {!edit || editLock ? (
            <td>
              <select
                name="station_id"
                value={bike.station_id}
                onChange={handleChange}
              >
                <option value={null}>İstasyon Seçiniz</option>
                {availableStations.map((availableStation) => {
                  return (
                    <option value={availableStation?.station_id}>
                      {availableStation?.name}
                    </option>
                  );
                })}
                {/* <option value="scooter">Scooter</option>
                <option value="ebike">E-Bike</option>
                <option value="tricycle">TriCycle</option> */}
              </select>
            </td>
          ) : (
            <td>{bike.station_id}</td>
          )}
        </tr>
        <tr>
          <td>Last Comm. Time</td>
          <td>
            {bike.last_comm_timestamp &&
              dateFormatEU(bike.last_comm_timestamp, selectedLang)}
          </td>
        </tr>
        <tr>
          <td>Last Position Time</td>
          <td>
            {bike.last_location_time &&
              dateFormatEU(bike.last_location_time, selectedLang)}
          </td>
        </tr>
        <tr>
          <td>Coordinates</td>
          {bike.latitude && bike.longitude && (
            <td>
              {bike.latitude?.toFixed(4)}, {bike.longitude?.toFixed(4)}{" "}
              <a
                target={"_blank"}
                rel={"noreferrer"}
                href={`http://maps.google.com/maps?q=${bike.latitude},${bike.longitude}&zoom=15`}
              >
                {" "}
                Dış haritada Aç
              </a>
            </td>
          )}
        </tr>
        {/*<tr>
          <td>{t("enabled_hours")}</td>
          {!edit || editLock ? (
            <td>
              <input
                type="text"
                value={bike.enabled_hours}
                name="enabled_hours"
                onChange={handleChange}
              />
            </td>
          ) : (
            <td>{bike.enabled_hours}</td>
          )}
          </tr>*/}
      </table>
      <Toast
        title="Message"
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
      {confirmDelete && (
        <ConfirmDialog
          title={t("delete_title")}
          message={t("confirm_delete")}
          onConfirm={() => {
            deleteBike(bike);
            setMode("list");
            dispatch(fetchBikesList());
          }}
          onClose={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
};

AddBike.defaultProps = {
  edit: false,
};

export default AddBike;
