import { useState } from "react";
import styles from "./stations.module.css";

import StationsGaugeChart from "./StationsGaugeChart";
import StationDetailsMap from "./StationDetailsMap";
import StationData from "./StationData";
import { useSelector, useDispatch } from "react-redux";
import { useLocalize } from "../../components/localize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TabControl from "../../components/tabControl";

const StationDetails = ({ setMode }) => {
  const [tab, setTab] = useState(1);
  const t = useLocalize();
  const selectedStation = useSelector(
    (state) => state.stations.selectedStation
  );
  const stationList = useSelector((state) => state.stations.stationList);

  const desiredStation = stationList.find(
    (station) => station.station_id === selectedStation.station_id
  );
  const [location, setLocation] = useState({
    latitude: desiredStation.latitude,
    longitude: desiredStation.longitude,
  });

  let tabs = [
    { nr: 1, name: t("information") },
    { nr: 2, name: t("location") },
    { nr: 3, name: t("graphics") },
  ];

  return (
    <div className={styles.stationDetails}>
      <div className={styles.topBar}>
        <h3>Station Details</h3>
        <button
          type="button"
          onClick={() => {
            setMode("edit");
          }}s
        >
          {t("edit")}
        </button>
        <button
          type="button"
          onClick={() => {
            setMode("list");
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
        </button>
      </div>
      <TabControl tabs={tabs} onTabChange={(tabNr) => setTab(tabNr)} />
      {tab === 1 && <StationData />}
      {tab === 2 && <StationDetailsMap location={location} />}
      {tab === 3 && <StationsGaugeChart />}
      {/*
      <div className={styles.stationDetailsBody}>
        <StationDetailsTable />
        <div className={styles.stationDetailsPlugins}>
          <div className={styles.stationDetailsGaugeCharts}>
            <StationsGaugeChart />
            <StationsGaugeChart />
          </div>
          <StationsMap></StationsMap>
        </div>
        <StationEdit></StationEdit>
        <StationDelete></StationDelete>
      </div>
            */}
    </div>
  );
};

export default StationDetails;
