import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./navmenu.module.css";

import { useLocalize } from "../localize";
import { setPage } from "../../store/reducers/pagesReducer";

const MenuItem = ({ linkName, href = "", items = null }) => {
  const navigate = useNavigate();
  const selectedPage = useSelector((state) => state.pages.selectedPage);

  const dispatch = useDispatch();

  let localStyle = null;
  if (selectedPage === href) localStyle = { color: "#DD5A2A" };

  const linkClick = () => {
    dispatch(setPage({ selectedPage: href }));
    navigate("/" + href);
  };

  return (
    <div
      className={styles.menuitem}
      onClick={linkClick}
      style={localStyle}
    >
      {linkName}
    </div>
  );
};

const NavMenu = () => {
  const t = useLocalize();

  const selectedPage = useSelector((state) => state.pages.selectedPage);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("afterunload", getCurrentPage);
    return () => {
      window.removeEventListener("afterunload", getCurrentPage);
    };
  }, []);

  const getCurrentPage = (e) => {
    if (user.username && selectedPage) {
      //todo check token here
      navigate("/" + selectedPage);
    }
  };

  return (
    <div className={styles.navMenuGroup}>
      <div className={styles.navMenu}>
        {user.priviliges.canSeeDashBoard && (
          <MenuItem
            linkName={t("home")}
            href="home"
          />
        )}
        {user.priviliges.canSeeCompanies && (
          <MenuItem
            linkName={t("companies")}
            href="companies"
          />
        )}
        {user.priviliges.canSeeVehicles && (
          <MenuItem
            linkName={t("vehicles")}
            href="bikes"
          />
        )}
        {user.priviliges.canSeeVehicles && (
          <MenuItem
            linkName={t("fleet")}
            href="fleet"
          />
        )}
        {user.priviliges.canSeeStations && (
          <MenuItem
            linkName={t("stations")}
            href="stations"
          />
        )}
        {user.priviliges.canSeeBatteries && (
          <MenuItem
            linkName={t("batteries")}
            href="batteries"
          />
        )}
        {user.priviliges.canSeeUsers && (
          <MenuItem
            linkName={t("Users")}
            href="users"
          />
        )}
        {user.priviliges.canSeeDrivers && (
          <MenuItem
            linkName={t("Drivers")}
            href="drivers"
          />
        )}
        {/* {user.level === "admin" && (
          <MenuItem linkName={t("RentaBike")} href="rentabike" />
        )}
        {user.level === "admin" && (
          <MenuItem linkName={t("SmartPlug")} href="smartplug" />
        )} */}
        {user.priviliges.canSeeReports && (
          <MenuItem
            linkName={t("Reports")}
            href="reports"
          />
        )}
        {user.priviliges.canSeeProfile && (
          <MenuItem
            linkName={t("Profile")}
            href="profile"
          />
        )}
        {user.priviliges.canSeePrices && (
          <MenuItem
            linkName={t("Prices")}
            href="prices"
          />
        )}
        {user.priviliges.canSeeDriversLicence && (
          <MenuItem
            linkName={t("drivers_licences")}
            href="driversLicence"
          />
        )}
        {user.priviliges.canSeeRentedBikes && (
          <MenuItem
            linkName={t("rented_bikes")}
            href="rentedBikes"
          />
        )}
        {user.priviliges.canSeeRentedCharges && (
          <MenuItem
            linkName={t("rented_charges")}
            href="rentedCharges"
          />
        )}
      </div>
    </div>
  );
};

export default NavMenu;
