import { useSelector } from "react-redux";
import { tr } from "./tr.js";
import { en } from "./en.js";

export const useLocalize = (w) => {
  const selectedLang = useSelector((state) => state.user.language);

  function translate(w) {
    if (w === "null") {
      return;
    }
    w = w.toString().toLowerCase();

    switch (selectedLang) {
      case "tr":
        if (tr[`${w}`]) return tr[`${w}`].toString();
        return w;
      case "en":
        if (tr[`${w}`]) return en[`${w}`].toString();
        return w;
      default:
        return w;
    }
  }

  return translate;
};
