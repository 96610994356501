import { useState } from "react";
import QrScanner from "react-qr-scanner";
import styles from "./rentabike.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const QrReader = ({ onReadSuccess, setMode, setQrOn }) => {
  const [qrCode, setqrCode] = useState(null);

  const previewStyle = {
    height: 480,
    width: 480,
  };

  const handleQR = (result) => {
    if (result.text !== qrCode) {
      onReadSuccess(result.text);
      setqrCode(result.text);
    }
  };

  return (
    <div className={styles.qrSection}>
      <h1 className={styles.pageTitle}>
        <FontAwesomeIcon icon={faQrcode} /> Scan QR Code
      </h1>
      <QrScanner
        delay={500}
        style={previewStyle}
        onScan={(result) => result && handleQR(result)}
        onError={(error) => console.log("Error")}
      />

      <section className={styles.qrButtons}>
        <button onClick={() => setMode("map")}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Map
        </button>
      </section>
    </div>
  );
};

export default QrReader;
