import { useState } from "react";
import Layout from "../../components/layout";
import BikeList from "./BikesList";
import AddBike from "./AddBike";
import styles from "./bikes.module.css";

const Bikes = () => {
  const [mode, setMode] = useState("list");

  return (
    <Layout>
      <section className={styles.container}>
        {mode === "list" && <BikeList setMode={setMode} />}
        {mode === "edit" && <AddBike setMode={setMode} edit />}
        {mode === "add" && <AddBike setMode={setMode} />}
      </section>
    </Layout>
  );
};

export default Bikes;
