import { useDispatch, useSelector } from "react-redux";
import { useLocalize } from "../../components/localize";
import styles from "../users/users.module.css";
import Toast from "../../components/toast";
import { useState } from "react";
import { terminateRent } from "../../utils/terminateRent";
import { fetchRentedBikesList } from "../../store/reducers/rentsReducer";
import { dateFormatEU } from "../../utils/dateFormat";

const RentedBikesListRow = ({ indice, driver }) => {
  const selectedLang = useSelector((state) => state.user.language);
  const t = useLocalize();
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const handleReject = async () => {
    const response = await terminateRent(driver);
    if (response.state === "success") {
      setMessage(t("terminate_rent_success"));
      dispatch(fetchRentedBikesList());
    } else {
      setMessage(t("terminate_rent_error"));
    }
    setShowMsg(true);
  };

  return (
    <tr key={indice}>
      <td>{indice + 1}</td>
      <td>{driver.process_id}</td>
      <td>{driver.name}</td>
      <td>{driver.licence_plate}</td>
      <td>{dateFormatEU(driver.start_date, selectedLang)} </td>
      <td>
        <button
          onClick={handleReject}
          className={styles.rejectButton}
        >
          {t("terminate")}
        </button>
      </td>
      <Toast
        title="Message"
        message={message}
        show={showMsg}
        onClose={() => {
          setShowMsg(false);
        }}
      />
    </tr>
  );
};

export default RentedBikesListRow;
