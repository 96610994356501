export const tr = {
  home: "Ana Sayfa",
  companies: "Firmalar",
  vehicles: "Araçlar",
  fleet: "Filo Takip",
  speed: "Hız",
  stations: "İstasyonlar",
  batteries: "Bataryalar",
  users: "Kullanıcılar",
  drivers: "Sürücüler",
  reports: "Raporlar",
  bikedetails: "Araç Detayları",
  assigned_courier_name: "Atanan Kurye Adı",
  back: "Geri",
  edit: "Düzenle",
  details: "Detaylar",
  add: "Ekle",
  delete: "Sil",
  save: "Kaydet",
  type: "Tip",
  plate: "Plaka",
  city: "Şehir",
  enabled: "Aktif",
  status: "Durum",
  connected: "Bağlı",
  battlevel: "Batarya",
  charging: "Şarj",
  search: "Arama",
  company_name: "Firma Adı",
  date_added: "Eklenme Tar.",
  latest_activity: "Son Aktivite",
  driver_count: "Sürücü Sayısı",
  vehicle_count: "Araç Sayısı",
  userdetails: "Kullanıcı Detayları",
  username: "Kullanıcı Adı",
  name: "Ad",
  surname: "Soyad",
  groups: "Gruplar",
  email: "E-Posta",
  level: "Seviye",
  last_login: "Son Giriş",
  gsm_nr: "GSM No",
  tckn: "TCKN",
  adduser: "Kullanıcı Ekle",
  serial_nr: "Seri No",
  bike_type: "Araç Tipi",
  color: "Renk",
  brand: "Marka",
  company: "Firma",
  group: "Grup",
  power: "Güç",
  battery: "Batarya",
  speed_limit: "Hız Sınırı",
  gsm_imei: "GSM IMEI",
  gsm_sim_ccid: "GSM SIM CCID",
  gsm_operator: "GSM Operatör",
  enabled_hours: "İzinli Saatler",
  delete_title: "Silme Onayı",
  confirm_delete: "Veri silinecektir, devam?",

  battery_saved: "Batarya Kaydedildi",
  battery_added: "Batarya Eklendi",
  battery_details: "Batarya Bilgileri",
  add_battery: "Batarya Ekle",
  battery_type: "Batarya Tipi",
  battery_capacity: "Batarya Kapasitesi",
  serial_no: "Seri Numarası",
  message: "Mesaj",

  energy_flow: "Enerji Akışı",
  reservation: "Rezerve",
  instant_power: "Anlık Güç",
  energy: "Enerji",
  flow_start: "Akış Başlangıcı",
  flow_end: "Akış Bitişi",
  last_command: "Son Komut",
  alarms: "Alarmlar",
  timeout: "Zaman Aşımı",
  excessive_force: "Aşırı Güç",
  flow_interruption: "Akış Kesilmesi",
  start: "Başlat",
  finish: "Bitir",
  rent_type: "Kiralama Türü",
  hourly: "Saatlik",
  three_hourly: "Üç Saatlik",
  six_hourly: "Altı Saatlik",
  daily: "Günlük",
  vehicle: "Araç",
  payment: "Ödeme",
  is_succeed: "Başarılı",
  report_type: "Rapor Tipi",
  rent_car: "Araç Kiralama",
  rent_energy: "Enerji Kiralama",
  battery_change: "Batarya Değişimi",
  report_creation: "Rapor Oluştur",
  export_excel: "Excel Dönüştürcü",
  export_pdf: "PDF Dönüştürcü",
  user: "Kullanıcı",
  station: "İstasyon",
  duration: "Süre",
  fee: "Ücret",
  process_start: "İşlem Başlangıç",
  process_finish: "İşlem Bitiş",
  request_date: "Talep Tarihi",
  charging_start: "Şarj Başlangıç",
  charging_end: "Şarj Bitiş",
  process_result: "İşlem Sonucu",
  number_port: "Port Sayısı",
  company_id: "Şirket Numarası",
  station_name: "İstasyon Adı",
  power_unit: "Güç Birimi",
  select_station: "İstasyon Seç",

  yes: "Evet",
  no: "Hayır",

  showall: "Tümünü Göster",

  capacity_unit: "kW/sa",

  production_date: "Üretim Tarihi",
  first_charge_date: "İlk Şarj Tarihi",
  associated_vehicle: "Bağlı Araç",
  associated_station: "Bağlı İstasyon",
  associated_warehouse: "Bağlı Depo",
  charge_level: "Şarj Seviyesi",
  capacity: "Kapasite",

  bike_saved: "Araç Kaydedildi",
  bike_added: "Araç Eklendi",
  imei_not_found_error: "Hata! IMEI numarası 15 hane olmalıdır.",
  click_the_button_to_be_able_to_select_a_location: "Konum seçebilmek için butona tıklayınız",
  vehicle_details: "Araç Detayları",
  add_new_vehicle: "Yeni Araç Ekle",
  locked: "Kilitli",
  in_use: "Kullanımda",
  offline: "Çevrimdışı",
  edit_vehicle_details: "Araç Bilgilerini Değiştir",
  related_station: "Bağlı İstasyon",

  company_saved: "Firma Kaydedildi",
  company_added: "Firma Eklendi",
  company_details: "Firma Detayları",
  add_new_company: "Yeni Firma Ekle",
  company_type: "Firma Tipi",
  customer: "Müşteri",
  partner: "Partner",

  total_vehicles: "Toplam Araç",
  daily_usage: "Günlük Kullanım",
  total_users: "Toplam Kullanıcı",
  total_stations: "Toplam İstasyon",
  active: "Aktif",
  total: "Toplam",
  rides: "Biniş",
  day: "Gün",

  username_or_password_not_set: "Kullanıcı adı veya şifre girilmedi!",
  sign_in: "Giriş Yap",
  sign_up: "Kayıt Ol",
  login: "Giriş",
  recover_password: "Şifre Yenile",
  no_account_or_login_problem: "Hesabın yok veya girişle ilgili bir sorun mu yaşıyorsun?",
  or: "veya",

  ports: "Portlar",
  charger: "Şarj Cihazı",
  line: "Güç",

  on: "Açık",
  off: "Kapalı",

  station_saved: "İstasyon Kaydedildi",
  station_added: "İstasyon Eklendi",
  station_details: "İstasyon Detayları",
  add_new_station: "Yeni İstasyon Ekle",
  station_type: "İstasyon Tipi",

  add_new_area: "Bölge Ekle",
  no_results_for_given_date_range: "Verilen tarih aralığında rapor bulunamadı.",
  enter_the_report_type_start_date: "Lütfen rapor tipini, başlangıç ve bitiş tarihini giriniz.",
  password: "Şifre",
  confirm_password: "Şifreyi Doğrula",
  true: "Doğru",
  all_vehicles: "Tüm Araçlar",
  all_stations: "Tüm İstasyonlar",
  false: "Yanlış",
  an_error_occured: "Bir hata oluştu, lütfen tekrar deneyiniz.",
  all_types: "Tüm Tipler",
  plug: "Fiş",
  swap: "Takas",

  succeed: "Başarılı",
  failed: "Başarısız",
  groupslist: "Grup Listesi",
  account_activation: "Hesap Aktifleştirilmiş",
  profile: "Profil",
  new_user_added: "Yeni Kullanıcı Eklendi.",
  admin: "Admin",
  partner_admin: "Partner Admin",
  corp_admin: "Firma Admin",
  corp_user: "Firma Kullanıcı",
  indv_user: "Bireysel Kullanıcı",
  please_include_all_fields: "Lütfen boş alanları doldurunuz!",
  information: "Bilgi",
  location: "Konum",
  graphics: "Grafikler",
  please_include_a_valid_gsm_number: "Lütfen geçerli bir telefon numarası giriniz!",
  adddriver: "Sürücü Ekle",
  onduty: "Görevde",
  offduty: "Görev Dışı",

  prices: "Fiyatlar",
  update_price_success: "Fiyatlar başarıyla güncellendi.",
  update_price_error: "Fiyatlar güncellenemedi. Lütfen kontrol edip tekrar deneyin.",
  check_prices_warning: "Ücretlerin hepsinin doğru girildiğinden emin olun. Fiyatlar boş bırakılamaz. Negatif olamaz ve boş bırakılamaz.",
  charge_rent: "Şarj Kiralama",
  rent_hourly: "Saatlik Araç Kiralama",
  rent_daily: "Günlük Araç Kiralama",
  rent_weekly: "Haftalık Araç Kiralama",
  rent_monthly: "Aylık Araç Kiralama",
  rent_penalty: "Ceza Ücreti",
  confirm_prices: "Fiyatları güncellemek istediğinizden emin misiniz?",
  price_update_confirm_message: "Güncellemeden önce lütfen fiyatları tekrar kontrol edin.",
  drivers_licences: "Sürücü Belgeleri",
  licence_image: "Belge Resmi",
  is_it_approved: "Onaylandı mı?",
  approve: "Onayla",
  reject: "Reddet",
  driver_licence_approved: "Sürücü Belgesi Onaylandı",
  driver_licence_rejected: "Sürücü Belgesi Reddedildi",
  rented_bikes: "Kiralanan Araçlar",
  process_id: "İşlem ID",
  start_date: "Başlangıç Tarihi",
  terminate: "Sonlandır",
  success: "Başarılı",
  error: "Hata",
  terminate_rent: "Kiralama Sonlandır",
  terminate_rent_success: "Kiralama başarıyla sonlandırıldı.",
  terminate_rent_error: "Kiralama sonlandırılamadı",
  terminate_rent_error_message: "Lütfen kontrol edip tekrar deneyin.",
  rented_charges: "Kiralanan Şarjlar",
  port_id: "Port ID",
  no_rented_charges: "Kiralanan şarj bulunamadı.",
  no_rented_bikes: "Kiralanan araç bulunamadı.",
  drivers_licence: "Sürücü Belgesi",
  user_image: "Kullanıcı Resmi",
  driver_name: "Sürücü Adı",
  licence_plate: "Plaka",
  renter_name: "Kiralayanın Adı",
};
