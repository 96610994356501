import { serverPath } from "./devSettings";

const editBike = async (bike) => {
  const reqData = {
    bike_id: bike.bike_id,
    type: bike.type,
    licence_plate: bike.licence_plate,
    serial_no: bike.serial_no,
    color: bike.color,
    brand: bike.brand,
    company_id: bike.company_id,
    motor_power: bike.motor_power,
    group: bike.group,
    battery_id: bike.battery_id,
    speed_limit: bike.speed_limit,
    gsm_imei: bike.gsm_imei,
    gsm_nr: bike.gsm_nr,
    gsm_ccid: bike.gsm_ccid,
    gsm_operator: bike.gsm_operator,
    enabled_hours: bike.enabled_hours,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/editBike", options);
  const result = await response.json();

  return result;
};

export default editBike;
