import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/layout";
import styles from "./home.module.css";
import Box from "../../components/box";
import {
  faBicycle,
  faChartSimple,
  faUser,
  faChargingStation,
} from "@fortawesome/free-solid-svg-icons";
import Chart from "../../components/chart/Chart";
import getBadges from "../../utils/dashboard";

import { useLocalize } from "../../components/localize";

import Groups from "../../components/groups";
import getAllUsage from "../../utils/getUsage";

const Home = () => {
  const user = useSelector((state) => state.user);
  const [badgeData, setBadgeData] = useState(null);
  const [usage, setUsage] = useState(null);

  useEffect(() => {
    getBadges({ token: user.token }).then((badgeData) =>
      setBadgeData(badgeData)
    );

    getAllUsage().then((usage) => {
      setUsage(usage);
    });
  }, []);

  const t = useLocalize();

  return (
    <>
      <Layout>
        <div className={styles.homeContainer}>
          {badgeData && (
            <div className={styles.boxContainer}>
              <Box
                value={
                  badgeData?.vehicleCount
                    ? "0/" + badgeData.vehicleCount.toString()
                    : "-/-"
                }
                property={t("total_vehicles")}
                icon={faBicycle}
                bgColor={styles.blueBackground}
                message={`${t("active")} / ${t("total")}`}
              />
              <Box
                value={badgeData.tripCount}
                property={t("daily_usage")}
                icon={faChartSimple}
                bgColor={styles.greenBackground}
                message={`${t("rides")} / ${t("day")}`}
              />
              <Box
                value={badgeData.userCount}
                property={t("total_users")}
                icon={faUser}
                bgColor={styles.yellowBackground}
                message={`${t("active")} / ${t("total")}`}
              />
              <Box
                value={badgeData.stationCount}
                property={t("total_stations")}
                icon={faChargingStation}
                bgColor={styles.slateBackground}
                message={`${t("active")} / ${t("total")}`}
              />
            </div>
          )}
          <div className={styles.chartContainer}>
            <Chart usage={usage} type={1}></Chart>
            <Chart usage={usage} type={2}></Chart>
            <Chart usage={usage} type={3}></Chart>
            <Chart usage={usage} type={4}></Chart>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
