import { serverPath } from "./devSettings";

const getGroupsList = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getGroups", options);
  const result = await response.json();

  return result;
};

const getGroupMembers = async (groupID) => {
  const reqData = {
    groupID: groupID,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getGroupMembers", options);
  const result = await response.json();

  return result;
};

const addNewGroup = async (groupName) => {
  const reqData = {
    groupName: groupName,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addGroup", options);
  const result = await response.json();

  return result;
};

export { getGroupsList, getGroupMembers, addNewGroup };
