import { useState } from "react";
import styles from "./passwordRecover.module.css";
import { resetPassword } from "../../utils/resetPassword";

const ForgotPassword = () => {
  const [pageState, setPageState] = useState(0);

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [alert, setAlert] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const _username = params.get("username");

  const [username, setUsername] = useState(_username);

  const saveNewPassword = () => {
    if (passwordAgain !== password) {
      setAlert("Şifreler uyuşmuyor!");
    } else if (password.length < 8) {
      setAlert("Şifre 8 karakter veya daha uzun olmalı!");
    } else {
      resetPassword({
        username: username,
        password: password,
      }).then((result) => {
        if (result.state === "success") setPageState(1);
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <img
          src="/images/greengo_logo.png"
          alt="bm logo"
          className={styles.logo}
        />
        <h3 className={styles.header}>Şifre Belirleme</h3>

        {pageState === 0 ? (
          <>
            <div className={styles.inputContainer}>
              <label>Kullanıcı Adı</label>
              <input
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                value={username}
                className={styles.input}
                disabled="true"
              ></input>
            </div>
            <div className={styles.inputContainer}>
              <label>Yeni Şifre</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className={styles.input}
                value={password}
              ></input>
            </div>
            <div className={styles.inputContainer}>
              <label>Yeni Şifre Tekrar</label>
              <input
                onChange={(e) => setPasswordAgain(e.target.value)}
                type="password"
                className={styles.input}
                value={passwordAgain}
              ></input>
            </div>
            <button
              className={styles.button}
              onClick={() => {
                saveNewPassword();
              }}
            >
              Kaydet
            </button>
            <div>
              <p>{alert}</p>
            </div>
          </>
        ) : (
          <div className={styles.column}>
            <p>Hesap Aktivasyonu Tamamlandı!</p>
            <p>
              <a href="/">Giriş için tıklayın.</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
