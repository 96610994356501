import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateList: false,
  list: [],
};

export const rentedCharges = createSlice({
  name: "rentedCharges",
  initialState,
  reducers: {
    fillRentedCharges: (state, action) => {
      state.updateList = false;
      state.list = action.payload.list;
    },
    fetchRentedChargesList: (state, action) => {
      state.updateList = true;
    },
  },
});

export const { fillRentedCharges, fetchRentedChargesList } = rentedCharges.actions;
export default rentedCharges.reducer;
