import { serverPath } from "./devSettings";

const getRentedBikes = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getRentedBikes", options);
  const result = await response.json();

  return result;
};

const terminateRent = async (bike) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bike),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/terminateRent", options);
  const result = await response.json();

  return result;
};

export { getRentedBikes, terminateRent };
