import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./langselector.module.css";
import { setLanguage } from "../../store/reducers/userReducer";

const LangSelector = () => {
  const selectedLang = useSelector((state) => state.user.language);
  const [lang, setLang] = useState(selectedLang);
  const dispatch = useDispatch();

  function setSelectedLanguage(event) {
    setLang(event.target.value);
    dispatch(setLanguage({language : event.target.value}));
  }

  return (
    <select className={styles.langSelector} onChange={setSelectedLanguage} value={lang}>
      <option value="en">EN</option>
      <option value="tr">TR</option>
    </select>
  );
};

export default LangSelector;
