import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
};

const selectedBikeSlice = createSlice({
  name : "selectedBike",
  initialState,
  reducers: {
    setSelectedBike : (state, action) => {
      state.selectedBike = action.payload.selectedBike;
    }
  }
})

export const {setSelectedBike} = selectedBikeSlice.actions;
export default selectedBikeSlice.reducer;

// const selectedBikeReducer = (state = initialState, action) => {
//     const payload = action.payload;
//     switch(action.type){
    
//       case actionTypes.SET_SELECTED_BIKE:
//         return {
//           ...state,
//           ...payload.selectedBike,
//         }
//       default : 
//         return {
//           ...state
//         }    
//   }
// };

// export default selectedBikeReducer;