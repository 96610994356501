import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocalize } from "../../components/localize";
import { useNavigate } from "react-router-dom";
import {
  faArrowLeft,
  faSave,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import styles from "./stations.module.css";
import addStation from "./../../utils/addStation";
import Toast from "../../components/toast";
import StationAddMap from "./StationAddMap";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";

const StationAdd = ({ setMode, edit }) => {
  const navigate = useNavigate();
  const companyId = useSelector((state) => state.user.companyId);
  const userid = useSelector((state) => state.user.userid);
  const [name, setName] = useState("");
  const [imei, setImei] = useState("");
  const [type, setType] = useState("");
  const [serialNr, setSerialNr] = useState("");
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [message, setMessage] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const t = useLocalize();

  const handleSetPosition = (newPosition) => {
    console.log(newPosition.lat);
    console.log(newPosition.lng);
    setLat(newPosition.lat);
    setLon(newPosition.lng);
  };

  const AddStationHandler = () => {
    if (name === "" || !name) {
      setMessage("İstasyon ismi belirtilmelidir.");
      setShowMsg(true);
      return;
    }

    if (imei.length !== 15) {
      setMessage("IMEI 15 hane olmalıdır!");
      setShowMsg(true);
      return;
    }

    if (serialNr === "" || !serialNr) {
      setMessage("Seri No Girilmelidir.");
      setShowMsg(true);
      return;
    }

    if (type === "" || !type) {
      setMessage("İstasyon tipini seçiniz!");
      setShowMsg(true);
      return;
    }
    if (lat === null && lon === null) {
      setMessage("İstasyon konumu seçiniz!");
      setShowMsg(true);
      return;
    }
    if (showMap === false) {
      setMessage("İstasyon konumunu seçiniz!");
      setShowMsg(true);
      return;
    }

    let stationData = {
      companyid: companyId,
      userid: userid,
      imei: imei,
      name: name,
      type: type,
      serialNr: serialNr,
      lat: lat,
      lon: lon,
    };

    addStation(stationData).then((result) => {
      if (result?.state === "success") {
        setMessage("İstasyon Eklendi");
        setShowMsg(true);

        setLat(null);
        setLon(null);
        setImei("");
        setName("");
        setSerialNr("");
        document.getElementById("stationNameInput").value = "";
        document.getElementById("stationImeiInput").value = "";
        document.getElementById("stationSerialInput").value = "";
        document.getElementById("type").value = "";
      } else {
        if (result?.cause === "imei_already_used") {
          setMessage("Bu IMEI önceden kullanılmış");
          setShowMsg(true);
        } else {
          setMessage("İstasyon eklenemedi!");
          setShowMsg(true);
        }
      }
    });
  };

  return (
    <div className={styles.stationsList}>
      <h2>{t("stations")}</h2>
      <section className={styles.topBar}>
        <h3>{t("add_new_station")} </h3>
        <section>
          <button onClick={() => AddStationHandler()}>
            <FontAwesomeIcon icon={faSave} />
            {t("save")}
          </button>
          <button
            type="button"
            className={styles.backButton}
            onClick={() => {
              setMode("list");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
          </button>
        </section>
      </section>
      <div className={styles.stationsListandMap}>
        <div>
          <table style={{ minWidth: "330px" }}>
            <tbody>
              <tr>
                <td>{t("station_name")}</td>
                <td>
                  <input
                    id="stationNameInput"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>{"IMEI"}</td>
                <td>
                  <input
                    id="stationImeiInput"
                    type="number"
                    onChange={(e) => setImei(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("serial_no")}</td>
                <td>
                  <input
                    id="stationSerialInput"
                    type="number"
                    onChange={(e) => setSerialNr(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("station_type")}</td>
                <td>
                  <select id="type" onChange={(e) => setType(e.target.value)}>
                    <option></option>
                    <option value="swap">Swap</option>
                    <option value="plug">Plug</option>
                  </select>
                </td>
              </tr>
              {type === "plug" ? (
                <tr>
                  <td>{t("number_port")}</td>
                  <td>
                    <input
                      type="number"
                      name="number_port"
                      id="stationPort1Input"
                      value={null}
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>{t("number_port")}</td>
                  <td>
                    <input
                      id="stationPortInput"
                      type="number"
                      name="number_port"
                      value={2}
                      disabled
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <button
            type="button"
            className={styles.button}
            onClick={() => setShowMap(true)}
          >
            <FontAwesomeIcon icon={faMapPin} />
            {t("select_station")}
          </button>
          {showMap ? null : (
            <p className={styles.warning}>
              {t("click_the_button_to_be_able_to_select_a_location")}.
            </p>
          )}
        </div>
        <StationAddMap showMap={showMap} onPositionChange={handleSetPosition} />
      </div>
      <Toast
        title="Message"
        message={message}
        show={showMsg}
        onClose={() => setShowMsg(false)}
      />
    </div>
  );
};

export default StationAdd;
