import L from "leaflet";
/*
import blueTriangle from "./icons/blue.png";
import redFlagImage from "./icons/red_flag.png";
import greenFlagImage from "./icons/green_flag.png";
*/
const defaultMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [13, 0],
});

const onlineVehicleMarker = new L.icon({
  iconUrl: require("../../icons/onlineVehicleIcon.png"),
  iconSize: [25, 41],
  iconAnchor: [13, 0],
});

const offlineVehicleMarker = new L.icon({
  iconUrl: require("../../icons/offlineVehicleIcon.png"),
  iconSize: [25, 41],
  iconAnchor: [13, 0],
});

const lockedVehicleMarker = new L.icon({
  iconUrl: require("../../icons/lock-solid.png"),
  iconSize: [25, 41],
  iconAnchor: [13, 0],
});

const plugMarker = new L.icon({
  iconUrl: require("../../icons/power-plug.png"),
  iconSize: [48, 48],
  iconAnchor: [13, 0],
  shadowAnchor: [10, 10],
});

const swapMarker = new L.icon({
  iconUrl: require("../../icons/swap-battery.png"),
  iconSize: [48, 48],
  iconAnchor: [13, 0],
  shadowAnchor: [10, 10],
});

/*
const blueIcon = new L.icon({
  iconUrl: blueTriangle,
  iconSize: [30, 50],
  iconAnchor: [15, 25],
});

const redFlag = new L.icon({
  iconUrl: redFlagImage,
  iconSize: [30, 50],
  iconAnchor: [15, 25],
});

const greenFlag = new L.icon({
  iconUrl: greenFlagImage,
  iconSize: [30, 50],
  iconAnchor: [15, 25],
});
*/
export { defaultMarker, plugMarker, swapMarker };
