import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./stations.module.css";
import Toast from "../../components/toast";
import { useState, useEffect } from "react";

const StationAddMap = ({ stations, showMap, onPositionChange }) => {
  const [mapReady, setMapReady] = useState(false);
  const [clickedPosition, setClickedPosition] = useState(null);
  const [showMsg, setShowMsg] = useState(false);

  const defMarker = new L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [13, 41],
  });

  const LocationMarker = () => {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
      click(e) {
        setClickedPosition(e.latlng);
      },
      locationfound(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });
    return clickedPosition === null ? null : (
      <Marker position={clickedPosition} icon={defMarker}>
        <Popup>Selected Location</Popup>
      </Marker>
    );
  };

  useEffect(() => {
    if (clickedPosition) {
      console.log("Clicked position:", clickedPosition);
      onPositionChange(clickedPosition);
    }
  }, [clickedPosition]);

  useEffect(() => {
    if (!showMap) {
      const timeoutId = setTimeout(() => {
        setShowMsg(true);
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [clickedPosition]);

  return (
    <div className={styles.stationsMapContainer}>
      <MapContainer
        center={[41.012702, 28.99884]}
        zoom={13}
        scrollWheelZoom={true}
        whenReady={() => setMapReady(true)}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {showMap ? <LocationMarker /> : null}
      </MapContainer>
    </div>
  );
};

export default StationAddMap;
