import { serverPath } from "./devSettings";

const getReports = async (report) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(report),
  };

  const response = await fetch(serverPath + "/getReports", options);
  const result = await response.json();
  return { result, response };
};

const getChargeRents = async (user) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getChargeRents", options);
  const result = await response.json();

  return result;
};

export { getReports, getChargeRents };
