import { useState } from "react";
import styles from "./account.module.css";
import { changePassword } from "../../utils/resetPassword";

const ActivateAccount = () => {
  const [pageState, setPageState] = useState(0);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [alert, setAlert] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const _username = params.get("username");
  const _oldPassword = params.get("tmp");

  const [username, setUsername] = useState(_username);
  const [oldPassword, setOldPassword] = useState(_oldPassword);

  const validateForm = () => {
    if (newPasswordAgain !== newPassword) {
      setAlert("Şifreler uyuşmuyor!");
      return false;
    } else if (newPassword.length < 8) {
      setAlert("Şifre 8 karakter veya daha uzun olmalı!");
      return false;
    }

    setAlert(" ");
    return true;
  };

  const saveNewPassword = () => {
    if (!validateForm()) return;
    changePassword({
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }).then((result) => {
      if (result.state === "success") setPageState(1);
    });
  };

  return (
    <div className={styles.container}>
      <img src="/images/greengo_logo.png" alt="greengologo" height="40" />
      <h3>Üyelik Hesabı Aktivasyionu / Şifre Belirleme</h3>

      {pageState === 0 ? (
        <div className={styles.newPasswordContainer}>
          <div className={styles.inputContainer}>
            <label>Kullanıcı Adı</label>
            <input
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              value={username}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>Eski Şifre</label>
            <input
              onChange={(e) => setOldPassword(e.target.value)}
              type="password"
              value={oldPassword}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>Yeni Şifre</label>
            <input
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              value={newPassword}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <label>Yeni Şifre Tekrar</label>
            <input
              onChange={(e) => setNewPasswordAgain(e.target.value)}
              type="password"
              value={newPasswordAgain}
            ></input>
          </div>
          <div className={styles.saveNewPassword}>
            <button
              onClick={() => {
                saveNewPassword();
              }}
            >
              Kaydet
            </button>
          </div>
          <div>
            <p>{alert}</p>
          </div>
        </div>
      ) : (
        <div>
          <p>Hesap Aktivasyonu Tamamlandı!</p>
          <p>
            <a href="/login">Giriş için tıklayın.</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default ActivateAccount;
