import { serverPath } from "./devSettings";

const editCompany = async (company) => {
  const reqData = {
    company_type: company.company_type,
    company_name: company.company_name,
    vehicle_count: company.vehicle_count,
    driver_count: company.driver_count,
    id: company.id,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/editCompany", options);
  const result = await response.json();

  return result;
};

export default editCompany;
