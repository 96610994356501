import { serverPath } from "./devSettings";

const getStations = async (user) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getStations", options);
  const result = await response.json();

  return result;
};

const getPlugStations = async (user) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getPlugStations", options);
  const result = await response.json();

  return result;
};

const getSwapStations = async (user) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getSwapStations", options);
  const result = await response.json();

  return result;
};

const getMultiSwapStations = async (user) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const response = await fetch(serverPath + "/getMultiSwapStations", options);
  const result = await response.json();

  return result;
};

const addStation = async (station) => {
  const reqData = {
    station_type: station.station_type,
    name: station.name,
    serial_no: station.serial_no,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/addStation", options);
  const result = await response.json();

  return result;
};

const deleteStation = async (station) => {
  const reqData = {
    station_id: station.station_id,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/deleteStation", options);
  const result = await response.json();

  return result;
};

const editStation = async (station) => {
  const reqData = {
    station_type: station.station_type,
    name: station.name,
    station_id: station.station_id,
    serial_no: station.serial_no,
    imei: station.imei,
    latitude: station?.latitude,
    longitude: station?.longitude,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/editStation", options);
  const result = await response.json();

  return result;
};

const plugCommand = async (command) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(command),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/plugCommand", options);
  const result = await response.json();

  return result;
};

const swapCommand = async (command) => {
  console.log(command);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(command),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/swapCommand", options);
  const result = await response.json();

  return result;
};

const multiSwapCommand = async (command) => {
  console.log(command);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(command),
    credentials: "include",
  };

  const response = await fetch(serverPath + "/multiSwapCommand", options);
  const result = await response.json();

  return result;
};

const getVehicleAvailableStations = async () => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
    credentials: "include",
  };

  const response = await fetch(
    serverPath + "/getVehicleAvailableStations",
    options
  );
  const result = await response.json();

  return result;
};

export {
  getStations,
  getPlugStations,
  getSwapStations,
  getMultiSwapStations,
  plugCommand,
  swapCommand,
  multiSwapCommand,
  editStation,
  deleteStation,
  getVehicleAvailableStations,
};
