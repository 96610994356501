import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import styles from "./ExportBar.module.css";
import { FaFilePdf } from "react-icons/fa";
import { useLocalize } from "../../components/localize";

export const ExportBarPDF = ({
  tableRef,
  reportFileName,
  reportTitle,
  isDisabled,
}) => {
  const t = useLocalize();
  const exportAsPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#reportTable" });
    doc.save("table.pdf");
  };

  return (
    <div className={styles.exportBar}>
      <button
        className={styles.button}
        disabled={isDisabled}
        onClick={exportAsPDF}
      >
        PDF
        <div>
          <FaFilePdf color="red" />
        </div>
      </button>
    </div>
  );
};

export default ExportBarPDF;
