import styles from "./stations.module.css";
import tableStyles from "../commonStyles/listTable.module.css";
import StationsListRow from "./StationsListRow";
import { Filter, hideByFilter } from "../../components/filter";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import StationsMap from "./StationsMap";

import { useLocalize } from "../../components/localize";

const StationsList = ({ setMode }) => {
  const [filterValue, setFilterValue] = useState("");

  const stations = useSelector((state) => state.stations.stationList);
  const t = useLocalize();

  return (
    <>
      <div className={styles.stationsList}>
        <h2>{t("stations")}</h2>
        <div className={styles.topBar}>
          <Filter setFilter={setFilterValue} />
          <button onClick={() => setMode("add")}>
            <FontAwesomeIcon icon={faPlus} /> {t("add")}
          </button>
        </div>

        <div className={styles.stationsListandMap}>
          <div>
            <table className={tableStyles.listTable}>
              <thead>
                <tr style={{ color: "black" }}>
                  <th></th>
                  <th>{t("name")}</th>
                  <th>{t("type")}</th>
                  <th>No</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: "30px" }}>
                {stations?.map((station, indice) => {
                  return (
                    <StationsListRow
                      station={station}
                      c
                      indice={indice}
                      setMode={setMode}
                      key={station.station_id}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          <StationsMap stations={stations} />
        </div>
      </div>
    </>
  );
};

export default StationsList;
